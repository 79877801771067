import { Component } from '@angular/core';
import {MatomoRestService} from '../../core/services/api/matomo.rest-service';

@Component({
  selector: 'phx-matomo-reports',
  templateUrl: './matomo-reports.component.html',
  styleUrls: ['./matomo-reports.component.scss']
})
export class MatomoReportsComponent {

  constructor(private matomoService: MatomoRestService) {
  }

}
