<div id="login">
    <div class="panel-header">
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <h1>PHOENIX Admin App</h1>
            </div>
            <div class="col-md-2"></div>
        </div>
    </div>
    <div class="panel-error" *ngIf="error || action">
        <notification-public *ngIf="error" [type]="'error'"
                             message="Ihr Benutzername und Passwort stimmen nicht überein."></notification-public>
        <notification-public *ngIf="action && action == 'passwort-erfolgreich-gesetzt'"
                             type="success"
                             message="Ihr neues Passwort wurde gespeichert. Geben Sie Ihren Benutzernamen und Ihr Passwort hier ein.">
        </notification-public>
    </div>
    <div *ngIf="passwordResetSuccess">
        <notification-public *ngIf="passwordResetSuccess" 
                              type="success" 
                              message="Ihre Anfrage wurde erfolgreich übermittelt. Wir prüfen Ihren Zugang und bitten um etwas Geduld. Ihr PHOENIX Apothekenportal Team">
        </notification-public>
    </div>
    <div class="panel-body mb-xxxl">
        
        <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                <div *ngIf="loading">
                    <div class="text-center py-l">
                        <i class="fal fa-spinner-third fa-spin"></i>&nbsp;Ihre Benutzerinformationen werden überprüft.
                    </div>
                </div>
                <form [formGroup]="loginForm" (submit)="submit()" *ngIf="!loading">
                    
                    <div class="row">
                        
                        <div class="col-md-12 pr-xxl">
                            <h3 class="pb-m">Geben Sie Ihren Benutzernamen und Ihr Passwort ein.</h3>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Benutzername</mat-label>
                                <input matInput
                                       autocomplete="off"
                                       placeholder="Benutzername"
                                       formControlName="username"
                                       tabindex="1"
                                       data-cy="username">
                                <mat-error *ngIf="username.hasError('required')">Bitte geben Sie Ihren Benutzernamen
                                    ein.
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Passwort</mat-label>
                                <input matInput
                                       placeholder="Passwort"
                                       formControlName="password"
                                       [type]="hide ? 'password' : 'text'"
                                       tabindex="2"
                                       data-cy="password">
                                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
                                </mat-icon>
                                <mat-error *ngIf="password.hasError('required')">Bitte geben Sie Ihr Passwort ein.
                                </mat-error>
                            </mat-form-field>
                            <a (click)="openDialog()" tabindex="5">Zugangsdaten vergessen?</a>
                            <button class="btn-primary mt-xxl d-block" type="submit" tabindex="3"
                                    data-cy="login-submit">
                                Einloggen
                            </button>
                        </div>
                    </div>
                </form>
        </div>
        </div>
        <div class="col-md-2">
        </div>
    </div>
</div>
