/**
 * represent the basic user model
 */
import {UserStatus} from "../../modules/user-management/model/user-status.enum";
import {UserSalutation} from "../../modules/user-management/model/user-salutation.enum";

export class User {
    title: string;

    id: number;
    email: string;
    group: string;

    username: string;
    firstName: string;
    lastName: string;
    status: UserStatus;
    salutation: UserSalutation;
    name: string;
    adminAuthorities: string[];
    authoritiesEnum: string[];
    distributionCenters: string[];

    constructor(obj: any) {
        this.id = obj && obj.id || '';
        this.email = obj && obj.email || '';
        this.group = obj && obj.group || '';
        this.title = obj && obj.title || '';
        this.username = obj && obj.username || '';
        this.name = obj && obj.name || '';
        this.firstName = obj && obj.firstName || '';
        this.lastName = obj && obj.lastName || '';
        this.status = obj && obj.status || '';
        this.salutation = obj && obj.salutation || '';
        this.adminAuthorities = obj && obj.adminAuthorities || [];
        this.authoritiesEnum = obj && obj.authoritiesEnum || [];
        this.distributionCenters = obj && obj.distributionCenters || [];
    }
}
