import {CmsActions, CmsActionsTypes} from '../actions/cms.actions';
import {HttpErrorResponse} from '@angular/common/http';


export interface State {
    forms: any;
    form: any;
    formsError: HttpErrorResponse;
    formError: HttpErrorResponse;
    formsLoading: boolean;
    formLoading: boolean;
}

export const initialState: State = {
    forms: null,
    form: null,
    formsError: null,
    formError: null,
    formsLoading: false,
    formLoading: false
};

export function reducer(state = initialState, action: CmsActions): State {
    switch (action.type) {
        case CmsActionsTypes.LOAD_FORMS:
            return {
                ...state,
                formsLoading: true
            };
        case CmsActionsTypes.LOAD_FORMS_COMPLETE:
            return {
                ...state,
                forms: action.payload,
                formsLoading: false
            };
        case CmsActionsTypes.LOAD_FORMS_FAIL:
            return {
                ...state,
                formsError: action.payload,
                formsLoading: false
            };
        case CmsActionsTypes.LOAD_FORM:
            return {
                ...state,
                form: action.payload,
                formLoading: true
            };
        case CmsActionsTypes.LOAD_FORM_COMPLETE:
            return {
                ...state,
                form: action.payload,
                formLoading: false
            };
        case CmsActionsTypes.LOAD_FORM_FAIL:
            return {
                ...state,
                formError: action.payload,
                formLoading: false
            };
        default:
            return state;
    }
}


export const getForms = (state: State) => state.forms;
export const getForm = (state: State) => state.form;
export const getFormsError = (state: State) => state.formsError;
export const getFormError = (state: State) => state.formError;
export const getFormsLoading = (state: State) => state.formsLoading;
export const getFormLoading = (state: State) => state.formLoading;