import {Injectable} from "@angular/core";

@Injectable()
export class CalendarService {
    //TODO: Test getWeekOfTheYear
    getWeekOfTheYear(){
        let d = new Date();
        d.setHours(0,0,0);
        d.setDate(+d.getDate()+4-(+d.getDay()||7));
        return Math.ceil((((+d-+new Date(+d.getFullYear(),0,1))/8.64e7)+1)/7);
    }

    //TODO: Test getLast12MonthsArray
    getLast12MonthsArray(): string[] {
        var date = new Date();
        var months = [],
            monthNames = [ "Januar", "Februar", "März", "April", "Mai", "Juni",
                "Juli", "August", "September", "Oktober", "November", "Dezember" ];
        date.setMonth(date.getMonth() - 1);
        for(var i = 0; i < 12; i++) {
            months.push(monthNames[date.getMonth()] + ' ' + date.getFullYear() );

            // Subtract a month each time
            date.setMonth(date.getMonth() - 1);
        }
        return months;
    }

    /**
     * TODO: Test getDateWithFirstDayOfMonthCountMonthsBack
     * @param count
     */
    getDateWithFirstDayOfMonthCountMonthsBack(count: number): Date{
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        let newStartDate = new Date(y, m, 1);
        newStartDate.setMonth(new Date().getMonth() - count);
        return newStartDate;
    }

    /**
     * TODO: Test getDateWithLastDayOfMonthCountMonthsBack
     * @param count
     */
    getDateWithLastDayOfMonthCountMonthsBack(count: number): Date {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        let newEndDate = new Date(date.getFullYear(), (date.getMonth() - count) + 1, 0);
        return newEndDate
    }

    isToday(date: Date) {
        date = new Date(date);
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    }
}