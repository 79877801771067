import * as fromMessageActions from '../actions/message.actions';
import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {AuthService} from '../../services/api/auth.service';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs';
import {MessageRestService} from "../../services/api/message/message-rest.service";

@Injectable()
export class MessageEffects {
    @Effect()
    loadMessages$: Observable<Action> = this.actions$.pipe(
        ofType<fromMessageActions.LoadMessagesAction>(fromMessageActions.MessageActionTypes.LOAD_MESSAGES))
        .pipe(map(action => action.payload), switchMap((rq) =>
                this.messagesRestService.getMessages(rq).pipe(
                    map(data => new fromMessageActions.LoadMessagesCompleteAction(data.returnObject)),
                    catchError((error) => of(new fromMessageActions.ErrorAction(error)))
                )
            )
        );

    @Effect()
    loadMessage$: Observable<Action> = this.actions$
        .pipe(ofType<fromMessageActions.LoadMessageAction>(fromMessageActions.MessageActionTypes.LOAD_MESSAGE))
        .pipe(map(action => action), switchMap((rq) =>
            this.messagesRestService.loadMessage(rq).pipe(
                map(data => new fromMessageActions.LoadMessageCompleteAction(data.returnObject)),
                catchError((error) => of(new fromMessageActions.ErrorAction(error)))
            )
        ));

    constructor(
        private authService: AuthService,
        private messagesRestService: MessageRestService,
        private actions$: Actions
    ) {}
}
