import {Action} from '@ngrx/store';

export enum ProductActionTypes {
    INIT_ADD_PRODUCT = '[Preorder-product] Init Add Product',
    ADD_PRODUCT_COMPLETE = '[Preorder-product] Add Product Complete',
    ADD_PRODUCT_FAILED = '[Preorder-product] Add Product Failed',
    INIT_EDIT_PRODUCT = '[Preorder-product] Init Edit Product',
    EDIT_PRODUCT_COMPLETE = '[Preorder-product] Edit Product Complete',
    EDIT_PRODUCT_FAILED = '[Preorder-product] Edit Product Failed',
    INIT_DELETE_PRODUCT = '[Preorder-product] Init Delete Product',
    DELETE_PRODUCT_COMPLETE = '[Preorder-product] Delete Product Complete',
    DELETE_PRODUCT_FAILED = '[Preorder-product] Delete Product Failed',
    INIT_LOAD_ORDERED_PRODUCTS = '[Preorder-product] Init Load Ordered Products',
    LOAD_ORDERED_PRODUCTS_COMPLETE = '[Preorder-product] Load Ordered Products complete',
    LOAD_ORDERED_PRODUCTS_FAILED = '[Preorder-product] Load Ordered Products failed',
}

export class InitAddProduct implements Action {
    readonly type = ProductActionTypes.INIT_ADD_PRODUCT;
}

export class AddProductComplete implements Action {
    readonly type = ProductActionTypes.ADD_PRODUCT_COMPLETE;

    constructor(public payload: any) {
    }
}

export class AddProductFailed implements Action {
    readonly type = ProductActionTypes.ADD_PRODUCT_FAILED;

    constructor(public payload: any) {
    }
}

export class InitEditProduct implements Action {
    readonly type = ProductActionTypes.INIT_EDIT_PRODUCT;

}

export class EditProductComplete implements Action {
    readonly type = ProductActionTypes.EDIT_PRODUCT_COMPLETE;

}

export class EditProductFailed implements Action {
    readonly type = ProductActionTypes.EDIT_PRODUCT_FAILED;

    constructor(public payload: any) {
    }
}

export class InitDeleteProduct implements Action {
    readonly type = ProductActionTypes.INIT_DELETE_PRODUCT;

}

export class DeleteProductComplete implements Action {
    readonly type = ProductActionTypes.DELETE_PRODUCT_COMPLETE;

}

export class DeleteProductFailed implements Action {
    readonly type = ProductActionTypes.DELETE_PRODUCT_FAILED;

    constructor(public payload: any) {
    }
}

export class InitLoadOrderedProducts implements Action {
    readonly type = ProductActionTypes.INIT_LOAD_ORDERED_PRODUCTS;

    constructor(public payload: any) {
    }
}

export class LoadOrderedProductsComplete implements Action {
    readonly type = ProductActionTypes.LOAD_ORDERED_PRODUCTS_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadOrderedProductsFailed implements Action {
    readonly type = ProductActionTypes.LOAD_ORDERED_PRODUCTS_FAILED;

    constructor(public payload: any) {
    }
}

export type ProductActions =
    InitAddProduct |
    AddProductComplete |
    AddProductFailed |
    InitEditProduct |
    EditProductComplete |
    EditProductFailed |
    InitDeleteProduct |
    DeleteProductComplete |
    DeleteProductFailed |
    InitLoadOrderedProducts |
    LoadOrderedProductsComplete |
    LoadOrderedProductsFailed;
