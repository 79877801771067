<!-- PROCESSING //-->
<ng-container *ngIf="submitted && !success">
    <div class="row panel-header" *ngIf="submitted && !error && !success">
        <div class="col-md-2"></div>
        <div class="col-md-8">
            <h1>Sie haben Ihre Zugangsdaten vergessen?</h1>
        </div>
        <div class="col-md-2"></div>
    </div>
    <div class="row panel-body">
        <div class="col-md-2"></div>
        <div class="col-md-8">
            <div class="text-center py-l">
                <i class="fal fa-spinner-third fa-spin"></i>&nbsp;Ihre Anfrage wird übermittelt.
            </div>
        </div>
        <div class="col-md-2"></div>
    </div>
</ng-container>
<!-- FORM //-->
<ng-container *ngIf="!submitted"> 
    <div class="row panel-body">
        <div class="col-md-12">
            <h1>Sie haben Ihren Benutzernamen und/oder Ihr Passwort vergessen?</h1>
            <p>Bitte füllen Sie das nachfolgende Formular aus. Wir prüfen im Anschluss Ihren Zugang und schicken Ihnen eine Aktivierungsmail.</p>
            <form [formGroup]="resetPasswordForm" onsubmit="return false" class="col-md-12">
                <div class="row">
                    <div class="col-md-6 pl-0">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Vorname</mat-label>
                            <input matInput autocomplete="off" placeholder="Vorname"
                                   value=""
                                   formControlName="firstName">
                            <mat-error *ngIf="name.hasError('required')" class="mt-m">
                                Bitte geben Sie Ihren Vornamen ein.
                            </mat-error>
                            <mat-error *ngIf="name.hasError('minlength') || name.hasError('maxlength')"  class="mt-m">Ihre Vorname muss zwischen 2- und 10-stellig sein</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Nachname</mat-label>
                            <input matInput autocomplete="off" placeholder="Nachname" value=""
                                   formControlName="lastName">
                            <mat-error *ngIf="surname.hasError('required')" class="mt-m">
                                Bitte geben Sie ihren Nachnamen ein.
                            </mat-error>
                            <mat-error *ngIf="surname.hasError('minlength') || surname.hasError('maxlength')"  class="mt-m">
                                Der Nachname muss zwischen 2 und 10 Zeichen lang sein.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 pl-0">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>E-Mail Adresse *</mat-label>
                            <input matInput autocomplete="off" name="email" placeholder="E-Mail Adresse" formControlName="email">
                            <mat-error *ngIf="email.hasError('required')"  class="mt-m">
                                Bitte geben Sie Ihre E-Mail-Adresse ein.
                            </mat-error>
                            <mat-error *ngIf="email.hasError('email')"  class="mt-m">
                                Bitte geben Sie eine gültige Email-Adresse ein.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Telefonnummer (optional)</mat-label>
                            <input matInput autocomplete="off"  placeholder="Telefonnummer" formControlName="phoneNumber">
                        </mat-form-field>
                    </div>
                    <mat-error *ngIf="error" class="mt-m">
                        Es ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben.
                    </mat-error>
                </div>
                <div class="text-right">
                    <button class="btn-secondary" type="button" (click)="onCancel()">Abbrechen</button>
                    <button class="btn-primary" type="submit" (click)="submit(); $event.stopPropagation()">Absenden</button>
                </div>
            </form>
            <p class="mt-l">* Für Mitarbeitende der PHOENIX Group, der PHOENIX Pharmahandel GmbH & Co KG und deren Tochterunternehmen</p>
        </div>
    </div>
</ng-container>

<!-- SUCCESS //-->
<ng-container *ngIf="submitted && success">
  <div class="message">
    <div class="row mb-xl">
      <div class="col-md-2 phx-cta my-auto text-center"><i class="fal fa-check-circle fa-6x"></i></div>
      <div class="col-md-8 my-auto">
        <h1>
          Eine Email wurde an die Emailadresse {{response.returnObject}} versandt. <br>
          Sollten Sie Ihre E-Mail-Adresse geändert oder vergessen haben, wenden Sie sich über folgendes
          <a [routerLink]="['/kontaktformular']" tabindex="1" class="link">Kontaktformular</a> an uns.
        </h1>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div>
          <a href="/"><i class="fal fa-arrow-right"></i> Zurück zum Apothekenportal</a>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</ng-container>