import {HttpErrorResponse} from '@angular/common/http';
import {MatomoActions, MatomoActionTypes} from '../actions/matomo.actions';

export interface State {
    categories: any;
    categoriesLoading: boolean;
    categoriesError: HttpErrorResponse;

    actions: any;
    actionsLoading: boolean;
    actionsError: HttpErrorResponse;

    impressions: any;
    impressionsLoading: boolean;
    impressionsError: HttpErrorResponse;
}

export const initialState: State = {
    actions: {},
    actionsError: null,
    actionsLoading: false,
    impressions: {},
    impressionsError: null,
    impressionsLoading: false,
    categories: {},
    categoriesLoading: false,
    categoriesError: null
};

export function reducer(state = initialState, action: MatomoActions): State {
    switch (action.type) {
        case MatomoActionTypes.LOAD_CATEGORIES:
            return {
                ...state,
                categoriesLoading: true,
                categoriesError: null
            };
        case MatomoActionTypes.LOAD_CATEGORIES_COMPLETE:
            return {
                ...state,
                categoriesLoading: false,
                categories: action.payload,
                categoriesError: null
            };
        case MatomoActionTypes.LOAD_CATEGORIES_FAIL:
            return {
                ...state,
                categoriesLoading: false,
                categoriesError: action.payload
            };
        case MatomoActionTypes.LOAD_ACTIONS:
            return {
                ...state,
                actionsLoading: true,
                actionsError: null
            };
        case MatomoActionTypes.LOAD_ACTIONS_COMPLETE:
            return {
                ...state,
                actionsLoading: false,
                actions: action.payload,
                actionsError: null
            };
        case MatomoActionTypes.LOAD_ACTIONS_FAIL:
            return {
                ...state,
                actionsLoading: false,
                actionsError: action.payload
            };
        case MatomoActionTypes.LOAD_IMPRESSIONS:
            return {
                ...state,
                impressionsLoading: true,
                impressionsError: null
            };
        case MatomoActionTypes.LOAD_IMPRESSIONS_COMPLETE:
            return {
                ...state,
                impressionsLoading: false,
                impressionsError: null,
                impressions: action.payload
            };
        case MatomoActionTypes.LOAD_IMPRESSIONS_FAIL:
            return {
                ...state,
                impressionsError: action.payload,
                impressionsLoading: false
            };
        default:
            return state;
    }
}

export const getCategoriesLoading = (state: State) => state.categoriesLoading;
export const getCategories = (state: State) => state.categories;
export const getCategoriesError = (state: State) => state.categoriesError;


export const getActionsLoading = (state: State) => state.actionsLoading;
export const getActions = (state: State) => state.actions;
export const getActionsError = (state: State) => state.actionsError;


export const getImpressionsLoading = (state: State) => state.impressionsLoading;
export const getImpressions = (state: State) => state.impressions;
export const getImpressionsError = (state: State) => state.impressionsError;