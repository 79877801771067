import * as fromVendorsActions from '../actions/vendors.actions';
import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs';
import {VendorsRestService} from '../../services/api/vendors.rest-service';

@Injectable()
export class VendorsEffects {

    constructor(
        private vendorsRestService: VendorsRestService,
        private actions$: Actions
    ) {
    }

    @Effect()
    loadVendors$: Observable<Action> = this.actions$.pipe(
        ofType<fromVendorsActions.InitLoadAllVendors>(fromVendorsActions.VendorsActionTypes.INIT_LOAD_ALL_VENDORS))
        .pipe(switchMap(() =>
                this.vendorsRestService.loadAllVendors().pipe(
                    map(data => new fromVendorsActions.LoadAllVendorsComplete(data.returnObject)),
                    catchError((error) => of(new fromVendorsActions.LoadAllVendorsFailed(error)))
                )
            )
        );
}
