import {ProductActions, ProductActionTypes} from '../../actions/preorder/product.actions';
import {HttpErrorResponse} from '@angular/common/http';

export interface State {
    addProductError: HttpErrorResponse;
    addProductLoading: boolean;
    editProductLoading: boolean;
    editProductError: HttpErrorResponse;
    deleteProductLoading: boolean;
    deleteProductError: HttpErrorResponse;
    orderedProductsLoading: boolean;
    orderedProductsError: HttpErrorResponse;
    orderedProducts: any[];
}


export const initialState: State = {
    addProductError: null,
    addProductLoading: false,
    editProductLoading: false,
    editProductError: null,
    deleteProductLoading: false,
    deleteProductError: null,
    orderedProductsLoading: false,
    orderedProductsError: null,
    orderedProducts: []
};

export function reducer(state = initialState, action: ProductActions): State {
    switch (action.type) {
        case ProductActionTypes.INIT_ADD_PRODUCT:
            return {
                ...state,
                addProductLoading: true,
                addProductError: null
            };
        case ProductActionTypes.ADD_PRODUCT_COMPLETE:
            return {
                ...state,
                addProductLoading: false,
                addProductError: null,
            };
        case ProductActionTypes.ADD_PRODUCT_FAILED:
            return {
                ...state,
                addProductLoading: false,
                addProductError: action.payload
            };
        case ProductActionTypes.INIT_EDIT_PRODUCT:
            return {
                ...state,
                editProductLoading: true,
                editProductError: null
            };
        case ProductActionTypes.EDIT_PRODUCT_COMPLETE:
            return {
                ...state,
                editProductLoading: false,
                editProductError: null,
            };
        case ProductActionTypes.EDIT_PRODUCT_FAILED:
            return {
                ...state,
                editProductLoading: false,
                editProductError: action.payload
            };
        case ProductActionTypes.INIT_DELETE_PRODUCT:
            return {
                ...state,
                deleteProductLoading: true,
                deleteProductError: null
            };
        case ProductActionTypes.DELETE_PRODUCT_COMPLETE:
            return {
                ...state,
                deleteProductLoading: false,
                deleteProductError: null,
            };
        case ProductActionTypes.DELETE_PRODUCT_FAILED:
            return {
                ...state,
                deleteProductLoading: false,
                deleteProductError: action.payload
            };
        case ProductActionTypes.INIT_LOAD_ORDERED_PRODUCTS:
            return {
                ...state,
                orderedProductsLoading: true,
                orderedProductsError: null
            };
        case ProductActionTypes.LOAD_ORDERED_PRODUCTS_COMPLETE:
            return {
                ...state,
                orderedProductsLoading: false,
                orderedProductsError: null,
                orderedProducts: action.payload
            };
        case ProductActionTypes.LOAD_ORDERED_PRODUCTS_FAILED:
            return {
                ...state,
                orderedProductsLoading: false,
                orderedProductsError: action.payload
            };
        default:
            return state;
    }
}

export const getAddProductLoading = (state: State) => state.addProductLoading;
export const getAddProductError = (state: State) => state.addProductError;
export const getEditProductLoading = (state: State) => state.editProductLoading;
export const getEditProductError = (state: State) => state.editProductError;
export const getDeleteProductLoading = (state: State) => state.deleteProductLoading;
export const getDeleteProductError = (state: State) => state.deleteProductError;
export const getOrderedProducts = (state: State) => state.orderedProducts;
export const getOrderedProductsLoading = (state: State) => state.orderedProductsLoading;
export const getOrderedProductsError = (state: State) => state.orderedProductsError;
