import {
    ActionReducer,
    ActionReducerMap,
    createFeatureSelector,
    createSelector,
    MetaReducer
} from '@ngrx/store';
import {environment} from '../../../../environments/environment';
import * as fromUser from './user.reducer';
import * as fromMessage from './message.reducer';
import * as fromPharmacyUser from './pharmacyUser.reducer';
import * as fromEsare from './esare.reducer';
import * as fromPromotions from './promotions.reducer';
import * as fromVaccine from './vaccine.reducer';
import * as fromAccount from './account.reducer';

import * as fromVendors from './vendors.reducer';
import * as fromCms from './cms.reducer';
import * as fromDelayInfo from './delay-info.reducer';
import * as fromMatomo from './matomo.reducer';
import * as fromBeraterUser from './beraterUser.reducer';
import * as fromPreorder from './preorder/preorder.reducer';
import * as fromPreorderProduct from './preorder/product.reducer';
import * as fromPreorderCategory from './preorder/category.reducer';
import * as fromPreorderSupplier from './preorder/supplier.reducer';
import * as fromPreorderPeriod from './preorder/period.reducer';

export interface State {
    user: fromUser.State;
    pharmacyUser: fromPharmacyUser.State;
    message: fromMessage.State;
    esare: fromEsare.State;
    promotions: fromPromotions.State;
    vaccine: fromVaccine.State;
    account: fromAccount.State;

    vendors: fromVendors.State;
    cms: fromCms.State;
    delays: fromDelayInfo.State;
    matomo: fromMatomo.State;
    beraterUser: fromBeraterUser.State;
    preorder: fromPreorder.State;
    preorderProduct: fromPreorderProduct.State;
    preorderCategory: fromPreorderCategory.State;
    preorderSupplier: fromPreorderSupplier.State;
    preorderPeriod: fromPreorderPeriod.State;
}

export const reducers: ActionReducerMap<State> = {
    user: fromUser.reducer,
    pharmacyUser: fromPharmacyUser.reducer,
    message: fromMessage.reducer,
    esare: fromEsare.reducer,
    promotions: fromPromotions.reducer,
    vaccine: fromVaccine.reducer,
    account: fromAccount.reducer,
    vendors: fromVendors.reducer,
    cms: fromCms.reducer,
    delays: fromDelayInfo.reducer,
    matomo: fromMatomo.reducer,
    beraterUser: fromBeraterUser.reducer,
    preorder: fromPreorder.reducer,
    preorderProduct: fromPreorderProduct.reducer,
    preorderCategory: fromPreorderCategory.reducer,
    preorderSupplier: fromPreorderSupplier.reducer,
    preorderPeriod: fromPreorderPeriod.reducer
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
    return function (state: State, action: any): State {
        console.log('state', state);
        console.log('action', action);

        return reducer(state, action);
    };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
// TODO: Check environment
export const metaReducers: MetaReducer<State>[] = !environment.production
    ? [logger]
    : [];


// user selectors
export const getUserState = createFeatureSelector<fromUser.State>('user');
export const getUserResponse = createSelector(getUserState, fromUser.getUserResponse);
export const getUserErrorResponse = createSelector(getUserState, fromUser.getUserErrorResponse);
export const getUserLoading = createSelector(getUserState, fromUser.getUserLoading);

export const getUsersRequest = createSelector(getUserState, fromUser.getUsersRequest);
export const getUsers = createSelector(getUserState, fromUser.getUsers);
export const getUsersResponse = createSelector(getUserState, fromUser.getUsersResponse);
export const getUsersLoading = createSelector(getUserState, fromUser.getUsersLoading);
export const getUsersError = createSelector(getUserState, fromUser.getUsersError);
export const getUserAuthTreeLoading = createSelector(getUserState, fromUser.getUserAuthTreeLoading);
export const getUserAuthTree = createSelector(getUserState, fromUser.getUserAuthTree);
export const getUserAuthTreeError = createSelector(getUserState, fromUser.getUserAuthTreeError);


// message selectors
export const getMessageState = createFeatureSelector<fromMessage.State>('message');
export const getMessageRequest = createSelector(getMessageState, fromMessage.getMessageRequest);
export const getMessageResponse = createSelector(getMessageState, fromMessage.getMessageResponse);
export const getMessageErrorResponse = createSelector(getMessageState, fromMessage.getMessageErrorResponse);
export const getMessagesLoading = createSelector(getMessageState, fromMessage.getMessagesLoading);
export const getMessageLoading = createSelector(getMessageState, fromMessage.getMessageLoading);

// pharmacy user selectors
export const getPharmacyUserState = createFeatureSelector<fromPharmacyUser.State>('pharmacyUser');

export const getPharmacyUsersRequest = createSelector(getPharmacyUserState, fromPharmacyUser.getPharmacyUsersRequest);
export const getPharmacyUsers = createSelector(getPharmacyUserState, fromPharmacyUser.getPharmacyUsers);
export const getPharmacyUsersResponse = createSelector(getPharmacyUserState, fromPharmacyUser.getPharmacyUsersResponse);
export const getPharmacyUsersLoading = createSelector(getPharmacyUserState, fromPharmacyUser.getPharmacyUsersLoading);
export const getPharmacyUsersError = createSelector(getPharmacyUserState, fromPharmacyUser.getPharmacyUsersError);
export const getPharmacyUserAuthTreeLoading = createSelector(getPharmacyUserState, fromPharmacyUser.getPharmacyUserAuthTreeLoading);
export const getPharmacyUserAuthTree = createSelector(getPharmacyUserState, fromPharmacyUser.getPharmacyUserAuthTree);
export const getPharmacyUserAuthTreeError = createSelector(getPharmacyUserState, fromPharmacyUser.getPharmacyUserAuthTreeError);

// esare selectors
export const getEsareState = createFeatureSelector<fromEsare.State>('esare');
export const getAddSupplementLoading = createSelector(getEsareState, fromEsare.getAddSupplementLoading);
export const getAddSupplementError = createSelector(getEsareState, fromEsare.getAddSupplementError);
export const getSupplements = createSelector(getEsareState, fromEsare.getSupplements);
export const getSupplementsLoading = createSelector(getEsareState, fromEsare.getSupplementsLoading);
export const getSupplementsError = createSelector(getEsareState, fromEsare.getSupplementsError);
export const getEditSupplementLoading = createSelector(getEsareState, fromEsare.getEditSupplementLoading);
export const getEditSupplementError = createSelector(getEsareState, fromEsare.getEditSupplementError);

// promotions selectors
export const getPromotionsState = createFeatureSelector<fromPromotions.State>('promotions');
export const getAddPromotionLoading = createSelector(getPromotionsState, fromPromotions.getAddPromotionLoading);
export const getAddPromotionError = createSelector(getPromotionsState, fromPromotions.getAddPromotionError);
export const getPromotions = createSelector(getPromotionsState, fromPromotions.getPromotions);
export const getPromotionsLoading = createSelector(getPromotionsState, fromPromotions.getPromotionsLoading);
export const getPromotionsError = createSelector(getPromotionsState, fromPromotions.getPromotionsError);
export const getEditPromotionLoading = createSelector(getPromotionsState, fromPromotions.getEditPromotionLoading);
export const getEditPromotionError = createSelector(getPromotionsState, fromPromotions.getEditPromotionError);
export const getAddPromotionLogoLoading = createSelector(getPromotionsState, fromPromotions.getAddPromotionLogoLoading);
export const getAddPromotionLogoError = createSelector(getPromotionsState, fromPromotions.getAddPromotionLogoError);
export const getPromotionById = createSelector(getPromotionsState, fromPromotions.getPromotionById);
export const getPromotionByIdLoading = createSelector(getPromotionsState, fromPromotions.getPromotionByIdLoading);
export const getPromotionByIdError = createSelector(getPromotionsState, fromPromotions.getPromotionByIdError);
export const getAddIdfsToPromotionLoading = createSelector(getPromotionsState, fromPromotions.getAddIdfsToPromotionLoading);
export const getAddIdfsToPromotionError = createSelector(getPromotionsState, fromPromotions.getAddIdfsToPromotionError);
export const getAddProductsLoading = createSelector(getPromotionsState, fromPromotions.getAddProductsLoading);
export const getAddProductsError = createSelector(getPromotionsState, fromPromotions.getAddProductsError);
export const getUpdateProductsLoading = createSelector(getPromotionsState, fromPromotions.getUpdateProductsLoading);
export const getUpdateProductsError = createSelector(getPromotionsState, fromPromotions.getUpdateProductsError);
export const getEditableProduct = createSelector(getPromotionsState, fromPromotions.getEditableProduct);
export const getDeletePromotionLoading = createSelector(getPromotionsState, fromPromotions.getDeletePromotionLoading);
export const getDeletePromotionError = createSelector(getPromotionsState, fromPromotions.getDeletePromotionError);
export const getRestorePromotionLoading = createSelector(getPromotionsState, fromPromotions.getRestorePromotionLoading);
export const getRestorePromotionError = createSelector(getPromotionsState, fromPromotions.getRestorePromotionError);
export const getDuplicatePromotionLoading = createSelector(getPromotionsState, fromPromotions.getDuplicatePromotionLoading);
export const getDuplicatePromotionError = createSelector(getPromotionsState, fromPromotions.getDuplicatePromotionError);

// vaccine selectors
export const getVaccineState = createFeatureSelector<fromVaccine.State>('vaccine');
export const getAddVaccineLoading = createSelector(getVaccineState, fromVaccine.getAddVaccineLoading);
export const getAddVaccineError = createSelector(getVaccineState, fromVaccine.getAddVaccineError);
export const getVaccines = createSelector(getVaccineState, fromVaccine.getVaccines);
export const getVaccinesLoading = createSelector(getVaccineState, fromVaccine.getVaccinesLoading);
export const getVaccinesError = createSelector(getVaccineState, fromVaccine.getVaccinesError);
export const getEditVaccineLoading = createSelector(getVaccineState, fromVaccine.getEditVaccineLoading);
export const getEditVaccineError = createSelector(getVaccineState, fromVaccine.getEditVaccineError);

// accounts selectors
export const getAccountState = createFeatureSelector<fromAccount.State>('account');
export const getAllAccountsRequest = createSelector(getAccountState, fromAccount.getAccountsRequest);
export const getAccounts = createSelector(getAccountState, fromAccount.getAccounts);
export const getAllAccountsResponse = createSelector(getAccountState, fromAccount.getAccountsResponse);
export const getAllAccountsLoading = createSelector(getAccountState, fromAccount.getAccountsLoading);
export const getAllAccountsError = createSelector(getAccountState, fromAccount.getAccountsError);


// vendors selectors
export const getVendorsState = createFeatureSelector<fromVendors.State>('vendors');
export const getAddVendorLoading = createSelector(getVendorsState, fromVendors.getAddVendorLoading);
export const getAddVendorError = createSelector(getVendorsState, fromVendors.getAddVendorError);
export const getUpdateVendorLoading = createSelector(getVendorsState, fromVendors.getUpdateVendorLoading);
export const getUpdateVendorError = createSelector(getVendorsState, fromVendors.getUpdateVendorError);
export const getVendors = createSelector(getVendorsState, fromVendors.getVendors);
export const getAllVendorsLoading = createSelector(getVendorsState, fromVendors.getAllVendorsLoading);
export const getAllVendorsLoadError = createSelector(getVendorsState, fromVendors.getAllVendorsLoadError);


// cms selectors
export const getCmsState = createFeatureSelector<fromCms.State>('cms');
export const getForms = createSelector(getCmsState, fromCms.getForms);
export const getForm = createSelector(getCmsState, fromCms.getForm);
export const getFormsError = createSelector(getCmsState, fromCms.getFormsError);
export const getFormsLoading = createSelector(getCmsState, fromCms.getFormsLoading);
export const getFormLoading = createSelector(getCmsState, fromCms.getFormLoading);

export const getDelaysState = createFeatureSelector<fromDelayInfo.State>('delays');
export const getDelays = createSelector(getDelaysState, fromDelayInfo.getDelays);
export const getDelaysLoading = createSelector(getDelaysState, fromDelayInfo.getDelaysLoading);
export const getDelaysError = createSelector(getDelaysState, fromDelayInfo.getDelaysError);

export const getMatomoState = createFeatureSelector<fromMatomo.State>('matomo');
export const getMatomoCategories = createSelector(getMatomoState, fromMatomo.getCategories);
export const getMatomoCategoriesLoading = createSelector(getMatomoState, fromMatomo.getCategoriesLoading);
export const getMatomoCategoriesError = createSelector(getMatomoState, fromMatomo.getCategoriesError);
export const getMatomoActions = createSelector(getMatomoState, fromMatomo.getActions);
export const getMatomoActionsLoading = createSelector(getMatomoState, fromMatomo.getActionsLoading);
export const getMatomoActionsError = createSelector(getMatomoState, fromMatomo.getActionsError);
export const getMatomoImpressions = createSelector(getMatomoState, fromMatomo.getImpressions);
export const getMatomoImpressionsLoading = createSelector(getMatomoState, fromMatomo.getImpressionsLoading);
export const getMatomoImpressionsError = createSelector(getMatomoState, fromMatomo.getImpressionsError);

// berater user selectors
export const getBeraterUserState = createFeatureSelector<fromBeraterUser.State>('beraterUser');
export const getAllBeraterUser = createSelector(getBeraterUserState, fromBeraterUser.getAllBeraterUser);
export const getAllBeraterUserLoading = createSelector(getBeraterUserState, fromBeraterUser.getAllBeraterUserLoading);
export const getAllBeraterUserError = createSelector(getBeraterUserState, fromBeraterUser.getAllBeraterUserError);
export const getAddUserLoading = createSelector(getBeraterUserState, fromBeraterUser.getAddUserLoading);
export const getAddUserError = createSelector(getBeraterUserState, fromBeraterUser.getAddUserError);
export const getUpdateUserLoading = createSelector(getBeraterUserState, fromBeraterUser.getUpdateUserLoading);
export const getUpdateUserError = createSelector(getBeraterUserState, fromBeraterUser.getUpdateUserError);
export const getDeleteUserLoading = createSelector(getBeraterUserState, fromBeraterUser.getDeleteUserLoading);
export const getDeleteUserError = createSelector(getBeraterUserState, fromBeraterUser.getDeleteUserError);
export const getPurchasingGroups = createSelector(getBeraterUserState, fromBeraterUser.getPurchasingGroups);
export const getPurchasingGroupsLoading = createSelector(getBeraterUserState, fromBeraterUser.getPurchasingGroupsLoading);
export const getPurchasingGroupsError = createSelector(getBeraterUserState, fromBeraterUser.getPurchasingGroupsError);
// preorder selectors
export const getPreorderState = createFeatureSelector<fromPreorder.State>('preorder');
export const getPreordersLoading = createSelector(getPreorderState, fromPreorder.getPreordersLoading);
export const getPreordersError = createSelector(getPreorderState, fromPreorder.getPreordersError);
export const getPreorders = createSelector(getPreorderState, fromPreorder.getPreorders);

// preorder product selectors
export const getPreorderProductState = createFeatureSelector<fromPreorderProduct.State>('preorderProduct');
export const getAddProductLoading = createSelector(getPreorderProductState, fromPreorderProduct.getAddProductLoading);
export const getAddProductError = createSelector(getPreorderProductState, fromPreorderProduct.getAddProductError);
export const getEditProductLoading = createSelector(getPreorderProductState, fromPreorderProduct.getEditProductLoading);
export const getEditProductError = createSelector(getPreorderProductState, fromPreorderProduct.getEditProductError);
export const getDeleteProductLoading = createSelector(getPreorderProductState, fromPreorderProduct.getDeleteProductLoading);
export const getDeleteProductError = createSelector(getPreorderProductState, fromPreorderProduct.getDeleteProductError);
export const getOrderedProductsLoading = createSelector(getPreorderProductState, fromPreorderProduct.getOrderedProductsLoading);
export const getOrderedProductsError = createSelector(getPreorderProductState, fromPreorderProduct.getOrderedProductsError);
export const getOrderedProducts = createSelector(getPreorderProductState, fromPreorderProduct.getOrderedProducts);

// preorder category selectors
export const getPreorderCategoryState = createFeatureSelector<fromPreorderCategory.State>('preorderCategory');
export const getCategories = createSelector(getPreorderCategoryState, fromPreorderCategory.getCategories);
export const getCategoriesLoading = createSelector(getPreorderCategoryState, fromPreorderCategory.getCategoriesLoading);
export const getCategoriesError = createSelector(getPreorderCategoryState, fromPreorderCategory.getCategoriesError);
export const getAddCategoryLoading = createSelector(getPreorderCategoryState, fromPreorderCategory.getAddCategoryLoading);
export const getAddCategoryError = createSelector(getPreorderCategoryState, fromPreorderCategory.getAddCategoryError);
export const getSelectedCategory = createSelector(getPreorderCategoryState, fromPreorderCategory.getSelectedCategory);
export const getSelectedCategoryLoading = createSelector(getPreorderCategoryState, fromPreorderCategory.getSelectedCategoryLoading);
export const getSelectedCategoryError = createSelector(getPreorderCategoryState, fromPreorderCategory.getAddCategoryError);
export const getEditCategoryLoading = createSelector(getPreorderCategoryState, fromPreorderCategory.getEditCategoryLoading);
export const getEditCategoryError = createSelector(getPreorderCategoryState, fromPreorderCategory.getEditCategoryError);

// preorder supplier selectors
export const getPreorderSupplierState = createFeatureSelector<fromPreorderSupplier.State>('preorderSupplier');
export const getAddSupplierLoading = createSelector(getPreorderSupplierState, fromPreorderSupplier.getAddSupplierLoading);
export const getAddSupplierError = createSelector(getPreorderSupplierState, fromPreorderSupplier.getAddSupplierError);
export const getEditSupplierLoading = createSelector(getPreorderSupplierState, fromPreorderSupplier.getEditSupplierLoading);
export const getEditSupplierError = createSelector(getPreorderSupplierState, fromPreorderSupplier.getEditSupplierError);
export const getSuppliers = createSelector(getPreorderSupplierState, fromPreorderSupplier.getSuppliers);
export const getSuppliersLoading = createSelector(getPreorderSupplierState, fromPreorderSupplier.getSuppliersLoading);
export const getSuppliersError = createSelector(getPreorderSupplierState, fromPreorderSupplier.getSuppliersError);
export const getNewCreatedSupplierId = createSelector(getPreorderSupplierState, fromPreorderSupplier.getNewCreatedSupplierId);

// preorder period selectors
export const getPreorderPeriodState = createFeatureSelector<fromPreorderPeriod.State>('preorderPeriod');
export const getPreorderPeriodsByCategory = createSelector(getPreorderPeriodState, fromPreorderPeriod.getPreorderPeriodsByCategory);
export const getPreorderPeriodsByCategoryLoading =
    createSelector(getPreorderPeriodState, fromPreorderPeriod.getPreorderPeriodsByCategoryLoading);
export const getPreorderPeriodsByCategoryError =
    createSelector(getPreorderPeriodState, fromPreorderPeriod.getPreorderPeriodsByCategoryError);
export const getAddPeriodLoading = createSelector(getPreorderPeriodState, fromPreorderPeriod.getAddPeriodLoading);
export const getAddPeriodError = createSelector(getPreorderPeriodState, fromPreorderPeriod.getAddPeriodError);