import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(@Inject(TranslateService) public translate: TranslateService) {
        translate.addLangs(['de']);
        translate.setDefaultLang('de');
    }

    ngOnInit() {

    }
}
