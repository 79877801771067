import {UserActions, UserActionTypes} from "../actions/user.actions";
import {UserResponse} from "../../models/user-response.model";
import {HttpErrorResponse} from "@angular/common/http";
import {User} from "../../models/user.model";
import {PaginationModel} from "../../models/pagination.model";
import {UserAuthNodeModel} from '../../../modules/user-management/model/user-auth-node.model';


export interface State {
    userResponse: UserResponse;
    userErrorResponse: HttpErrorResponse;
    userLoading: boolean;
    usersRequest: PaginationModel;
    users: User[];
    usersResponse: any;
    usersError: HttpErrorResponse;
    usersLoading: boolean;
    authTree: UserAuthNodeModel[];
    authTreeError: HttpErrorResponse;
    authTreeLoading: boolean;
}

export const initialState: State = {
    userResponse: null,
    userErrorResponse: null,
    userLoading: false,
    usersRequest: new PaginationModel(),
    users: [],
    usersResponse: null,
    usersError: null,
    usersLoading: false,
    authTree: null,
    authTreeError: null,
    authTreeLoading: false
};

export function reducer(state = initialState, action: UserActions): State {
    switch (action.type) {
        case UserActionTypes.LOAD_USER:
            return {
                ...state,
                userLoading: true,
            };
        case UserActionTypes.LOAD_USER_COMPLETE:
            return {
                ...state,
                userResponse: action.payload,
                userLoading: false
            };
        case UserActionTypes.LOAD_USER_FAIL:
            return {
                ...state,
                userErrorResponse: action.payload,
                userLoading: false
            };
        case UserActionTypes.LOGOUT:
            state = undefined;
            return {
                ...state
            };
        case UserActionTypes.LoadUsers:
            return {
                ...state,
                usersLoading: true,
                usersRequest: action.payload
            };
        case UserActionTypes.LoadUsersComplete:
            return {
                ...state,
                users: action.payload.result,
                usersResponse: action.payload,
                usersLoading: false
            };
        case UserActionTypes.LoadUsersFailed:
            return {
                ...state,
                usersError: action.payload,
                usersLoading: false
            };
        case UserActionTypes.AddUser:
            let addUsers = state.users;
            action.payload.id = addUsers.length + 1;
            addUsers.push(action.payload);
            return {
                ...state,
                users: addUsers
            };
        case UserActionTypes.UpdateUser:
            let updateUsers = [];
            state.users.forEach((user) => {
                if (user.id == action.payload.id) {
                    updateUsers.push(action.payload)
                } else {
                    updateUsers.push(user);
                }
            });
            return {
                ...state,
                users: updateUsers
            };
        case UserActionTypes.RemoveUser:
            let removeUsers = [];
            state.users.forEach((user) => {
                if (user.id != action.payload) {
                    removeUsers.push(user);
                }
            });
            return {
                ...state,
                users: removeUsers
            };
        case UserActionTypes.INIT_AUTH_TREE:
            return {
                ...state,
                authTreeLoading: true
            };
        case UserActionTypes.INIT_AUTH_TREE_COMPLETE:
            const authTree = [new UserAuthNodeModel({ label: 'SUBUSER.ALL_RIGHTS' })];
            authTree[0].children = action.payload.children.map(node => new UserAuthNodeModel(node));
            return {
                ...state,
                authTree: authTree,
                authTreeLoading: false
            };
        case UserActionTypes.INIT_AUTH_TREE_FAILED:
            return {
                ...state,
                authTreeError: action.payload,
                authTreeLoading: false
            };
        default:
            return state;
    }
}

export const getUserResponse = (state: State) => state.userResponse;
export const getUserErrorResponse = (state: State) => state.userErrorResponse;
export const getUserLoading = (state: State) => state.userLoading;

export const getUsersRequest = (state: State) => state.usersRequest;
export const getUsers = (state: State) => state.users;
export const getUsersResponse = (state: State) => state.usersResponse;
export const getUsersLoading = (state: State) => state.usersLoading;
export const getUsersError = (state: State) => state.usersError;
export const getUserAuthTreeLoading = (state: State) => state.authTreeLoading;
export const getUserAuthTree = (state: State) => state.authTree;
export const getUserAuthTreeError = (state: State) => state.authTreeError;
