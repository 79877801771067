import {Injectable} from '@angular/core';
import {RestServiceAbstract} from "../../abstracts/rest-service.abstract";
import {map, takeUntil} from "rxjs/operators";
import {Observable, Subject} from "rxjs";
import {SetCredentialsRequest} from "../../models/registration/set-credentials-request.model";
import {ICredentialsService} from "../../interfaces/credentials-service.interface";
import {Response} from "../../models/response.model";

@Injectable({
    providedIn: 'root'
})
export class PasswordRestService extends RestServiceAbstract implements ICredentialsService{

    protected ngUnsubscribe$: Subject<void> = new Subject<void>();

    /**
     * @param {Object} r
     * @returns {Observable<Object>}
     */
    public initPasswordReset(r: Object): Observable<Response> {
        return this.post(`/usermanagement/adminactivation/init`, r).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    /**
     * @returns {Observable<Response>}
     */
    public setCredentials(scr: SetCredentialsRequest): Observable<Response> {
        return this.post(`/usermanagement/adminactivation/setCredentials`, scr).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    /**
     * @returns{
     * @param {string} token
     * @returns {Observable<Response>}
     */
    public confirm(token: string): Observable<Response> {
        return this.get(`/usermanagement/adminactivation/activationconfirm?`, {params: {token: token}}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public sendResetPassword(body:any){
        return this.post(`/usermanagement/passwordReset/internal-reset`, body).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }
    
    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe$.next();
        // This completes the subject properly.
        this.ngUnsubscribe$.complete();
    }
}
