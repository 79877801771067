import { Component, OnInit } from '@angular/core';
import {Store} from "@ngrx/store";
import * as fromReducers from "../../../core/store/reducers";
import {UserLogoutAction} from "../../../core/store/actions/user.actions";

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(public store: Store<fromReducers.State>) {
    store.dispatch(new UserLogoutAction());
  }

  ngOnInit() {
  }

}
