import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PromotionProductsTempListComponent} from './promotion-products-temp-list.component';
import {SafehtmlModule} from '../../../shared/safehtml/safehtml.module';

@NgModule({
    declarations: [
        PromotionProductsTempListComponent
    ],
    exports: [
        PromotionProductsTempListComponent
    ],
    imports: [
        CommonModule,
        SafehtmlModule
    ]
})
export class PromotionProductsTempListModule {
}
