import * as fromPreorderActions from '../../actions/preorder/preorder.actions';
import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs';
import {PreorderRestService} from '../../../services/api/preorder.rest-service';

@Injectable()
export class PreorderEffects {

    constructor(
        private preorderRestService: PreorderRestService,
        private actions$: Actions
    ) {
    }

    @Effect()
    loadPreorders$: Observable<Action> = this.actions$.pipe(
        ofType<fromPreorderActions.InitLoadAllPreorders>
        (fromPreorderActions.PreorderActionTypes.INIT_LOAD_ALL_PREORDERS))
        .pipe(map(action => action.payload), switchMap((url) =>
                this.preorderRestService.loadAllPreordersByCategory(url).pipe(
                    map(data => new fromPreorderActions.LoadAllPreordersComplete(data.returnObject)),
                    catchError((error) => of(new fromPreorderActions.LoadAllPreordersFailed(error)))
                )
            )
        );
}

