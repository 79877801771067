import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LogService} from "../../core/services/utility/log.service";

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

    router: Router;
    error: any;
    errorJson: string;
    errorJsonForMailBody: string;
    status: string;

    constructor(router: Router,
                private route: ActivatedRoute,
                @Inject('ADMIN_MAIL') private ADMIN_MAIL: string,
                @Inject(LogService) private log: LogService) {
        this.router = router;
        /**
         * Passed in by {@link error.interceptor.ts}
         * @type {string | null}
         */
        this.errorJson = this.route.snapshot.queryParamMap.get('error');
        this.error = JSON.parse(this.errorJson);
        this.errorJsonForMailBody = encodeURIComponent(this.errorJson);
        this.log.info('Error: ', this.error);
    }

}
