import {SupplierActions, SupplierActionTypes} from '../../actions/preorder/supplier.actions';
import {HttpErrorResponse} from '@angular/common/http';

export interface State {
    addSupplierLoading: boolean;
    addSupplierError: HttpErrorResponse;
    editSupplierLoading: boolean;
    editSupplierError: HttpErrorResponse;
    suppliers: any[];
    getAllSuppliersLoading: boolean;
    getAllSuppliersError: HttpErrorResponse;
    selectedSupplierId: any;
}

export const initialState: State = {
    addSupplierLoading: false,
    addSupplierError: null,
    editSupplierLoading: false,
    editSupplierError: null,
    suppliers: [],
    getAllSuppliersLoading: false,
    getAllSuppliersError: null,
    selectedSupplierId: null,
};

export function reducer(state = initialState, action: SupplierActions): State {
    switch (action.type) {
        case SupplierActionTypes.INIT_ADD_SUPPLIER:
            return {
                ...state,
                addSupplierLoading: true,
                addSupplierError: null
            };
        case SupplierActionTypes.ADD_SUPPLIER_COMPLETE:
            return {
                ...state,
                addSupplierLoading: false,
                addSupplierError: null,
                selectedSupplierId: action.payload
            };
        case SupplierActionTypes.ADD_SUPPLIER_FAILED:
            return {
                ...state,
                addSupplierLoading: false,
                addSupplierError: action.payload
            };
        case SupplierActionTypes.INIT_EDIT_SUPPLIER:
            return {
                ...state,
                editSupplierLoading: true,
                editSupplierError: null
            };
        case SupplierActionTypes.EDIT_SUPPLIER_COMPLETE:
            return {
                ...state,
                editSupplierLoading: false,
                editSupplierError: null,
            };
        case SupplierActionTypes.EDIT_SUPPLIER_FAILED:
            return {
                ...state,
                editSupplierLoading: false,
                editSupplierError: action.payload
            };
        case SupplierActionTypes.INIT_LOAD_ALL_SUPPLIERS:
            return {
                ...state,
                getAllSuppliersLoading: true,
                getAllSuppliersError: null
            };
        case SupplierActionTypes.LOAD_ALL_SUPPLIERS_COMPLETE:
            return {
                ...state,
                getAllSuppliersLoading: false,
                getAllSuppliersError: null,
                suppliers: action.payload
            };
        case SupplierActionTypes.LOAD_ALL_SUPPLIERS_FAILED:
            return {
                ...state,
                getAllSuppliersLoading: false,
                getAllSuppliersError: action.payload
            };
        default:
            return state;
    }
}

export const getAddSupplierLoading = (state: State) => state.addSupplierLoading;
export const getAddSupplierError = (state: State) => state.addSupplierError;
export const getEditSupplierLoading = (state: State) => state.editSupplierLoading;
export const getEditSupplierError = (state: State) => state.editSupplierError;
export const getSuppliers = (state: State) => state.suppliers;
export const getSuppliersLoading = (state: State) => state.getAllSuppliersLoading;
export const getSuppliersError = (state: State) => state.getAllSuppliersError;
export const getNewCreatedSupplierId = (state: State) => state.selectedSupplierId;
