import {VaccineActions, VaccineActionTypes} from '../actions/vaccine.actions';
import {HttpErrorResponse} from '@angular/common/http';

export interface State {
    vaccines: any[];
    addVaccineLoading: boolean;
    addVaccineError: HttpErrorResponse;
    getAllVaccinesLoading: boolean;
    getAllVaccinesError: HttpErrorResponse;
    editVaccineLoading: boolean;
    editVaccineError: HttpErrorResponse;
}

export const initialState: State = {
    vaccines: [],
    addVaccineLoading: false,
    addVaccineError: null,
    getAllVaccinesLoading: false,
    getAllVaccinesError: null,
    editVaccineLoading: false,
    editVaccineError: null
};

export function reducer(state = initialState, action: VaccineActions): State {
    switch (action.type) {
        case VaccineActionTypes.INIT_ADD_VACCINE:
            return {
                ...state,
                addVaccineLoading: true,
                addVaccineError: null
            };
        case VaccineActionTypes.ADD_VACCINE_COMPLETE:
            return {
                ...state,
                addVaccineLoading: false,
                addVaccineError: null
            };
        case VaccineActionTypes.ADD_VACCINE_FAILED:
            return {
                ...state,
                addVaccineLoading: false,
                addVaccineError: action.payload
            };
        case VaccineActionTypes.INIT_LOAD_ALL_VACCINES:
            return {
                ...state,
                getAllVaccinesLoading: true,
                getAllVaccinesError: null
            };
        case VaccineActionTypes.LOAD_ALL_VACCINES_COMPLETE:
            return {
                ...state,
                getAllVaccinesLoading: false,
                getAllVaccinesError: null,
                vaccines: action.payload
            };
        case VaccineActionTypes.LOAD_ALL_VACCINES_FAILED:
            return {
                ...state,
                getAllVaccinesLoading: false,
                getAllVaccinesError: action.payload,
            };
        case VaccineActionTypes.INIT_EDIT_VACCINE:
            return {
                ...state,
                editVaccineLoading: true,
                editVaccineError: null
            };
        case VaccineActionTypes.EDIT_VACCINE_COMPLETE:
            return {
                ...state,
                editVaccineLoading: false,
                editVaccineError: null
            };
        case VaccineActionTypes.EDIT_VACCINE_FAILED:
            return {
                ...state,
                editVaccineLoading: false,
                editVaccineError: action.payload
            };
        default:
            return state;
    }
}

export const getAddVaccineLoading = (state: State) => state.addVaccineLoading;
export const getAddVaccineError = (state: State) => state.addVaccineError;
export const getVaccines = (state: State) => state.vaccines;
export const getVaccinesLoading = (state: State) => state.getAllVaccinesLoading;
export const getVaccinesError = (state: State) => state.getAllVaccinesError;
export const getEditVaccineLoading = (state: State) => state.editVaccineLoading;
export const getEditVaccineError = (state: State) => state.editVaccineError;
