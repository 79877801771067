import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { PharmacyUserPaginationModel } from '../../models/pharmacyUserPagination.model';

export enum PharmacyUserActionTypes {
    GET_ALL_PHARMACY_USERS = '[PharmacyUser] Get All Pharmacy Users',
    GET_ALL_PHARMACY_USERS_COMPLETE = '[PharmacyUser] Get All Pharmacy Users complete',
    GET_ALL_PHARMACY_USERS_FAILED = '[PharmacyUser] Get All Pharmacy Users failed',
    INIT_AUTH_TREE = '[PharmacyUser] Init PharmacyUser authorities tree',
    INIT_AUTH_TREE_COMPLETE = '[PharmacyUser] Init PharmacyUser authorities complete',
    INIT_AUTH_TREE_FAILED = '[PharmacyUser] Init PharmacyUser authorities failed'
}

export class GetAllPharmacyUsers implements Action {
    readonly type = PharmacyUserActionTypes.GET_ALL_PHARMACY_USERS;

    constructor(public payload: PharmacyUserPaginationModel) {
    }
}

export class GetAllPharmacyUsersComplete implements Action {
    readonly type = PharmacyUserActionTypes.GET_ALL_PHARMACY_USERS_COMPLETE;

    constructor(public payload: any) {
    }
}

export class GetAllPharmacyUsersFailed implements Action {
    readonly type = PharmacyUserActionTypes.GET_ALL_PHARMACY_USERS_FAILED;

    constructor(public payload: HttpErrorResponse) {
    }
}


export class InitAuthTree implements Action {
    readonly type = PharmacyUserActionTypes.INIT_AUTH_TREE;
}

export class InitAuthTreeComplete implements Action {
    readonly type = PharmacyUserActionTypes.INIT_AUTH_TREE_COMPLETE;

    constructor(public payload: any) { // SubUser[]
    }
}

export class InitAuthTreeFailed implements Action {
    readonly type = PharmacyUserActionTypes.INIT_AUTH_TREE_FAILED;

    constructor(public payload: any) {
    }
}

export type PharmacyUserActions =
    GetAllPharmacyUsers |
    GetAllPharmacyUsersComplete |
    GetAllPharmacyUsersFailed |
    InitAuthTree |
    InitAuthTreeComplete |
    InitAuthTreeFailed;
