import {MessageActions, MessageActionTypes} from "../actions/message.actions";
import {HttpErrorResponse} from "@angular/common/http";


export interface State {
    messageRequest: any;
    messageResponse: any;
    messageErrorResponse: HttpErrorResponse;
    messagesLoading: boolean;
    messageLoading: boolean;
}

export const initialState: State = {
    messageRequest: null,
    messageResponse: null,
    messageErrorResponse: null,
    messagesLoading: false,
    messageLoading: false,

};

export function reducer(state = initialState, action: MessageActions): State {
    switch (action.type) {
        case MessageActionTypes.LOAD_MESSAGES:
            return {
                ...state,
                messageResponse: action.payload,
                messagesLoading: true
            };
        case MessageActionTypes.LOAD_MESSAGES_COMPLETE:
            return {
                ...state,
                messageResponse: action.payload,
                messagesLoading: false
            };
        case MessageActionTypes.LOAD_MESSAGE:
            return {
                ...state,
                messageLoading: true
            };
        case MessageActionTypes.LOAD_MESSAGE_COMPLETE:
            // TODO: merge content into response
            const newResults = [];
            for (const message of state.messageResponse.results) {
                if (message.id === action.payload.results[0].id) {
                    message.content = action.payload.results[0].content;
                }
                newResults.push(message);
            }
            const newMessagesResponse = {...action.payload, ...{results: newResults}};
            return {
                ...state,
                messageResponse: {...state.messageResponse, ...newMessagesResponse},
                messageLoading: false
            };
        case MessageActionTypes.MESSAGE_FAILED:
            return {
                ...state,
                messageErrorResponse: action.payload,
                messagesLoading: false
            };
        default:
            return state;
    }
}

export const getMessageRequest = (state: State) => state.messageRequest;
export const getMessageResponse = (state: State) => state.messageResponse;
export const getMessageErrorResponse = (state: State) => state.messageErrorResponse;
export const getMessagesLoading = (state: State) => state.messagesLoading;
export const getMessageLoading = (state: State) => state.messageLoading;
