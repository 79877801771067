import { PharmacyUserActions, PharmacyUserActionTypes} from '../actions/pharmacyUser.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from '../../models/pharmacyUser.model';
import { PharmacyUserPaginationModel } from '../../models/pharmacyUserPagination.model';
import { AuthorizationNodeModel } from '../../../modules/pharmacy-user-management/model/authorization-node.model';


export interface State {
    usersRequest: PharmacyUserPaginationModel;
    users: User[];
    usersResponse: any;
    usersError: HttpErrorResponse;
    usersLoading: boolean;
    authTree: AuthorizationNodeModel[];
    authTreeError: HttpErrorResponse;
    authTreeLoading: boolean;
}

export const initialState: State = {
    usersRequest: new PharmacyUserPaginationModel(),
    users: [],
    usersResponse: null,
    usersError: null,
    usersLoading: false,
    authTree: null,
    authTreeError: null,
    authTreeLoading: false
};

export function reducer(state = initialState, action: PharmacyUserActions): State {
    switch (action.type) {
        case PharmacyUserActionTypes.GET_ALL_PHARMACY_USERS:
            return {
                ...state,
                usersLoading: true,
                usersRequest: action.payload
            };
        case PharmacyUserActionTypes.GET_ALL_PHARMACY_USERS_COMPLETE:
            return {
                ...state,
                users: action.payload.result,
                usersResponse: action.payload,
                usersLoading: false
            };
        case PharmacyUserActionTypes.GET_ALL_PHARMACY_USERS_FAILED:
            return {
                ...state,
                usersError: action.payload,
                usersLoading: false
            };
        case PharmacyUserActionTypes.INIT_AUTH_TREE:
            return {
                ...state,
                authTreeLoading: true
            };
        case PharmacyUserActionTypes.INIT_AUTH_TREE_COMPLETE:
            const authTree = [new AuthorizationNodeModel({ label: 'SUBUSER.ALL_RIGHTS' })];
            authTree[0].children = action.payload.map(node => new AuthorizationNodeModel(node));
            return {
                ...state,
                authTree: authTree,
                authTreeLoading: false
            };
        case PharmacyUserActionTypes.INIT_AUTH_TREE_FAILED:
            return {
                ...state,
                authTreeError: action.payload,
                authTreeLoading: false
            };
        default:
            return state;
    }
}


export const getPharmacyUsersRequest = (state: State) => state.usersRequest;
export const getPharmacyUsers = (state: State) => state.users;
export const getPharmacyUsersResponse = (state: State) => state.usersResponse;
export const getPharmacyUsersLoading = (state: State) => state.usersLoading;
export const getPharmacyUsersError = (state: State) => state.usersError;
export const getPharmacyUserAuthTreeLoading = (state: State) => state.authTreeLoading;
export const getPharmacyUserAuthTree = (state: State) => state.authTree;
export const getPharmacyUserAuthTreeError = (state: State) => state.authTreeError;
