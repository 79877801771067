import * as fromPromotionActions from '../actions/promotions.action';
import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs';
import {PromotionsRestService} from '../../services/api/promotions.rest-service';

@Injectable()
export class PromotionsEffects {

    constructor(
        private promotionsRestService: PromotionsRestService,
        private actions$: Actions
    ) {
    }

    @Effect()
    loadPromotions$: Observable<Action> = this.actions$
        .pipe(ofType<fromPromotionActions.InitLoadAllPromotions>(fromPromotionActions.PromotionsActionTypes.INIT_LOAD_ALL_PROMOTIONS))
        .pipe(map(action => action), switchMap((rq) =>
                this.promotionsRestService.loadAllPromotions(rq.payload).pipe(
                    map(data => new fromPromotionActions.LoadAllPromotionsComplete(data.returnObject)),
                    catchError((error) => of(new fromPromotionActions.LoadAllPromotionsFailed(error)))
                )
            )
        );
}
