import {EsareActions, EsareActionTypes} from '../actions/esare.actions';
import {HttpErrorResponse} from '@angular/common/http';

export interface State {
    supplements: any[];
    addSupplementLoading: boolean;
    addSupplementError: HttpErrorResponse;
    getAllSupplementsLoading: boolean;
    getAllSupplementsError: HttpErrorResponse;
    editSupplementLoading: boolean;
    editSupplementError: HttpErrorResponse;
}

export const initialState: State = {
    supplements: [],
    addSupplementLoading: false,
    addSupplementError: null,
    getAllSupplementsLoading: false,
    getAllSupplementsError: null,
    editSupplementLoading: false,
    editSupplementError: null
};

export function reducer(state = initialState, action: EsareActions): State {
    switch (action.type) {
        case EsareActionTypes.INIT_ADD_SUPPLEMENT:
            return {
                ...state,
                addSupplementLoading: true,
                addSupplementError: null
            };
        case EsareActionTypes.ADD_SUPPLEMENT_COMPLETE:
            return {
                ...state,
                addSupplementLoading: false,
                addSupplementError: null
            };
        case EsareActionTypes.ADD_SUPPLEMENT_FAILED:
            return {
                ...state,
                addSupplementLoading: false,
                addSupplementError: action.payload
            };
        case EsareActionTypes.INIT_LOAD_ALL_SUPPLEMENTS:
            return {
                ...state,
                getAllSupplementsLoading: true,
                getAllSupplementsError: null
            };
        case EsareActionTypes.LOAD_ALL_SUPPLEMENTS_COMPLETE:
            const theMonths = ['', 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli',
                'August', 'September', 'Oktober', 'November', 'Dezember'];
            const allSupplements: any = [];
            for (let i = 0; i < action.payload.length; i++) {
                allSupplements[i] = {...action.payload[i]};
                const month = theMonths[action.payload[i].id.toString().slice(4)];
                const year = action.payload[i].id.toString().slice(0, 4);
                allSupplements[i]['month'] = month + ' ' + year;
            }
            return {
                ...state,
                getAllSupplementsLoading: false,
                getAllSupplementsError: null,
                supplements: allSupplements
            };
        case EsareActionTypes.LOAD_ALL_SUPPLEMENTS_FAILED:
            return {
                ...state,
                getAllSupplementsLoading: false,
                getAllSupplementsError: action.payload,
            };
        case EsareActionTypes.INIT_EDIT_SUPPLEMENT:
            return {
                ...state,
                editSupplementLoading: true,
                editSupplementError: null
            };
        case EsareActionTypes.EDIT_SUPPLEMENT_COMPLETE:
            return {
                ...state,
                editSupplementLoading: false,
                editSupplementError: null
            };
        case EsareActionTypes.EDIT_SUPPLEMENT_FAILED:
            return {
                ...state,
                editSupplementLoading: false,
                editSupplementError: action.payload
            };
        default:
            return state;
    }
}

export const getAddSupplementLoading = (state: State) => state.addSupplementLoading;
export const getAddSupplementError = (state: State) => state.addSupplementError;
export const getSupplements = (state: State) => state.supplements;
export const getSupplementsLoading = (state: State) => state.getAllSupplementsLoading;
export const getSupplementsError = (state: State) => state.getAllSupplementsError;
export const getEditSupplementLoading = (state: State) => state.editSupplementLoading;
export const getEditSupplementError = (state: State) => state.editSupplementError;
