import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'registration-heading',
    templateUrl: './registration-heading.component.html',
    styleUrls: ['./registration-heading.component.scss']
})
export class RegistrationHeadingComponent implements OnInit {
    @Input() public redirection;

    constructor() {
    }

    ngOnInit() {
    }

}
