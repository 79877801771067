import {Action} from '@ngrx/store';

export enum MatomoActionTypes {
    LOAD_CATEGORIES = '[MATOMO] Load Categories',
    LOAD_CATEGORIES_COMPLETE = '[MATOMO] Load Categories Complete',
    LOAD_CATEGORIES_FAIL = '[MATOMO] Load Categories Fail',
    LOAD_ACTIONS = '[MATOMO] Load Categories',
    LOAD_ACTIONS_COMPLETE = '[MATOMO] Load Categories Complete',
    LOAD_ACTIONS_FAIL = '[MATOMO] Load Categories Fail',
    LOAD_IMPRESSIONS = '[MATOMO] Load Categories',
    LOAD_IMPRESSIONS_COMPLETE = '[MATOMO] Load Categories Complete',
    LOAD_IMPRESSIONS_FAIL = '[MATOMO] Load Categories Fail'
}

export class LoadCategoriesAction implements Action {
    readonly type = MatomoActionTypes.LOAD_CATEGORIES;
    constructor(public payload: any) {
    }
}

export class LoadCategoriesCompleteAction implements Action {
    readonly type = MatomoActionTypes.LOAD_CATEGORIES_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadCategoriesFailAction implements Action {
    readonly type = MatomoActionTypes.LOAD_CATEGORIES_FAIL;

    constructor(public payload: any) {
    }
}

export class LoadActionsAction implements Action {
    readonly type = MatomoActionTypes.LOAD_ACTIONS;
    constructor(public payload: any) {
    }
}

export class LoadActionsCompleteAction implements Action {
    readonly type = MatomoActionTypes.LOAD_ACTIONS_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadActionsFailAction implements Action {
    readonly type = MatomoActionTypes.LOAD_ACTIONS_FAIL;

    constructor(public payload: any) {
    }
}

export class LoadImpressionsAction implements Action {
    readonly type = MatomoActionTypes.LOAD_IMPRESSIONS;
    constructor(public payload: any) {
    }
}

export class LoadImpressionsCompleteAction implements Action {
    readonly type = MatomoActionTypes.LOAD_IMPRESSIONS_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadImpressionsFailAction implements Action {
    readonly type = MatomoActionTypes.LOAD_IMPRESSIONS_FAIL;

    constructor(public payload: any) {
    }
}


export type MatomoActions = LoadCategoriesAction |
    LoadCategoriesCompleteAction |
    LoadCategoriesFailAction |
    LoadActionsAction |
    LoadActionsCompleteAction |
    LoadActionsFailAction |
    LoadImpressionsAction |
    LoadImpressionsCompleteAction |
    LoadImpressionsFailAction;