import {map, takeUntil} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {Subject, Observable, throwError} from 'rxjs';
import {Response} from '../../models/response.model';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class BeraterUserRestService extends RestServiceAbstract {
    protected ngUnsubscribe$: Subject<void> = new Subject<void>();

    public loadPurchasingGroups() {
        return this.get('/usermanagement/admin/consultants/purchasing-groups').pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public loadAllBeraterUser() {
        return this.get('/usermanagement/admin/consultants').pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public addBeraterUser(rq) {
        return this.post(`/usermanagement/admin/consultants`, rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public updateBeraterUser(rq: any): Observable<Response> {
        return this.put(`/usermanagement/admin/consultants`, rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public deleteBeraterUser(request: any) {
        return this.delete(`/usermanagement/admin/consultants`, {body: request }).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

}
