import {UserAuthNodeInterface} from '../interface/user-auth-node.interface';

export class UserAuthNodeModel implements UserAuthNodeInterface {
    authority: string;
    children: UserAuthNodeModel[];
    label: string;
    expandable: boolean;
    level: number;

    constructor(obj?: any) {
        if (obj && obj.children && obj.children.length > 0) {
            this.children = obj.children.map(node => new UserAuthNodeModel({...node})) || null;
        } else {
            delete this.children;
        }
        this.authority = obj && obj.authority || null;
        this.label = obj && obj.label || null;
        this.expandable = obj && obj.expandable || null;
        this.level = obj && obj.level || null;
    }
}
