import {Inject, Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "./api/auth.service";
import {LogService} from "./utility/log.service";

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(@Inject(AuthService) public authService: AuthService,
                public log: LogService,
                public router: Router,
                ) {
        this.log.info('AuthGuard --- active');
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        this.log.info('AuthGuard --- Route canActivate');
        if (!this.authService.isAuthenticated()) {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
            return false;
        }
        return true;
    }
}
