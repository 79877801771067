import {Injectable} from '@angular/core';
import {PreorderRestService} from '../../../services/api/preorder.rest-service';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import * as fromPreorderCategoryActions from '../../actions/preorder/category.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';


@Injectable()
export class PreorderCategoryEffects {
    constructor(
        private preorderRestService: PreorderRestService,
        private actions$: Actions
    ) {
    }

    @Effect()
    loadCategories$: Observable<Action> = this.actions$.pipe(
        ofType<fromPreorderCategoryActions.InitLoadAllCategories>(fromPreorderCategoryActions.CategoryActionTypes.INIT_LOAD_ALL_CATEGORIES))
        .pipe(switchMap(() =>
                this.preorderRestService.loadAllCategories().pipe(
                    map(data => new fromPreorderCategoryActions.LoadAllCategoriesComplete(data.returnObject)),
                    catchError((error) => of(new fromPreorderCategoryActions.LoadAllCategoriesFailed(error)))
                )
            )
        );

    @Effect()
    loadCategory$: Observable<Action> = this.actions$.pipe(
        ofType<fromPreorderCategoryActions.InitLoadCategory>
        (fromPreorderCategoryActions.CategoryActionTypes.INIT_LOAD_CATEGORY))
        .pipe(map(action => action.payload), switchMap((categoryUrl) =>
                this.preorderRestService.loadCategory(categoryUrl).pipe(
                    map(data => new fromPreorderCategoryActions.LoadCategoryComplete(data.returnObject)),
                    catchError((error) => of(new fromPreorderCategoryActions.LoadCategoryFailed(error)))
                )
            )
        );
}
