<h2>Report for slider {{ slide.title }}</h2>
<!--<hr class="mb-l">-->
<div class="row mb-xl">
    <div class="col-2">
        <div class="mb-l">
            <strong>Buchung</strong>
            <p>{{slide.title}}</p>
        </div>
        <div class="mb-l">
            <strong>Werbeform</strong>
            <p>Slider</p>
        </div>
        <div class="mb-l">
            <strong>Zeitraum</strong>
            <p>{{getDate(slide.start) | date: 'dd.MM.YYYY'}} - <br>{{getDate(slide.end) | date: 'dd.MM.YYYY'}}</p>
        </div>
    </div>
    <div class="col-10"><div class="image-container"><img src="{{ CDN }}/marketing-management/{{slide.image}}" alt="{{ slide.title }}"/></div></div>
</div>
<div class="performance-slide pt-xxl" id="{{getSlideId()}}" [attr.data-click]="click"  [attr.data-type]="'Slider'">
<div class="row">
    <div class="col-2">
        Click-performance
    </div>
    <div class="col-10">
        <div class="click w-100 mb-l">
            <div class="d-flex">
                <div class="vis-1">
                    <div class="marker" [style.left]="click * 100 + '%'" *ngIf="click <= 1">
                        <div class="value d-flex flex-column text-center">
                            <small>{{ click }}</small>
                            <span class="fa fa-caret-down"></span>
                        </div>
                    </div>
                </div>
                <div class="vis-2">
                    <div class="marker" [style.left]="((click-1)/click) * 100 + '%'" *ngIf="click > 1">
                        <div class="value d-flex flex-column text-center">
                            <small>{{ click }}</small>
                            <span class="fa fa-caret-down"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="red"></div>
                <div class="yellow"></div>
                <div class="green"></div>
            </div>
        </div>
    </div>
</div>
</div>