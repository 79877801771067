import {Action} from '@ngrx/store';

export enum SupplierActionTypes {
    INIT_ADD_SUPPLIER = '[Preorder-supplier] Init Add Supplier',
    ADD_SUPPLIER_COMPLETE = '[Preorder-supplier] Add Supplier complete',
    ADD_SUPPLIER_FAILED = '[Preorder-supplier] Add Supplier failed',
    INIT_EDIT_SUPPLIER = '[Preorder-supplier] Init Edit Supplier',
    EDIT_SUPPLIER_COMPLETE = '[Preorder-supplier] Edit Supplier Complete',
    EDIT_SUPPLIER_FAILED = '[Preorder-supplier] Edit Supplier Failed',
    INIT_LOAD_ALL_SUPPLIERS = '[Preorder-supplier] Init Load All Suppliers',
    LOAD_ALL_SUPPLIERS_COMPLETE = '[Preorder-supplier] Load All Suppliers Complete',
    LOAD_ALL_SUPPLIERS_FAILED = '[Preorder-supplier] Load All Suppliers Failed',
}

export class InitAddSupplier implements Action {
    readonly type = SupplierActionTypes.INIT_ADD_SUPPLIER;
}

export class AddSupplierComplete implements Action {
    readonly type = SupplierActionTypes.ADD_SUPPLIER_COMPLETE;

    constructor(public payload: any) {
    }
}

export class AddSupplierFailed implements Action {
    readonly type = SupplierActionTypes.ADD_SUPPLIER_FAILED;

    constructor(public payload: any) {
    }
}

export class InitEditSupplier implements Action {
    readonly type = SupplierActionTypes.INIT_EDIT_SUPPLIER;

}

export class EditSupplierComplete implements Action {
    readonly type = SupplierActionTypes.EDIT_SUPPLIER_COMPLETE;

}

export class EditSupplierFailed implements Action {
    readonly type = SupplierActionTypes.EDIT_SUPPLIER_FAILED;

    constructor(public payload: any) {
    }
}

export class InitLoadAllSuppliers implements Action {
    readonly type = SupplierActionTypes.INIT_LOAD_ALL_SUPPLIERS;
}

export class LoadAllSuppliersComplete implements Action {
    readonly type = SupplierActionTypes.LOAD_ALL_SUPPLIERS_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadAllSuppliersFailed implements Action {
    readonly type = SupplierActionTypes.LOAD_ALL_SUPPLIERS_FAILED;

    constructor(public payload: any) {
    }
}

export type SupplierActions =
    InitAddSupplier |
    AddSupplierComplete |
    AddSupplierFailed |
    InitEditSupplier |
    EditSupplierComplete |
    EditSupplierFailed |
    InitLoadAllSuppliers |
    LoadAllSuppliersComplete |
    LoadAllSuppliersFailed;
