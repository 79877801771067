import * as fromEsareActions from '../actions/esare.actions';
import { Injectable} from '@angular/core';
import { Actions, Effect, ofType} from '@ngrx/effects';
import { Action} from '@ngrx/store';
import { catchError, map, switchMap} from 'rxjs/operators';
import { of} from 'rxjs/internal/observable/of';
import { Observable} from 'rxjs';
import { EsareRestService } from '../../services/api/esare.rest-service';

@Injectable()
export class EsareEffects {

    constructor(
        private esareRestService: EsareRestService,
        private actions$: Actions
    ) {}

    @Effect()
    loadSupplements$: Observable<Action> = this.actions$.pipe(
        ofType<fromEsareActions.InitLoadAllSupplements>(fromEsareActions.EsareActionTypes.INIT_LOAD_ALL_SUPPLEMENTS))
        .pipe(switchMap(() =>
                this.esareRestService.loadAllSupplements().pipe(
                    map(data => new fromEsareActions.LoadAllSupplementsComplete(data.returnObject)),
                    catchError((error) => of(new fromEsareActions.LoadAllSupplementsFailed(error)))
                )
            )
        );
}
