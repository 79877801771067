import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PasswordRestService} from '../../../core/services/api/password.rest-service';
import {Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Response} from '../../../core/models/response.model';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'password-forgotten',
    templateUrl: './password-forgotten.component.html',
    styleUrls: ['./password-forgotten.component.scss']
})
export class PasswordForgottenComponent implements OnInit, OnDestroy {

    public resetPasswordForm: FormGroup;   

    public name: FormControl = new FormControl('');
    public surname: FormControl = new FormControl('');
    public email: FormControl = new FormControl('', [
        Validators.required,
        Validators.email
    ]);
    public number: FormControl = new FormControl('');
    
    
    public success = false;
    public response: Response;
    public submitted = false;
    public submitButtonClicked = false;
    public error = false;

    private ngUnsubscribe$ = new Subject<any>(); // emit unsubscription

    constructor(
        private formBuilder: FormBuilder, 
        @Inject(PasswordRestService) private passwordRestService: PasswordRestService, 
        public router: Router,
        public dialogRef: MatDialogRef<PasswordForgottenComponent>, // MatDialogRef für den Dialog
        @Inject(MAT_DIALOG_DATA) public data: any // Daten für den Dialog
    ) {
        this.resetPasswordForm = formBuilder.group({
            firstName: this.name,
            lastName: this.surname,
            email: this.email,
            phoneNumber: this.number         
        });
    }

    ngOnInit() {

    }

    submit(): void {
        this.submitButtonClicked = true; // show error class on checkboxes
        if (this.resetPasswordForm.valid) {
            const request = this.resetPasswordForm.value;
            this.submitted = true;// hide form and show spinner

            this.passwordRestService.sendResetPassword(request).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((response: Response) => {
                    this.success = true;
                    this.response = response;
                    this.dialogRef.close({success: true});
                },
                err => {
                    // Do stuff with your error
                    this.submitted = false;
                    this.error = true;
                    console.log(err);
                });
        }
    }

    /**
     * unsubcribe all subscriptions on destroy
     */
    onCancel(): void {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
