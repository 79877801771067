import {Action} from '@ngrx/store';
import {AccountsPaginationRequestModel} from '../../models/accounts-pagination-request.model';

export enum AccountActionTypes {
    INIT_LOAD_ALL_ACCOUNTS = '[Account] Init Load all accounts',
    LOAD_ALL_ACCOUNTS_COMPLETE = '[Account] Load all accounts complete',
    LOAD_ALL_ACCOUNTS_FAILED = '[Account] Load all accounts failed'
}

export class InitLoadAllAccounts implements Action {
    readonly type = AccountActionTypes.INIT_LOAD_ALL_ACCOUNTS;

    constructor(public payload: AccountsPaginationRequestModel) {
    }
}

export class LoadAllAccountsComplete implements Action {
    readonly type = AccountActionTypes.LOAD_ALL_ACCOUNTS_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadAllAccountsFailed implements Action {
    readonly type = AccountActionTypes.LOAD_ALL_ACCOUNTS_FAILED;

    constructor(public payload: any) {
    }
}

export type AccountActions =
    InitLoadAllAccounts |
    LoadAllAccountsComplete |
    LoadAllAccountsFailed;
