import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {DelayInfoRestService} from '../../services/api/delay-info.rest-service';
import * as fromDelayInfoActions from '../actions/delay-info.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';


@Injectable()
export class DelayInfoEffects {
    constructor(private delayInfoRestService: DelayInfoRestService,
                private actions$: Actions) {
    }

    @Effect()
    loadForms$: Observable<Action> = this.actions$.pipe(
        ofType<fromDelayInfoActions.LoadDelaysAction>(fromDelayInfoActions.DelayInfoActionTypes.LOAD_DELAYS))
        .pipe(map(action => action.payload),switchMap((rq) =>
                this.delayInfoRestService.loadDelays(rq).pipe(
                    map(data => new fromDelayInfoActions.LoadDelaysCompleteAction(data.returnObject)),
                    catchError((error) => of(new fromDelayInfoActions.LoadDelaysFailAction(error)))
                )
            )
        );
}