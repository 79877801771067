import {Component, ElementRef, Inject, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {MatomoRestService} from '../../../core/services/api/matomo.rest-service';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {delay, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AdsRestService} from '../../../core/services/api/cms/ads.rest-service';
import {BlobRestService} from '../../../core/services/api/blob.rest-service';
import {MarketingService} from '../../../core/services/marketing.service';
import {jsPDF} from 'jspdf';
import * as htmlToImage from 'html-to-image';
import {MatTabGroup} from '@angular/material/tabs';
import * as CryptoJS from 'crypto-js';
import {VendorsService} from '../../../core/services/vendors.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';


@Component({
    selector: 'phx-supplier-report',
    templateUrl: './supplier-report.component.html',
    styleUrls: ['./supplier-report.component.scss']
})
export class SupplierReportComponent implements OnInit, OnDestroy {
    private ngUnsubscribe$ = new Subject<any>(); // emit unsubscription
    @ViewChild('tabs', {static: false}) tabs: MatTabGroup;

    dataForm = this.formBuilder.group({
        selectedAds: [''],
        selectedSlide: [''],
        selectedAdvertorial: [''],
        idfCount: ['', Validators.required]
    });
    ads = [];
    adsForDate = [];
    options = {};
    loadingAds = true;
    click: number = 0;
    view: number = 0;
    group: number = 1;

    private impressions: any;

    selectedSlides: any;
    selectedAds = [];
    selectedAdvert: any = null;
    sliders = [];
    adverts = [];
    loadingSliders = true;
    selectedDialog: any;
    pdf;
    added = false;
    vendors = [];
    filteredOptions: Observable<string[]>;
    vendorFilter = new FormControl();
    locations: any = [
        {value: 'DASH', label: 'Super Banner'},
        {value: 'RIGHT', label: 'Rechts Skyscraper Banner'},
        {value: 'LEFT', label: 'Links Skyscraper Banner'},
        {value: 'MENU', label: 'Square Banner'},
        {value: 'PROMO', label: 'Promotions Banner'}
    ];

    displayFn(vendor: any): string {
        return vendor && vendor.name ? vendor.name : '';
    }


    constructor(private matomoService: MatomoRestService,
                private adsService: AdsRestService,
                private blobService: BlobRestService,
                private marketingService: MarketingService,
                private formBuilder: FormBuilder,
                private elementRef: ElementRef,
                private vendorsService: VendorsService,
                @Inject('CDN') public CDN) {
        this.vendorsService.loadAllVendors().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(ven => {
            this.vendors = ven;
        });
    }

    ngOnInit() {
        this.filteredOptions = this.vendorFilter.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.name),
                map(name => name ? this._filter(name) : this.vendors.slice())
            );
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.vendors.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    getData() {
        if (!this.dataForm.valid) {
            return;
        }

        const values = this.dataForm.value;

        this.selectedAds = this.adsForDate.filter(ad => values['selectedAds'].includes(ad.id));
        this.selectedSlides = this.sliders.filter(slide => values['selectedSlide'].includes(slide.title));
        this.selectedAdvert = values['selectedAdvertorial'];

        this.pdf = new jsPDF();
    }

    addAdFormat(y, fontSize, font, selected, doc, elType) {
        doc.setTextColor(0);
        doc.setFontSize(fontSize);
        doc.setFont(font, 'normal', 700);
        doc.text('Buchung', 10, y);
        y = y + (fontSize / 2);

        doc.setFont(font, 'normal', 400);
        doc.text(selected.title, 10, y);

        y += fontSize;

        doc.setFont(font, 'normal', 700);
        doc.text('Werbeform', 10, y);
        y += (fontSize / 2);

        doc.setFont(font, 'normal', 400);
        doc.text(elType, 10, y);
        y = y + fontSize;

        doc.setFont(font, 'normal', 700);
        doc.text('Zeitraum', 10, y);

        doc.setFont(font, 'normal', 400);
        y += (fontSize / 2);

        const start = new Date(selected.start);
        const end = new Date(selected.end);

        doc.text(start.getDate() + '.' + start.getMonth() + '.' + start.getFullYear() + ' -', 10, y);
        y += (fontSize / 2);
        doc.text(end.getDate() + '.' + end.getMonth() + '.' + end.getFullYear(), 10, y);

        return y;
    }

    addToFile(part) {
        this.added = true;
        let collection;
        let i = 1;
        let y = 10;

        this.pdf.addImage('/assets/img/apothekenportal_icon_text_gruen_filled.png', 'png', this.pdf.internal.pageSize.getWidth() - 55, y - 5);
        this.pdf.setFont('helvetica', 'normal', 400);

        const pageWidth = this.pdf.internal.pageSize.width || this.pdf.internal.pageSize.getWidth();

        const baseFontSize = 10;
        const h2FontSize = 20;

        const dis = this;

        switch (part) {
            case 'ads':
                collection = (document).getElementsByClassName('performance-ad');
                break;
            case 'slides':
                collection = (document).getElementsByClassName('performance-slide');
                break;
            default:
                break;

        }

        console.log(this.pdf.getCurrentPageInfo().pageNumber);


        for (let item of collection) {
            let el = new ElementRef(item);
            let selected;

            let id = el.nativeElement.id;
            let click = el.nativeElement.dataset.click;
            let elType = el.nativeElement.dataset.type;
            let group = 0;
            let view = 0;
            let viewPerc = 0;
            const font = 'helvetica';

            if (part === 'ads') {
                group = el.nativeElement.dataset.group;
                view = el.nativeElement.dataset.view;
                viewPerc = el.nativeElement.dataset.viewPerc;

                selected = this.selectedAds.find(a => {
                    return a.id === parseInt(id);
                });
            }

            if (part === 'slides') {
                selected = this.selectedSlides.find(s => {
                    return id === CryptoJS.HmacSHA256(s.title + s.start + s.end, 'slide').toString(CryptoJS.enc.Base64);
                });
            }

            if (selected) {
                htmlToImage.toPng(item)
                    .then(function (dataUrl) {
                        y += 20;
                        dis.pdf.setFont(font, 'normal', 400);
                        dis.pdf.setFontSize(h2FontSize);
                        dis.pdf.setTextColor(0, 151, 117);
                        dis.pdf.text((selected.title).toUpperCase(), 10, y);
                        y += 3;
                        dis.pdf.setFillColor(0, 151, 117);
                        dis.pdf.line(10, y, dis.pdf.internal.pageSize.getWidth() - 10, y, 'F');

                        y += 3;
                        dis.pdf.setFillColor(242, 242, 242);
                        dis.pdf.rect(70, y + 7, dis.pdf.internal.pageSize.getWidth() - 80, 80, 'F');

                        const img = dis.CDN + '/marketing-management/' + selected.image;
                        const imgProp = dis.pdf.getImageProperties(dis.CDN + '/marketing-management/' + selected.image);

                        const rat = imgProp.height / imgProp.width;
                        if (part === 'ads') {
                            if (selected.location === 'DASH') {
                                dis.pdf.addImage(img, imgProp.fileType, 70, y + 7, dis.pdf.internal.pageSize.getWidth() - 80, (dis.pdf.internal.pageSize.getWidth() - 80) * rat);
                            } else {
                                let x = 70;
                                if (selected.location === 'RIGHT') {
                                    x = dis.pdf.internal.pageSize.getWidth() - (80 / rat) - 10;
                                }
                                dis.pdf.addImage(img, imgProp.fileType, x, y + 7, 80 / rat, 80);
                            }
                        } else {
                            dis.pdf.addImage(img, imgProp.fileType, 70, y + 7, dis.pdf.internal.pageSize.getWidth() - 80, (dis.pdf.internal.pageSize.getWidth() - 80) * rat);
                        }

                        y += 25;

                        y = dis.addAdFormat(y, baseFontSize, font, selected, dis.pdf, elType);

                        y += 35;

                        dis.pdf.setFontSize(h2FontSize);
                        dis.pdf.setTextColor(0, 151, 117);
                        dis.pdf.text('PERFORMANCE', 10, y);
                        dis.pdf.setFillColor(0, 151, 117);
                        dis.pdf.line(10, y + 3, dis.pdf.internal.pageSize.getWidth() - 10, y + 3, 'F');
                        y += 6;
                        const ratio = el.nativeElement.clientHeight / el.nativeElement.clientWidth;

                        dis.pdf.addImage(dataUrl, 'png', 10, y, pageWidth - 20, (pageWidth - 20) * ratio);

                        y += 80;
                        dis.pdf.setFontSize(h2FontSize);
                        dis.pdf.setTextColor(0, 151, 117);
                        dis.pdf.text('INTERPRETATION', 10, y);
                        dis.pdf.setFillColor(0, 151, 117);
                        dis.pdf.line(10, y + 3, dis.pdf.internal.pageSize.getWidth() - 10, y + 3, 'F');
                        dis.pdf.setFontSize(baseFontSize);
                        dis.pdf.setTextColor(0);

                        y += 10;

                        let clickPerf = 'unterdurchschnittliche';

                        if (click >= 0.5) {
                            clickPerf = 'durchschnittliche';
                        }

                        if (click >= 1) {
                            clickPerf = 'überdurchschnittliche';
                        }
                        let text = 'Ihre ' + elType + ' Buchung zu „' + selected.title + '“ generierte in der relevanten Zielgruppe eine ' + clickPerf + ' Clickperformance. ';

                        if (group > 0) {
                            text += 'Es wurde eine Zielgruppeabdeckung von ' + group * 100 + '% im Buchungszeitraum erreicht. ';
                        }

                        if (view > 0) {
                            let viewPerf = 'unterdurchschnittliche';

                            if (viewPerc >= 0.3) {
                                viewPerf = 'durchschnittliche';
                            }

                            if (viewPerc >= 0.6) {
                                viewPerf = 'überdurchschnittliche';
                            }
                            text += 'Es wurde eine ' + viewPerf + ' Sichtbarkeit von ' + view + ' im Buchungszeitraum erreicht.';
                        }

                        dis.pdf.text(text, 10, y, {maxWidth: dis.pdf.internal.pageSize.getWidth() - 20});

                        dis.addFooter(dis.pdf, elType);
                        dis.pdf.setTextColor(0);

                        y = 10;

                        if (dis.pdf.getCurrentPageInfo().pageNumber !== collection.length) {
                            dis.pdf.addPage();
                        }
                    });
            }

        }
    }

    exportPdf() {
        this.pdf.save('hersteller-report.pdf');
        this.added = false;
        this.pdf = new jsPDF();
    }

    addFooter(doc, type) {
        let y = doc.internal.pageSize.getHeight() - (8 * 5);
        doc.setFillColor('#999999');
        doc.line(10, y - 3, doc.internal.pageSize.getWidth() - 10, y - 3, 'F');

        y += 2;
        doc.setFontSize(8);
        doc.setTextColor('#999999');
        doc.text('Die ' + type + ' Performance wird anhand eines Benchmarkings ausgerichtet. Dieses beziecht sich auf vergleichbare ' + type + ' Buchungen im Apothekenportal in \r\neinem Referenzzeitraum', 10, y);
        y += 8;
        doc.setFont('helvetica', 'normal', 700);
        doc.text('Click-Performance', 10, y);
        doc.setFont('helvetica', 'normal', 400);
        let dimensions = doc.getTextDimensions('Click-Performance');
        doc.text(' Beschreibt das Verhältnis von Klicks auf Tagesbasis unter Berücksichtigung der Selektionsgüte.', dimensions.w * 1.5, y);

        y += 8;
        doc.setFont('helvetica', 'normal', 700);
        doc.text('Zielgruppenabdeckung', 10, y);
        doc.setFont('helvetica', 'normal', 400);
        dimensions = doc.getTextDimensions('Zielgruppenabdeckung');
        doc.text(' Gibt an, wie viel % der Targeting-Menge im Buchungszeitraum den Banner mind. einmal gesehen haben.', dimensions.w * 1.5, y);

        y += 8;
        doc.setFont('helvetica', 'normal', 700);
        doc.text('Sichtbarkeit', 10, y);
        doc.setFont('helvetica', 'normal', 400);
        dimensions = doc.getTextDimensions('Sichtbarkeit');
        doc.text(' Gibt an, wie häufig die Nutzer der Zielgruppe das Medium innerhalb des Buchungszeitraum gesehen haben.', dimensions.w * 2, y);
    }

    /**
     * unsubcribe all subscriptions on destroy
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    selectVendor($event: any) {
        const vendor = $event;
        console.log('selecting vendor');
        console.log(vendor);

        this.adsService.getSupplierAds('', vendor).subscribe(ad => {
            this.adsForDate = ad.return_object;
            this.loadingAds = false;
        });

        this.marketingService.downloadList('slider').then(value => {
            this.sliders = value.filter(slide => slide.vendor === vendor);

            this.loadingSliders = false;
        });
    }

    selectingVendor(event) {
        var name = event.target.value;
        var selected = this.vendors.filter(option => option.name === name);

        if(selected.length === 1) {
            this.selectVendor(selected[0].id);
        }
    }

    getLocation(location: string) {
        return this.locations.filter(loc => loc.value === location)[0].label;
    }
}
