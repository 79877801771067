import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {Response} from '../../models/response.model';
import {map, takeUntil} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Subject, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DistributionCenterRestService extends RestServiceAbstract {
    protected ngUnsubscribe$: Subject<void> = new Subject<void>();

    public getDistributionCenters() {
        return this.get(`/usermanagement/distributionCenter/all`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                console.log(data);
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            }))
    }
}
