import { Component, Inject, Input, OnInit } from '@angular/core';
import {MarketingService} from '../../../../core/services/marketing.service';
import {MatomoService} from '../../../../core/services/matomo.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'phx-slider-report',
  templateUrl: './slider-report.component.html',
  styleUrls: ['./slider-report.component.scss',
    '../supplier-report.component.scss']
})
export class SliderReportComponent implements OnInit {
  @Input() slide;
  days = 0;
  slides;
  compares;
  click;

  constructor(private marketingService: MarketingService,
              private matomoService: MatomoService,
              @Inject('CDN') public CDN) { }

  ngOnInit(): void {
    this.days = this.matomoService.getDateRange(new Date(this.slide.start), new Date(this.slide.end));

    this.getData();
  }

  getData() {
    const startDate = new Date(this.slide.start);
    const startDateThree = new Date(startDate.setMonth(startDate.getMonth()-3));
    const endDate = new Date(this.slide.end);

    this.matomoService.searchForRange('Klick+auf+Startseite+Slider', startDateThree, endDate).subscribe(slides => {
      slides.subscribe(slidesDate => {
        this.slides = slidesDate;

        this.calculateClickPerformance();
      });
    });

    this.marketingService.downloadList('slider').then(value => {
      this.compares = value.filter(slide=> new Date(slide.start) >= startDateThree);
    });
  }

  calculateClickPerformance() {
    if (this.slides.length > 0 && this.compares) {
      const title = this.slide.title.replace('+', ' ').trim();
      const selected = this.slides.find(slide => slide.label.trim() === title);
      let compareReport = [];

      this.slides.forEach(slide => {
        if(slide.label !== this.slide.title && this.compares.find((compare: any) => compare.title.trim() === slide.label.trim()) !== undefined) {
          compareReport.push(slide);
        }
      });
      const selectedClicks = this.matomoService.calculateClicksPerDay(selected, this.days);

      let compareClicks = 0;
      let compareNr = 0;

      for(let compare of this.compares){
        let compareDays = 0;
        let title = compare.title.replace('+', ' ').trim()
        compareDays = this.matomoService.getDateRange(new Date(compare.start), new Date(compare.end));
        let comp = compareReport.find(comp => comp.label.trim() === title);

        if(comp) {
          compareClicks += this.matomoService.calculateClicksPerDay(comp, compareDays);
          compareNr++;
        }
      }

      this.click = +((selectedClicks / (compareClicks/compareNr)).toFixed(2));
    }
  }

  getDate(date) {
    return new Date(date);
  }

  getSlideId() {
    const id = CryptoJS.HmacSHA256(this.slide.title + this.slide.start + this.slide.end, 'slide') .toString(CryptoJS.enc.Base64);
    return id;
  }
}
