import {PreorderActions, PreorderActionTypes} from '../../actions/preorder/preorder.actions';
import {HttpErrorResponse} from '@angular/common/http';

export interface State {
    getAllPreordersLoading: boolean;
    getAllPreordersError: HttpErrorResponse;
    preorders: any[];
}

export const initialState: State = {
    getAllPreordersLoading: false,
    getAllPreordersError: null,
    preorders: []
};

export function reducer(state = initialState, action: PreorderActions): State {
    switch (action.type) {
        case PreorderActionTypes.INIT_LOAD_ALL_PREORDERS:
            return {
                ...state,
                getAllPreordersLoading: true,
                getAllPreordersError: null
            };
        case PreorderActionTypes.LOAD_ALL_PREORDERS_COMPLETE:
            return {
                ...state,
                getAllPreordersLoading: false,
                getAllPreordersError: null,
                preorders: action.payload
            };
        case PreorderActionTypes.LOAD_ALL_PREORDERS_FAILED:
            return {
                ...state,
                getAllPreordersLoading: false,
                getAllPreordersError: action.payload
            };
        default:
            return state;
    }
}

export const getPreorders = (state: State) => state.preorders;
export const getPreordersLoading = (state: State) => state.getAllPreordersLoading;
export const getPreordersError = (state: State) => state.getAllPreordersError;

