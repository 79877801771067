import {Action} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';


export enum BeraterUserActionTypes {
    GET_ALL_BERATER_USERS = '[BeraterUser] Get All Berater Users',
    GET_ALL_BERATER_USERS_COMPLETE = '[BeraterUser] Get All Berater Users complete',
    GET_ALL_BERATER_USERS_FAILED = '[BeraterUser] Get All Berater Users failed',
    ADD_BERATER_USER = '[BeraterUser] Add BeraterUser',
    ADD_BERATER_USER_COMPLETE = '[BeraterUser] Add BeraterUser complete',
    ADD_BERATER_USER_FAILED = '[BeraterUser] Add BeraterUser failed',
    INIT_UPDATE_BERATER_USER = '[BeraterUser] Init Update User',
    UPDATE_BERATER_USER_FAILED = '[BeraterUser] Update User failed',
    UPDATE_BERATER_USER_COMPLETE = '[BeraterUser] Update User complete',
    INIT_DELETE_BERATER_USER = '[BeraterUser] Init Delete User',
    DELETE_BERATER_USER_COMPLETE = '[BeraterUser] Delete User Complete',
    DELETE_BERATER_USER_FAILED = '[BeraterUser] Delete User failed',
    INIT_GET_PURCHASING_GROUPS = '[BeraterUser] Init GetPurchasing Groups',
    GET_PURCHASING_GROUPS_COMPLETE = 'BeraterUser] Get Purchasing Groups Complete',
    GET_PURCHASING_GROUPS_FAILED = '[BeraterUser] Get Purchasing Groups Complete Failed',
}

export class LoadBeraterUser implements Action {
    readonly type = BeraterUserActionTypes.GET_ALL_BERATER_USERS;

    constructor() {
    }
}

export class LoadBeraterUserComplete implements Action {
    readonly type = BeraterUserActionTypes.GET_ALL_BERATER_USERS_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadBeraterUserFailed implements Action {
    readonly type = BeraterUserActionTypes.GET_ALL_BERATER_USERS_FAILED;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class AddBeraterUser implements Action {
    readonly type = BeraterUserActionTypes.ADD_BERATER_USER;

    constructor() {
    }
}

export class AddBeraterUserComplete implements Action {
    readonly type = BeraterUserActionTypes.ADD_BERATER_USER_COMPLETE;

    constructor() {
    }
}

export class AddBeraterUserFailed implements Action {
    readonly type = BeraterUserActionTypes.ADD_BERATER_USER_FAILED;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class InitUpdateBeraterUser implements Action {
    readonly type = BeraterUserActionTypes.INIT_UPDATE_BERATER_USER;

    constructor() {
    }
}

export class UpdateBeraterUserComplete implements Action {
    readonly type = BeraterUserActionTypes.UPDATE_BERATER_USER_COMPLETE;

    constructor() {
    }
}

export class UpdateBeraterUserFailed implements Action {
    readonly type = BeraterUserActionTypes.UPDATE_BERATER_USER_FAILED;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class InitDeleteBeraterUser implements Action {
    readonly type = BeraterUserActionTypes.INIT_DELETE_BERATER_USER;

    constructor() {
    }
}

export class DeleteBeraterUserComplete implements Action {
    readonly type = BeraterUserActionTypes.DELETE_BERATER_USER_COMPLETE;

    constructor() {
    }
}

export class DeleteBeraterUserFailed implements Action {
    readonly type = BeraterUserActionTypes.DELETE_BERATER_USER_FAILED;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class LoadPurchasingGroups implements Action {
    readonly type = BeraterUserActionTypes.INIT_GET_PURCHASING_GROUPS;

    constructor() {
    }
}

export class LoadPurchasingGroupsComplete implements Action {
    readonly type = BeraterUserActionTypes.GET_PURCHASING_GROUPS_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadPurchasingGroupsFailed implements Action {
    readonly type = BeraterUserActionTypes.GET_PURCHASING_GROUPS_FAILED;

    constructor(public payload: HttpErrorResponse) {
    }
}

export type BeraterUserActions =
    LoadBeraterUser |
    LoadBeraterUserComplete |
    LoadBeraterUserFailed |
    AddBeraterUser |
    AddBeraterUserFailed |
    AddBeraterUserComplete |
    InitUpdateBeraterUser |
    UpdateBeraterUserComplete |
    UpdateBeraterUserFailed |
    InitDeleteBeraterUser |
    DeleteBeraterUserComplete |
    DeleteBeraterUserFailed |
    LoadPurchasingGroups |
    LoadPurchasingGroupsComplete |
    LoadPurchasingGroupsFailed;
