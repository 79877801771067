import {Action} from '@ngrx/store';

export enum VendorsActionTypes {
    INIT_LOAD_ALL_VENDORS = '[Vendors] Load all Vendors',
    LOAD_ALL_VENDORS_COMPLETE = '[Vendors] Load all Vendors Complete',
    LOAD_ALL_VENDORS_FAILED = '[Vendors] Load all Vendors Failed',
    INIT_ADD_VENDOR = '[Vendors] Add Vendor',
    ADD_VENDOR_COMPLETE = '[Vendors] Add Vendor Complete',
    ADD_VENDOR_FAILED = '[Vendors] Add Vendor Failed',
    INIT_UPDATE_VENDOR = '[Vendors] Init Update Vendor',
    UPDATE_VENDOR_COMPLETE = '[Vendors] Update Vendor Complete',
    UPDATE_VENDOR_FAILED = '[Vendors] Update Vendor Failed'
}

export class InitLoadAllVendors implements Action {
    readonly type = VendorsActionTypes.INIT_LOAD_ALL_VENDORS;
}

export class LoadAllVendorsComplete implements Action {
    readonly type = VendorsActionTypes.LOAD_ALL_VENDORS_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadAllVendorsFailed implements Action {
    readonly type = VendorsActionTypes.LOAD_ALL_VENDORS_FAILED;

    constructor(public payload: any) {
    }
}

export class InitAddVendor implements Action {
    readonly type = VendorsActionTypes.INIT_ADD_VENDOR;
}

export class AddVendorComplete implements Action {
    readonly type = VendorsActionTypes.ADD_VENDOR_COMPLETE;
}

export class AddVendorFailed implements Action {
    readonly type = VendorsActionTypes.ADD_VENDOR_FAILED;

    constructor(public payload: any) {
    }
}

export class InitUpdateVendor implements Action {
    readonly type = VendorsActionTypes.INIT_UPDATE_VENDOR;
}

export class UpdateVendorComplete implements Action {
    readonly type = VendorsActionTypes.UPDATE_VENDOR_COMPLETE;
}

export class UpdateVendorFailed implements Action {
    readonly type = VendorsActionTypes.UPDATE_VENDOR_FAILED;

    constructor(public payload: any) {
    }
}

export type VendorsActions =
    InitLoadAllVendors |
    LoadAllVendorsComplete |
    LoadAllVendorsFailed |
    InitAddVendor |
    AddVendorComplete |
    AddVendorFailed |
    InitUpdateVendor |
    UpdateVendorComplete |
    UpdateVendorFailed;
