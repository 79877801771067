import {Component, Inject, OnInit} from '@angular/core';
import {AuthService} from '../../core/services/api/auth.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-quick-nav',
    templateUrl: './quick-nav.component.html',
    styleUrls: ['./quick-nav.component.scss']
})
export class QuickNavComponent implements OnInit {

    constructor(@Inject(AuthService) public authService: AuthService,
                private router: Router) {
    }

    ngOnInit() {
    }

    logout() {
        this.authService.logout();
        this.router.navigateByUrl('logout');
    }

}
