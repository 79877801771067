import {Action} from '@ngrx/store';
import {HttpErrorResponse} from "@angular/common/http";

export enum CmsActionsTypes {
    LOAD_FORMS = '[CMS] Load Forms',
    LOAD_FORMS_COMPLETE = '[CMS] Load Forms Complete',
    LOAD_FORMS_FAIL = '[CMS] Load Forms Fail',
    LOAD_FORM = '[CMS] Load Form',
    LOAD_FORM_COMPLETE = '[CMS] Load Form Complete',
    LOAD_FORM_FAIL = '[CMS] Load Form Fail',
    LOAD_PAGES = '[CMS] Load Pages',
    LOAD_PAGES_COMPLETE = '[CMS] Load Pages Complete',
    LOAD_PAGES_FAIL = '[CMS] Load Pages Fail',
    LOAD_PAGE = '[CMS] Load Page',
    LOAD_PAGE_COMPLETE = '[CMS] Load Page Complete',
    LOAD_PAGE_FAIL = '[CMS] Load Page Fail'
}


export class LoadFormsAction implements Action {
    readonly type = CmsActionsTypes.LOAD_FORMS;

    constructor() {
    }
}

export class LoadFormsCompleteAction implements Action {
    readonly type = CmsActionsTypes.LOAD_FORMS_COMPLETE;

    constructor(public payload: any) {
        console.log(payload)
    }
}

export class LoadFormsFailAction implements Action {
    readonly type = CmsActionsTypes.LOAD_FORMS_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class LoadFormAction implements Action {
    readonly type = CmsActionsTypes.LOAD_FORM;

    constructor(public payload: any) {
    }
}

export class LoadFormCompleteAction implements Action {
    readonly type = CmsActionsTypes.LOAD_FORM_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadFormFailAction implements Action {
    readonly type = CmsActionsTypes.LOAD_FORM_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class LoadPagesAction implements Action {
    readonly type = CmsActionsTypes.LOAD_PAGES;

    constructor(public payload: any) {
    }
}

export class LoadPagesCompleteAction implements Action {
    readonly type = CmsActionsTypes.LOAD_PAGES_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadPagesFailAction implements Action {
    readonly type = CmsActionsTypes.LOAD_PAGES_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class LoadPageAction implements Action {
    readonly type = CmsActionsTypes.LOAD_PAGE;

    constructor(public payload: any) {
    }
}

export class LoadPageCompleteAction implements Action {
    readonly type = CmsActionsTypes.LOAD_PAGE_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadPageFailAction implements Action {
    readonly type = CmsActionsTypes.LOAD_PAGE_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export type CmsActions =
    LoadFormAction | LoadFormCompleteAction | LoadFormFailAction |
    LoadFormsAction | LoadFormsCompleteAction | LoadFormsFailAction |
    LoadPagesAction | LoadPagesCompleteAction | LoadPagesFailAction |
    LoadPageAction | LoadPageCompleteAction | LoadPageFailAction;