import * as fromUserActions from '../actions/user.actions';
import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {AuthService} from '../../services/api/auth.service';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs';
import {UserRestService} from '../../services/api/user.rest-service';
import {UserResponse} from '../../models/user-response.model';

@Injectable()
export class UserEffects {
    @Effect()
    loadUser$: Observable<Action> = this.actions$.pipe(
        ofType<fromUserActions.LoadUserAction>(fromUserActions.UserActionTypes.LOAD_USER))
        .pipe(map(action => action), switchMap(() =>
                this.authService.getUserResponseFromGateway().pipe(
                    map(data => new fromUserActions.LoadUserCompleteAction(new UserResponse(data.returnObject))),
                    catchError((error) => of(new fromUserActions.LoadUserFailAction(error)))
                )
            )
        );

    @Effect()
    loadUsers$: Observable<Action> = this.actions$.pipe(
        ofType<fromUserActions.LoadUsers>(fromUserActions.UserActionTypes.LoadUsers))
        .pipe(map(action => action.payload), switchMap((rq) =>
                this.userRestService.loadUsers(rq).pipe(
                    map(data => new fromUserActions.LoadUsersComplete(data.returnObject)),
                    catchError((error) => of(new fromUserActions.LoadUsersFailed(error)))
                )
            )
        );

    @Effect()
    loadAuthTree$: Observable<Action> = this.actions$
        .pipe(ofType<fromUserActions.InitAuthTree>(fromUserActions.UserActionTypes.INIT_AUTH_TREE))
        .pipe(map(action => action), switchMap(() =>
            this.userRestService.requestAuthTree().pipe(
                switchMap(data => [
                    new fromUserActions.InitAuthTreeComplete(data.returnObject),
                ]),
                catchError((error) => of(new fromUserActions.InitAuthTreeFailed(error)))
            )
        )
    );

    constructor(
        private authService: AuthService,
        private userRestService: UserRestService,
        private actions$: Actions
    ) {}
}
