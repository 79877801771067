import {User} from "./app-user.model";

export class UserResponse {
    name: string;
    groups: string[];
    user: User;

    constructor(obj) {
        this.name = obj && obj.name || null;
        this.groups = obj && obj.groups || [];

        //TODO: map actual personal data
        this.user = obj && new User(obj) || null;
    }
}
