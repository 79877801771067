export class MatomoRequestModel {
    method;
    id_site;
    period;
    date;
    id_subtable;
    label;
    segment;
    secondary_dimension;
    filter_pattern;

    constructor(obj: any = {}) {
        this.method = obj && obj.method || null;
        this.id_site = obj && obj.id_site || null;
        this.id_subtable = obj && obj.id_subtable || null;
        this.period = obj && obj.period || null;
        this.date = obj && obj.date || null;
        this.segment = obj && obj.segment || null;
        this.secondary_dimension = obj && obj.secondary_dimension || null;
        this.filter_pattern = obj && obj.filter_pattern || null;
    }
}