import * as fromAccountActions from '../actions/account.actions';
import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs';
import {AccountRestService} from '../../services/api/account.rest-service';

@Injectable()
export class AccountEffects {

    constructor(
        private accountRestService: AccountRestService,
        private actions$: Actions
    ) {
    }

    @Effect()
    loadAccounts$: Observable<Action> = this.actions$.pipe(
        ofType<fromAccountActions.InitLoadAllAccounts>(fromAccountActions.AccountActionTypes.INIT_LOAD_ALL_ACCOUNTS))
        .pipe(map(action => action), switchMap((rq) =>
                this.accountRestService.loadAllAccounts(rq.payload).pipe(
                    map(data => new fromAccountActions.LoadAllAccountsComplete(data.returnObject)),
                    catchError((error) => of(new fromAccountActions.LoadAllAccountsFailed(error)))
                )
            )
        );
}
