export class AccountsPaginationRequestModel {
    size: number;
    page: number;
    sort: String;
    searchString: string;

    constructor(obj: any = {}) {
        this.size = obj && obj.pageSize || 25;
        this.page = obj && obj.pageIndex || 0;
        this.sort = obj && obj.sort || 'idf,asc';
        this.searchString = obj && obj.searchString || '';
    }
}
