import {Injectable, OnDestroy} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {FormsRestService} from '../api/cms/forms.rest-service';
import * as fromReducers from '../../store/reducers';
import {LoadFormAction, LoadFormsAction} from '../../store/actions/cms.actions';

@Injectable({
    providedIn: 'root',
})
export class FormsService implements OnDestroy {
    private forms$: Observable<any> = null;
    protected ngUnsubscribe$: Subject<void> = new Subject<void>();
    private formsList = new Map<any, any>();


    constructor(private formsRestService: FormsRestService,
                public store: Store<fromReducers.State>) {
        this.loadForms();
    }

    getForms() {
        if (!this.forms$) {
            this.loadForms();
            return this.forms$;
        }
        return this.forms$;
    }

    loadForms() {
        console.log('loading forms');
        this.store.dispatch(new LoadFormsAction());
        this.forms$ = this.store.pipe(select(fromReducers.getForms));
    }

    getLoadingFormsObservable() {
        return this.store.pipe(select(fromReducers.getFormsLoading));
    }

    getForm(id) {
        this.store.dispatch(new LoadFormAction(id));
        return this.store.pipe(select(fromReducers.getForm));
    }

    getLoadingFormObservable() {
        return this.store.pipe(select(fromReducers.getFormLoading));
    }

    saveForm(data) {
        if (data.id) {
            return this.formsRestService.updateForm(data).pipe(takeUntil(this.ngUnsubscribe$), map(resp => resp));
        }
        return this.formsRestService.createForm(data).pipe(takeUntil(this.ngUnsubscribe$),map(resp => resp));
    }

    deleteForm(form) {
        return this.formsRestService.deleteForm(form).pipe(takeUntil(this.ngUnsubscribe$), map(resp => resp));
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe$.next();
        // This completes the subject properly.
        this.ngUnsubscribe$.complete();
    }

    deleteField(field) {
        return this.formsRestService.deleteField(field);
    }
}