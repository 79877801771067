import * as fromReducers from '../store/reducers';
import {Directive, EventEmitter, Injectable, OnDestroy, Output} from '@angular/core';
import {User} from '../models/user.model';
import {Nav} from '../models/nav.model';
import {select, Store} from '@ngrx/store';
import {UserResponse} from '../models/user-response.model';
import {takeUntil} from 'rxjs/operators';
import {UserRestService} from './api/user.rest-service';
import {Observable, Subject} from 'rxjs';
import {LogService} from './utility/log.service';
import {
    AddUser,
    InitAuthTree,
    LoadUsers,
    RemoveUser,
    UpdateUser,
    UserLogoutAction
} from '../store/actions/user.actions';
import {PaginationModel} from '../models/pagination.model';

@Directive()
@Injectable({
    providedIn: 'root',
})
export class UserService implements OnDestroy {
    @Output() add = new EventEmitter<User>();
    @Output() update = new EventEmitter<User>();
    @Output() remove = new EventEmitter<number>();
    private user$: Observable<UserResponse>;
    private userResponse: UserResponse;
    private ngUnsubscribe$ = new Subject<any>();
    private currentUsersRequest: PaginationModel = new PaginationModel();
    private nav: Nav;
    private users$: Observable<User[]>;

    /**
     * @param {Store<State>} store
     * @param userRestService
     */
    constructor(public store: Store<fromReducers.State>,
                public userRestService: UserRestService,
                public log: LogService) {
        this.nav = new Nav();
        this.add.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((value) => {
            const addUserAction: AddUser = new AddUser(value);
            this.store.dispatch(addUserAction);
        });
        this.update.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((value) => {
            const updateUserAction: UpdateUser = new UpdateUser(value);
            this.store.dispatch(updateUserAction);
        });
        this.remove.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((id) => {
            const removeUserAction: RemoveUser = new RemoveUser(id);
            this.store.dispatch(removeUserAction);
        });
    }

    loadUserAuthorities() {
        return this.store.pipe(select(fromReducers.getUserAuthTree));
    }

    getUserAuthorities() {
        this.store.dispatch(new InitAuthTree());
    }

    hasRequiredAuthority(authorities: string[]): boolean {
        if (!authorities) {
            return false;
        }
        const userFromStore = this.getUserResponseFromStore();
        if (!userFromStore) {
            return false;
        }
        const user = userFromStore.user;
        if (!user) {
            return false;
        }
        return authorities.filter(value => -1 !== user.authoritiesEnum.indexOf(value)).length > 0;
    }
    /**
     * Get UserResponse from store.
     *
     * @returns {UserResponse}
     */
    public getUserResponseFromStore(): UserResponse {
        this.user$ = this.store.pipe(select(fromReducers.getUserResponse));
        this.user$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(ur => this.userResponse = ur);
        return this.userResponse;
    }

    public loadUser(): Observable<UserResponse> {
        return this.store.pipe(select(fromReducers.getUserResponse));
    }

    /**
     * Get User from store.
     *
     * @returns {User}
     */
    public getUser(): User {
        if (this.getUserResponseFromStore()) {
            return this.getUserResponseFromStore().user;
        } else {
            return null;
        }
    }

    /**
     * Get Navigation mock model for now.
     *
     * @returns {Nav}
     */
    getNav(): Nav {
        //TODO: retrieve nav from service?
        return this.nav;
        /*
        return this.http.get(`${this.AUTH_URL}/getUserNav`).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(data => {
         return data;
        });
        */
    }

    loadUsers() {
        const loadUsersAction: LoadUsers = new LoadUsers(this.currentUsersRequest);
        this.store.dispatch(loadUsersAction);
        return this.getUsersObservable();
    }

    searchUsers(pageable: PaginationModel) {
        this.currentUsersRequest = pageable;
        this.loadUsers();
    }

    getUsersObservable(): Observable<User[]> {
        this.users$ = this.store.pipe(select(fromReducers.getUsers));
        return this.users$;
    }

    getUsersResponseObservable(): Observable<User[]> {
        this.users$ = this.store.pipe(select(fromReducers.getUsersResponse));
        return this.users$;
    }

    getUsersRequestObservable(): Observable<PaginationModel> {
        return this.store.pipe(select(fromReducers.getUsersRequest));
    }

    getUsersLoadingObservable(): Observable<boolean> {
        return this.store.pipe(select(fromReducers.getUsersLoading));
    }

    page(pageable: PaginationModel) {
        this.currentUsersRequest = pageable;
        this.loadUsers();
    }

    /**
     * Trigger User creation.
     * @param request
     */
    addUser(request): Observable<any> {
        return this.userRestService.createUser(request);
    }

    /**
     * Update User.
     * @param request
     */
    updateUser(request): Observable<any> {
        return this.userRestService.updateUser(request);
    }

    sendActivationMail(request): Observable<any> {
        return this.userRestService.sendActivationMail(request);
    }

    /**
     * Delete User.
     * @param request
     */
    removeUser(request: number): Observable<any> {
        return this.userRestService.deleteUser(request);
    }

    exportUsers(pageable: PaginationModel): Observable<any> {
        return this.userRestService.loadUsers(pageable);
    }

    logout() {
        this.store.dispatch(new UserLogoutAction());
    }

    /**
     * Unscribe from all scriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}

