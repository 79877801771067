import {CategoryActions, CategoryActionTypes} from '../../actions/preorder/category.actions';
import {HttpErrorResponse} from '@angular/common/http';

export interface State {
    categories: any[];
    getAllCategoriesLoading: boolean;
    getAllCategoriesError: HttpErrorResponse;
    addCategoryLoading: boolean;
    addCategoryError: HttpErrorResponse;
    selectedCategory: any;
    getCategoryLoading: boolean;
    getCategoryError: HttpErrorResponse;
    editCategoryLoading: boolean;
    editCategoryError: HttpErrorResponse;
}

export const initialState: State = {
    categories: [],
    getAllCategoriesLoading: false,
    getAllCategoriesError: null,
    addCategoryLoading: false,
    addCategoryError: null,
    selectedCategory: null,
    getCategoryLoading: false,
    getCategoryError: null,
    editCategoryLoading: false,
    editCategoryError: null
};

export function reducer(state = initialState, action: CategoryActions): State {
    switch (action.type) {
        case CategoryActionTypes.INIT_LOAD_ALL_CATEGORIES:
            return {
                ...state,
                getAllCategoriesLoading: true,
                getAllCategoriesError: null
            };
        case CategoryActionTypes.LOAD_ALL_CATEGORIES_COMPLETE:
            return {
                ...state,
                getAllCategoriesLoading: false,
                getAllCategoriesError: null,
                categories: action.payload
            };
        case CategoryActionTypes.LOAD_ALL_CATEGORIES_FAILED:
            return {
                ...state,
                getAllCategoriesLoading: false,
                getAllCategoriesError: action.payload
            };
        case CategoryActionTypes.INIT_ADD_CATEGORY:
            return {
                ...state,
                addCategoryLoading: true,
                addCategoryError: null
            };
        case CategoryActionTypes.ADD_CATEGORY_COMPLETE:
            return {
                ...state,
                addCategoryLoading: false,
                addCategoryError: null
            };
        case CategoryActionTypes.ADD_CATEGORY_FAILED:
            return {
                ...state,
                addCategoryLoading: false,
                addCategoryError: action.payload
            };
        case CategoryActionTypes.INIT_LOAD_CATEGORY:
            return {
                ...state,
                getCategoryLoading: true,
                getCategoryError: null
            };
        case CategoryActionTypes.LOAD_CATEGORY_COMPLETE:
            return {
                ...state,
                getCategoryLoading: false,
                getCategoryError: null,
                selectedCategory: action.payload
            };
        case CategoryActionTypes.LOAD_CATEGORY_FAILED:
            return {
                ...state,
                getCategoryLoading: false,
                getCategoryError: action.payload
            };
        case CategoryActionTypes.INIT_EDIT_CATEGORY:
            return {
                ...state,
                editCategoryLoading: true,
                editCategoryError: null
            };
        case CategoryActionTypes.EDIT_CATEGORY_COMPLETE:
            return {
                ...state,
                editCategoryLoading: false,
                editCategoryError: null,
            };
        case CategoryActionTypes.EDIT_CATEGORY_FAILED:
            return {
                ...state,
                editCategoryLoading: false,
                editCategoryError: action.payload
            };
        default:
            return state;
    }
}

export const getCategories = (state: State) => state.categories;
export const getCategoriesLoading = (state: State) => state.getAllCategoriesLoading;
export const getCategoriesError = (state: State) => state.getAllCategoriesError;
export const getAddCategoryLoading = (state: State) => state.addCategoryLoading;
export const getAddCategoryError = (state: State) => state.addCategoryError;
export const getSelectedCategory = (state: State) => state.selectedCategory;
export const getSelectedCategoryLoading = (state: State) => state.getCategoryLoading;
export const getSelectedCategoryError = (state: State) => state.getCategoryError;
export const getEditCategoryLoading = (state: State) => state.editCategoryLoading;
export const getEditCategoryError = (state: State) => state.editCategoryError;
