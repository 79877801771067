import * as fromReducers from '../store/reducers';
import {Injectable, OnDestroy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DelayInfoRestService} from './api/delay-info.rest-service';
import {LoadDelaysAction} from '../store/actions/delay-info.actions';

@Injectable({
    providedIn: 'root',
})
export class DelayInfoService {
    private ngUnsubscribe$ = new Subject<any>();

    constructor(public store: Store<fromReducers.State>,
                public delaysRestService: DelayInfoRestService) {
    }

    getAllDelaysObservable(): Observable<any[]> {
        return this.store.pipe(select(fromReducers.getDelays));
    }

    loadDelaysList(request){
        console.log('load Delays List')
        this.store.dispatch(new LoadDelaysAction(request));
        return this.getAllDelaysObservable();
    }

    getDelaysLoadingObservable(): Observable<boolean> {
        return this.store.pipe(select(fromReducers.getDelaysLoading));
    }

    getDelaysErrorObservable(): Observable<any> {
        return this.store.pipe(select(fromReducers.getDelaysError));
    }

    // /**
    //  * Unsubscribe from all subscriptions.
    //  */
    // ngOnDestroy(): void {
    //     this.ngUnsubscribe$.next();
    //     this.ngUnsubscribe$.complete();
    // }

    updateDelay(request: any) {
        return this.delaysRestService.updateDelay(request)
    }

    createDelay(request: any) {
        return this.delaysRestService.createDelay(request)
    }

    deleteDelay (request: any) {
        return this.delaysRestService.deleteDelay(request);
    }

    getTours(dc: any, date: string) {
        return this.delaysRestService.getToursOfTheDay(dc, date);
    }

    getTourIdfs(tours: any, date: string, dc: any) {
        return this.delaysRestService.getTourIdfs(dc, date, tours);
    }
}