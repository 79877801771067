import {Routes} from '@angular/router';
import {ErrorComponent} from "./view/error/error.component";
import {AuthGuardService as AuthGuard} from "./core/services/auth-guard.service";

export const ROUTES: Routes = [
    {
        path: '', redirectTo: 'public', pathMatch: 'full'
    },
    {
        path: 'public', loadChildren: () => import('./view-public/view-public.module').then(m => m.ViewPublicModule)
    },
    {
        path: 'app', loadChildren: () => import('./view/view.module').then(m => m.ViewModule), canActivate: [AuthGuard]
    },
    {
        path: 'error', component: ErrorComponent
    },
    {
        path: '**', component: ErrorComponent
    }
];
