import {map, takeUntil} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {Subject, Observable, throwError} from 'rxjs';
import {Response} from '../../models/response.model';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class VaccineRestService extends RestServiceAbstract {

    protected ngUnsubscribe$: Subject<void> = new Subject<void>();

    public addVaccine(rq) {
        return this.post(`/resource/vaccine`, rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public loadAllVaccines() {
        return this.get('/resource/vaccine').pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    // public remove(rq: any): Observable<Response> {
    //     return this.delete(`/resource/vaccine/${rq.id}`, rq).pipe(
    //         takeUntil(this.ngUnsubscribe$),
    //         map(data => {
    //             return new Response(data);
    //         }),
    //         catchError((err) => {
    //             return throwError(err);
    //         })
    //     );
    // }

    public updateVaccine(id: any, rq: any): Observable<Response> {
        return this.put(`/resource/vaccine/${id}`, rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public getLinkedVaccineProducts(id: any): Observable<Response> {
        return this.get(`/resource/vaccine/${id}`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    getVaccineShopConfig() {
        return this.get('/resource/vaccine-shop').pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    setVaccineShopConfig(req) {
        return this.post('/resource/vaccine-shop',req).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    sendShipmentFile(req) {
        return this.post('/resource/vaccine/shipments/upload',req).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    getLatestUploads() {
        return this.get('/resource/vaccine/shipments/lastuploads').pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }
}
