<!-- spin if user is loading//-->
<div class="page-center" *ngIf="(userLoading$ | async)">
    <i class="fal fa-spinner-third fa-spin"></i>&nbsp;Einen kurzen Moment bitte. Ihre Benutzerdaten werden geladen.
</div>

<!-- load content if user loading has finished and user is authenticated //-->
<div id="app" *ngIf="!(userLoading$ | async) && authenticated">
<div id="view" class="d-flex flex-md-row flex-column">
<!--<div id="view" class="d-flex flex-row">-->
    <app-nav></app-nav>
    <div id="visible-view">
        <div id="main-content">
            <app-header></app-header>
            <app-quick-nav></app-quick-nav>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<!-- show login link if user loading has finished and user isn't authenticated //-->
<!--
<div class="page-center text-center" *ngIf="!(userLoading$ | async) && !authenticated">
    <div class="py-s">Sie werden zum Login weitergeleitet.</div>
    <a routerLink="/login">Hier geht es zurück zum Login →</a>
</div>
-->
