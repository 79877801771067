import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/api/auth.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    /**
     * @param {Injector} inj
     * @param router
     */
    constructor(private inj: Injector,
                public router: Router) {
    }

    /**
     * @param {HttpRequest<any>} req
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const auth = this.inj.get(AuthService);
        const token = auth.getToken();
        if (token) {
            const cloned = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + token)
            });

            return next.handle(cloned)
                .pipe(tap((event: HttpEvent<any>) => {
                    /*if (event instanceof HttpResponse) {
                      // do stuff with response if you want
                    }
                  }, (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                      if (err.status === 401) {
                        this.log.info('Unauthorized. Forward to login');
                        // Redirect to the login route
                        this.router.navigate(['login']);
                      }
                    }*/
                }));

        } else {
            return next.handle(req);
        }
    }
}
