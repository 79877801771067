import {Action} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {User} from '../../models/user.model';
import {PaginationModel} from '../../models/pagination.model';

export enum UserActionTypes {
    LOAD_USER = '[User] Load User',
    LOAD_USER_COMPLETE = '[User] Load User Complete',
    LOAD_USER_FAIL = '[User] Load User FAIL',
    LOGOUT = '[User] Logout',
    LoadUsers = '[User] Load Users',
    LoadUsersComplete = '[User] Load Users complete',
    LoadUsersFailed = '[User] Load Users failed',
    AddUser = '[User] Add User',
    UpdateUser = '[User] Update User',
    RemoveUser = '[User] Remove User',
    INIT_AUTH_TREE = '[User] Init User authorities tree',
    INIT_AUTH_TREE_COMPLETE = '[User] Init User authorities complete',
    INIT_AUTH_TREE_FAILED = '[User] Init User authorities failed'
}

export class LoadUserAction implements Action {
    readonly type = UserActionTypes.LOAD_USER;

    constructor() { // == Query
    }
}

export class LoadUserCompleteAction implements Action {
    readonly type = UserActionTypes.LOAD_USER_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadUserFailAction implements Action {
    readonly type = UserActionTypes.LOAD_USER_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class UserLogoutAction implements Action {
    readonly type = UserActionTypes.LOGOUT;

    constructor() { // == Query
    }
}

export class LoadUsers implements Action {
    readonly type = UserActionTypes.LoadUsers;

    constructor(public payload: PaginationModel) {
    }
}

export class LoadUsersComplete implements Action {
    readonly type = UserActionTypes.LoadUsersComplete;

    constructor(public payload: any) {
    }
}

export class LoadUsersFailed implements Action {
    readonly type = UserActionTypes.LoadUsersFailed;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class AddUser implements Action {
    readonly type = UserActionTypes.AddUser;

    constructor(public payload: User) {
    }
}

export class UpdateUser implements Action {
    readonly type = UserActionTypes.UpdateUser;

    constructor(public payload: User) {
    }
}

export class RemoveUser implements Action {
    readonly type = UserActionTypes.RemoveUser;

    constructor(public payload: number) {
    }
}

export class InitAuthTree implements Action {
    readonly type = UserActionTypes.INIT_AUTH_TREE;
}

export class InitAuthTreeComplete implements Action {
    readonly type = UserActionTypes.INIT_AUTH_TREE_COMPLETE;

    constructor(public payload: any) {
    }
}

export class InitAuthTreeFailed implements Action {
    readonly type = UserActionTypes.INIT_AUTH_TREE_FAILED;

    constructor(public payload: any) {
    }
}

export type UserActions =
    LoadUserAction |
    LoadUserCompleteAction |
    LoadUserFailAction |
    UserLogoutAction |
    LoadUsers |
    LoadUsersComplete |
    LoadUsersFailed |
    AddUser |
    UpdateUser |
    RemoveUser |
    InitAuthTree |
    InitAuthTreeComplete |
    InitAuthTreeFailed;
