import * as fromPreorderPeriodActions from '../../actions/preorder/period.actions';
import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs';
import {PreorderRestService} from '../../../services/api/preorder.rest-service';

@Injectable()
export class PreorderPeriodEffects {

    constructor(
        private preorderRestService: PreorderRestService,
        private actions$: Actions
    ) {
    }

    @Effect()
    loadVendorPeriodsByCategory$: Observable<Action> = this.actions$.pipe(
        ofType<fromPreorderPeriodActions.InitLoadVendorPeriodsByCategory>
        (fromPreorderPeriodActions.PeriodActionTypes.INIT_LOAD_VENDOR_PERIODS_BY_CATEGORY))
        .pipe(map(action => action.payload), switchMap((categoryUrl) =>
                this.preorderRestService.loadVendorPeriodsByCategory(categoryUrl).pipe(
                    map(data => new fromPreorderPeriodActions.LoadVendorPeriodsByCategoryComplete(data.returnObject)),
                    catchError((error) => of(new fromPreorderPeriodActions.LoadVendorPeriodsByCategoryFailed(error)))
                )
            )
        );
}
