import {Action} from '@ngrx/store';

export enum PreorderActionTypes {
    INIT_LOAD_ALL_PREORDERS = '[Preorder] Init Load all Preorders',
    LOAD_ALL_PREORDERS_COMPLETE = '[Preorder] Load all Preorders complete',
    LOAD_ALL_PREORDERS_FAILED = '[Preorder] Load all Preorders failed',
}

export class InitLoadAllPreorders implements Action {
    readonly type = PreorderActionTypes.INIT_LOAD_ALL_PREORDERS;

    constructor(public payload: any) {
    }
}

export class LoadAllPreordersComplete implements Action {
    readonly type = PreorderActionTypes.LOAD_ALL_PREORDERS_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadAllPreordersFailed implements Action {
    readonly type = PreorderActionTypes.LOAD_ALL_PREORDERS_FAILED;

    constructor(public payload: any) {
    }
}

export type PreorderActions =
    InitLoadAllPreorders |
    LoadAllPreordersComplete |
    LoadAllPreordersFailed;
