<h2>Report for advertorial {{ advertorial.title }}</h2>
<div class="col-4"></div>
<div class="col-8"><div class="-image-container"></div></div>
<div class="col-12">
    <div class="click w-100 mb-l">
        <div class="d-flex">
            <div class="vis-1">
                <div class="marker" [style.left]="click * 100 + '%'" *ngIf="click <= 1">
                    <div class="value d-flex flex-column text-center">
                        <small>{{ click }}</small>
                        <span class="fa fa-caret-down"></span>
                    </div>
                </div>
            </div>
            <div class="vis-2">
                <div class="marker" [style.left]="((click-1)/click) * 100 + '%'" *ngIf="click > 1">
                    <div class="value d-flex flex-column text-center">
                        <small>{{ click }}</small>
                        <span class="fa fa-caret-down"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex">
            <div class="red"></div>
            <div class="yellow"></div>
            <div class="green"></div>
        </div>
    </div>
</div>
