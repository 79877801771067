import {map, takeUntil} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {Subject, Observable} from 'rxjs';
import {Response} from '../../models/response.model';

@Injectable({
    providedIn: 'root',
})
export class UserRestService extends RestServiceAbstract {

    protected ngUnsubscribe$: Subject<void> = new Subject<void>();

    /**
     * Required by search form to request selectable branch numbers
     *
     * @returns {Observable<any>}
     */
    public getVZs(idf: string) {
        return this.get(`/usermanagement/distributionCenter?idf=${idf}`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    /**
     * Required by search form to request selectable IDFs
     *
     * @returns {Observable<any>}
     */
    public getIDFs() {
        return this.get(`/usermanagement/idf`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    getShippingAdresses() {
        return this.get(`/usermanagement/idf/shippingAddresses`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    getMainDistributionCentersAdress() {
        return this.get(`/usermanagement/distributionCenter/main`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    getAllDistributionCentersAdress() {
        return this.get(`/usermanagement/distributionCenter/all`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    getAllVisAuths() {
        return this.get(`/usermanagement/admin/user/visAuths`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    loadUsers(rq) {
        return this.get(`/usermanagement/admin/user/listInternalUsers`, {params: rq}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    deleteUser(rq) {
        return this.delete(`/usermanagement/admin/user/delete/${rq}`, {params: {userId: rq}}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    createUser(rq) {
        return this.post(`/usermanagement/admin/user/create`, rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    updateUser(rq) {
        return this.patch(`/usermanagement/admin/user/update/` + rq.id, rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    sendActivationMail(rq) {
        return this.post(`/usermanagement/admin/user/sendActivationMail/` + rq.id, rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    /**
     * Pharmacy user management
     **/

    updatePharmacyUser(rq) {
        return this.patch(`/usermanagement/admin/user/` + rq.id, rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    loadPharmacyUsers(rq) {
        return this.get(`/usermanagement/admin/user/search`, {params: rq}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    getPharmacyUserAuthorities(id: number): Observable<Response> {
        return this.get(`/usermanagement/admin/user/${id}/authorities`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map((response: Object) => {
                return new Response(response);
            })
        );
    }

    requestAuthTree(): Observable<Response> {
        return this.get(`/usermanagement/admin/user/authtree`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe$.next();
        // This completes the subject properlly.
        this.ngUnsubscribe$.complete();
    }
}