import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MessageComponent} from './message.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule, TranslateModule
    ],
    declarations: [MessageComponent],
    exports: [MessageComponent]
})
export class MessageModule {
}
