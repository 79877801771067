import {Action} from '@ngrx/store';
import {HttpErrorResponse} from "@angular/common/http";
import {
    AddSupplementComplete,
    AddSupplementFailed, EditSupplementComplete, EditSupplementFailed,
    InitAddSupplement, InitEditSupplement,
    InitLoadAllSupplements,
    LoadAllSupplementsComplete, LoadAllSupplementsFailed
} from './esare.actions';

export enum DelayInfoActionTypes {
    LOAD_DELAYS = '[DELAY INFO] Load Delays',
    LOAD_DELAYS_COMPLETE = '[DELAY INFO] Load Delays Complete',
    LOAD_DELAYS_FAIL = '[DELAY INFO] Load Delays Fail'
}


export class LoadDelaysAction implements Action {
    constructor(public payload: any) {
        
    }

    readonly type = DelayInfoActionTypes.LOAD_DELAYS;
}

export class LoadDelaysCompleteAction implements Action {
    readonly type = DelayInfoActionTypes.LOAD_DELAYS_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadDelaysFailAction implements Action {
    readonly type = DelayInfoActionTypes.LOAD_DELAYS_FAIL;

    constructor(public payload: any) {
    }
}

export type DelayInfoActions =
    LoadDelaysAction |
    LoadDelaysCompleteAction |
    LoadDelaysFailAction;