import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PromotionProductModel} from '../../../../core/models/promotion-product.model';

@Component({
    selector: 'phx-promotion-products-temp-list',
    templateUrl: './promotion-products-temp-list.component.html',
    styleUrls: ['./promotion-products-temp-list.component.scss']
})
export class PromotionProductsTempListComponent implements OnInit {
    @Input('newProducts') newProducts: PromotionProductModel[];
    @Output() removeTempProduct: EventEmitter<any> = new EventEmitter<any>();
    isNaN: Function = Number.isNaN;
    constructor() {
    }

    ngOnInit(): void {
    }

    remove(index) {
        this.removeTempProduct.emit(index);
    }
}
