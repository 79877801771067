import {BeraterUserActionTypes, BeraterUserActions} from '../actions/beraterUser.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {BeraterUser} from '../../models/berater-user.model';

export interface State {
    allUsersLoading: boolean;
    allUsersError: HttpErrorResponse;
    allUsers: BeraterUser[];
    createUserLoading: boolean;
    createUserError: HttpErrorResponse;
    updateUserLoading: boolean;
    updateUserError: HttpErrorResponse;
    deleteUserLoading: boolean;
    deleteUserError: HttpErrorResponse;
    purchasingGroupsLoading: boolean;
    purchasingGroupsError: HttpErrorResponse;
    purchasingGroups: number[];
}

export const initialState: State = {
    allUsersLoading: false,
    allUsersError: null,
    allUsers: [],
    createUserLoading: false,
    createUserError: null,
    updateUserLoading: false,
    updateUserError: null,
    deleteUserLoading: false,
    deleteUserError: null,
    purchasingGroupsLoading: false,
    purchasingGroupsError: null,
    purchasingGroups: []
};

export function reducer(state = initialState, action: BeraterUserActions): State {
    switch (action.type) {
        case BeraterUserActionTypes.GET_ALL_BERATER_USERS:
            return {
                ...state,
                allUsersLoading: true,
                allUsersError: null
            };
        case BeraterUserActionTypes.GET_ALL_BERATER_USERS_COMPLETE:
            return {
                ...state,
                allUsers: action.payload,
                allUsersLoading: false
            };
        case BeraterUserActionTypes.GET_ALL_BERATER_USERS_FAILED:
            return {
                ...state,
                allUsersError: action.payload,
                allUsersLoading: false
            };
        case BeraterUserActionTypes.ADD_BERATER_USER:
            return {
                ...state,
                createUserLoading: true,
                createUserError: null,
            };
        case BeraterUserActionTypes.ADD_BERATER_USER_COMPLETE:
            return {
                ...state,
                createUserLoading: false,
            };
        case BeraterUserActionTypes.ADD_BERATER_USER_FAILED:
            return {
                ...state,
                createUserError: action.payload,
                createUserLoading: false
            };
        case BeraterUserActionTypes.INIT_UPDATE_BERATER_USER:
            return {
                ...state,
                updateUserLoading: true,
                updateUserError: null,
            };
        case BeraterUserActionTypes.UPDATE_BERATER_USER_COMPLETE:
            return {
                ...state,
                updateUserLoading: false,
            };
        case BeraterUserActionTypes.UPDATE_BERATER_USER_FAILED:
            return {
                ...state,
                updateUserError: action.payload,
                updateUserLoading: false
            };
        case BeraterUserActionTypes.INIT_DELETE_BERATER_USER:
            return {
                ...state,
                deleteUserLoading: true,
                deleteUserError: null,
            };
        case BeraterUserActionTypes.DELETE_BERATER_USER_COMPLETE:
            return {
                ...state,
                deleteUserLoading: false,
            };
        case BeraterUserActionTypes.DELETE_BERATER_USER_FAILED:
            return {
                ...state,
                deleteUserError: action.payload,
                deleteUserLoading: false
            };
        case BeraterUserActionTypes.INIT_GET_PURCHASING_GROUPS:
            return {
                ...state,
                purchasingGroupsLoading: true,
                purchasingGroupsError: null
            };
        case BeraterUserActionTypes.GET_PURCHASING_GROUPS_COMPLETE:
            return {
                ...state,
                purchasingGroups: action.payload,
                purchasingGroupsLoading: false
            };
        case BeraterUserActionTypes.GET_PURCHASING_GROUPS_FAILED:
            return {
                ...state,
                purchasingGroupsError: action.payload,
                purchasingGroupsLoading: false
            };
        default:
            return state;
    }
}

export const getAllBeraterUser = (state: State) => state.allUsers;
export const getAllBeraterUserLoading = (state: State) => state.allUsersLoading;
export const getAllBeraterUserError = (state: State) => state.allUsersError;

export const getAddUserLoading = (state: State) => state.createUserLoading;
export const getAddUserError = (state: State) => state.createUserError;

export const getUpdateUserLoading = (state: State) => state.updateUserLoading;
export const getUpdateUserError = (state: State) => state.updateUserError;

export const getDeleteUserLoading = (state: State) => state.deleteUserLoading;
export const getDeleteUserError = (state: State) => state.deleteUserError;

export const getPurchasingGroups = (state: State) => state.purchasingGroups;
export const getPurchasingGroupsLoading = (state: State) => state.purchasingGroupsLoading;
export const getPurchasingGroupsError = (state: State) => state.purchasingGroupsError;