import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {Response} from '../../models/response.model';
import {Injectable, OnDestroy} from '@angular/core';

@Injectable({
    providedIn: 'root',
})

export class BlobRestService extends RestServiceAbstract implements OnDestroy {
    protected ngUnsubscribe$: Subject<void> = new Subject<void>();

    public getSasToken() {
        return this.get(`/azure/editor-token`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe$.next();
        // This completes the subject properlly.
        this.ngUnsubscribe$.complete();
    }
}
