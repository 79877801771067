import {VendorsActions, VendorsActionTypes} from '../actions/vendors.actions';
import {HttpErrorResponse} from '@angular/common/http';

export interface State {
    vendors: any[];
    allVendorsLoading: boolean;
    allVendorsLoadError: HttpErrorResponse;
    addVendorLoading: boolean;
    addVendorError: HttpErrorResponse;
    updateVendorLoading: boolean;
    updateVendorError: HttpErrorResponse;
}

export const initialState: State = {
    vendors: [],
    allVendorsLoading: false,
    allVendorsLoadError: null,
    addVendorLoading: false,
    addVendorError: null,
    updateVendorLoading: false,
    updateVendorError: null
};

export function reducer(state = initialState, action: VendorsActions): State {
    switch (action.type) {
        case VendorsActionTypes.INIT_LOAD_ALL_VENDORS:
            return {
                ...state,
                allVendorsLoading: true,
                allVendorsLoadError: null
            };
        case VendorsActionTypes.LOAD_ALL_VENDORS_COMPLETE:
            return {
                ...state,
                vendors: action.payload,
                allVendorsLoading: false,
                allVendorsLoadError: null
            };
        case VendorsActionTypes.LOAD_ALL_VENDORS_FAILED:
            return {
                ...state,
                allVendorsLoading: false,
                allVendorsLoadError: action.payload
            };
        case VendorsActionTypes.INIT_ADD_VENDOR:
            return {
                ...state,
                addVendorLoading: true,
                addVendorError: null
            };
        case VendorsActionTypes.ADD_VENDOR_COMPLETE:
            return {
                ...state,
                addVendorLoading: false,
                addVendorError: null
            };
        case VendorsActionTypes.ADD_VENDOR_FAILED:
            return {
                ...state,
                addVendorLoading: false,
                addVendorError: action.payload
            };
        case VendorsActionTypes.INIT_UPDATE_VENDOR:
            return {
                ...state,
                updateVendorLoading: true,
                updateVendorError: null
            };
        case VendorsActionTypes.UPDATE_VENDOR_COMPLETE:
            return {
                ...state,
                updateVendorLoading: false,
                updateVendorError: null
            };
        case VendorsActionTypes.UPDATE_VENDOR_FAILED:
            return {
                ...state,
                updateVendorLoading: false,
                updateVendorError: action.payload
            };
        default:
            return state;
    }
}

export const getAddVendorLoading = (state: State) => state.addVendorLoading;
export const getAddVendorError = (state: State) => state.addVendorError;
export const getUpdateVendorLoading = (state: State) => state.updateVendorLoading;
export const getUpdateVendorError = (state: State) => state.updateVendorError;
export const getVendors = (state: State) => state.vendors;
export const getAllVendorsLoading = (state: State) => state.allVendorsLoading;
export const getAllVendorsLoadError = (state: State) => state.allVendorsLoadError;
