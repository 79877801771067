export class PaginationModel {
    total: number;
    size: number;
    page: number;
    search: String;

    constructor(obj: any = {}) {
        this.total = obj && obj.total || 0;
        this.size = obj && obj.pageSize || 10;
        this.page = obj && obj.pageIndex || 0;
        this.search = obj && obj.search || '';
    }

    setSearch(obj: any = {}){
        this.search = obj && obj.search || '';
        return this;
    }
}
