import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {LoginComponent} from '../modules/authentication/login/login.component';
import {LogoutComponent} from '../modules/authentication/logout/logout.component';
import {MembershipRequestComponent} from '../modules/authentication/membership-request/membership-request.component';
import {PasswordForgottenComponent} from '../modules/authentication/password-forgotten/password-forgotten.component';
import {ViewPublicComponent} from './view-public.component';
import {ROUTES} from './view-public.routes';
import {MessageModule} from '../modules/shared/message/message.module';
import {SpinnerModule} from '../modules/shared/spinner/spinner.module';
import {NotificationPublicModule} from '../modules/shared/notification-public/notification-public.module';
import {NotificationModule} from '../modules/shared/notification/notification.module';
import {SuccessModule} from '../modules/shared/success/success.module';

@NgModule({
    imports: [
        CommonModule,
        ROUTES,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        NotificationPublicModule,
        MessageModule,
        SpinnerModule,
        NotificationModule,
        SuccessModule
    ],
    declarations: [
        ViewPublicComponent,
        LoginComponent,
        LogoutComponent,
        PasswordForgottenComponent,
        MembershipRequestComponent,
    ],
    exports: [
        ViewPublicComponent
    ]
})
export class ViewPublicModule {
}


