import {map, takeUntil} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {Subject, Observable, throwError} from 'rxjs';
import {Response} from '../../models/response.model';
import {catchError} from 'rxjs/operators';
import {AccountsPaginationRequestModel} from '../../models/accounts-pagination-request.model';
import {of} from 'rxjs/internal/observable/of';

@Injectable({
    providedIn: 'root',
})
export class AccountRestService extends RestServiceAbstract {
    protected ngUnsubscribe$: Subject<void> = new Subject<void>();


    public loadAllAccounts(rq: AccountsPaginationRequestModel): Observable<Response> {
        return this.get('/usermanagement/admin/accounts', {params: {page: rq.page, size: rq.size, sort: rq.sort, searchString: rq.searchString}}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public exportAccounts(rq: any): Observable<Response> {
        return this.get('/usermanagement/admin/accounts/export', {params: {searchString: rq.searchString}}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((error) => {
                return of({errorCode: error.status, returnObject: null});
            })
        );
    }
}
