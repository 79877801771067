<div class="error-container">
    <div class="error-pane">
        <h3>{{error?.status ? error.status : '404'}}</h3>
        <h1>{{error?.error?.error ? error.error.error : 'Seite nicht gefunden'}}</h1>
        <hr/>
        <h5>
            {{ error?.error?.message ? error?.error?.message : 'Leider konnten wir die von Ihnen gesuchte Seite nicht finden.' }}
        </h5>
        <p class="text-center" *ngIf="error?.status && error?.error?.error">
            Tragen sie zur Verbesserung des Portals bei: <a
                href="mailto:{{ADMIN_MAIL}}?subject=PHX Portal Fehler | {{ error.status +' '+ error.error.error }} &body={{ errorJsonForMailBody }}">Klicken Sie hier, um
            den Fehler zu melden.</a>
        </p>
    </div>
</div>