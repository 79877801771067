import {Component, ElementRef, Inject, OnDestroy, QueryList, ViewChildren} from '@angular/core';
import {Nav} from "../../core/models/nav.model";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {UserService} from "../../core/services/user.service";
import {User} from "../../core/models/user.model";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnDestroy {

    @ViewChildren("toggleArrow", {read: ElementRef}) nodes: QueryList<ElementRef>;
    public nav: Nav;
    public user: User;
    public active: string | boolean;
    public menuActive: boolean = false;
    private ngUnsubscribe$ = new Subject<any>();
    public showing;

    /**
     * @param router
     * @param route
     * @param userService
     */
    constructor(private router: Router, private route: ActivatedRoute,
                @Inject(UserService) private userService: UserService) {
        this.nav = this.userService.getNav();
        this.user = this.userService.getUser();

        router.events.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((val) => {
                if (val instanceof NavigationEnd) {
                    this.determineActive();
                }
            }
        );

    }

    determineInitShowing(routeSnapshot) {
        if (routeSnapshot.children.length > 0) {
            this.showing = this.nav
                && this.nav.getParent(routeSnapshot.children[0].routeConfig.path);
        }
    }

    determineActive(routeSnapshot = this.route.snapshot) {
        if (routeSnapshot.children.length > 0) {
            if (routeSnapshot.children[0].routeConfig.path === 'dashboard') {
                this.active = routeSnapshot.children[0].routeConfig.path;
                return;
            }
            const changeActive = this.nav
                && this.nav.getProperty(routeSnapshot.children[0].routeConfig.path, 'path');

            if (routeSnapshot.queryParamMap.has('heading') || !changeActive) {
                this.active = null;
            } else {
                this.active = changeActive;
            }
        }
    }

    toggleMenuActive() {
        this.menuActive = !this.menuActive;
    }

    menuInactive() {
        this.menuActive = false;
    }

    navigateToFragment() {
        this.router.navigate(['/dashboard'], {fragment: 'exklusivangebote'});
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
