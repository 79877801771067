import * as fromVaccineActions from '../actions/vaccine.actions';
import { Injectable} from '@angular/core';
import { Actions, Effect, ofType} from '@ngrx/effects';
import { Action} from '@ngrx/store';
import { catchError, map, switchMap} from 'rxjs/operators';
import { of} from 'rxjs/internal/observable/of';
import { Observable} from 'rxjs';
import { VaccineRestService } from '../../services/api/vaccine.rest-service';

@Injectable()
export class VaccineEffects {

    constructor(
        private vaccineRestService: VaccineRestService,
        private actions$: Actions
    ) {}

    @Effect()
    loadVaccines$: Observable<Action> = this.actions$.pipe(
        ofType<fromVaccineActions.InitLoadAllVaccines>(fromVaccineActions.VaccineActionTypes.INIT_LOAD_ALL_VACCINES))
        .pipe(switchMap(() =>
                this.vaccineRestService.loadAllVaccines().pipe(
                    map(data => new fromVaccineActions.LoadAllVaccinesComplete(data.returnObject)),
                    catchError((error) => of(new fromVaccineActions.LoadAllVaccinesFailed(error)))
                )
            )
        );
}
