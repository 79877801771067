<div class="message">
    <div class="row mb-xl">
        <div class="col-md-2 phx-cta my-auto text-center"><i class="fal {{icon}} fa-6x"></i></div>
        <div class="col-md-8 my-auto">
            <h1 *ngIf="id">{{id | translate}}</h1>
            <h1 *ngIf="!id && message">{{message}}</h1>
        </div>
        <div class="col-md-2"></div>
    </div>
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
            <div *ngIf="showLink && !logout">
                <a href="{{ link }}"><i class="fal fa-arrow-right"></i> {{linkText}}</a>
            </div>
            <div *ngIf="showLink && logout">
                <a href="{{ APP_URL }}" class="d-block mb-s"><i class="fal fa-arrow-right"></i> zu Apothekenportal</a>
                <a href="{{ ADMIN_URL }}" class="d-block mb-s"><i class="fal fa-arrow-right" ></i> zu AdminApp</a>
            </div>
        </div>
        <div class="col-md-2"></div>

    </div>
</div>
