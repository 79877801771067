import {Component, Inject} from '@angular/core';
import {User} from "../../core/models/user.model";
import {Nav} from "../../core/models/nav.model";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {UserService} from "../../core/services/user.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

    public user: User;
    public nav: Nav;
    public activeHeading: string | boolean;
    private ngUnsubscribe$ = new Subject<any>();

    constructor(private router: Router, private route: ActivatedRoute,
                @Inject(UserService) private userService: UserService) {
        this.nav = this.userService.getNav();
        this.user = this.userService.getUser();
        /**
         * The header needs to know which component is called, so that it can ask the nav.model for the name.
         */
        router.events.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((val) => {
            if (val instanceof NavigationEnd) {
                if (this.route.snapshot.children.length > 0) {
                    this.activeHeading = this.nav.getProperty(this.route.snapshot.children[0].routeConfig.path);
                }
            }
        });
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void  {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
