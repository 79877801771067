import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {MatomoRestService} from '../../services/api/matomo.rest-service';
import * as fromMatomoActions from '../actions/matomo.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Response} from '../../models/response.model';

@Injectable()
export class MatomoEffects {

    constructor(private matomoRestService: MatomoRestService,
                private actions$: Actions) {


    }

    loadCategories$: Observable<Action> = createEffect(() => {
        return this.actions$
            .pipe(ofType<fromMatomoActions.LoadCategoriesAction>(fromMatomoActions.MatomoActionTypes.LOAD_CATEGORIES))
            .pipe(map(action => action.payload),
                switchMap((action: any) => {
                        if (action) {
                            return this.matomoRestService.getCategoryForRange(action).pipe(
                                map((data: Response) => {
                                        console.log(data.returnObject);
                                        return new fromMatomoActions.LoadCategoriesCompleteAction(data.returnObject);
                                    }
                                ),
                                catchError((error) => of(new fromMatomoActions.LoadCategoriesFailAction(error)))
                            );
                        }
                    }
                )
            );
    });

    loadMatomoActions$: Observable<Action> = createEffect(() => {
        return this.actions$
            .pipe(ofType<fromMatomoActions.LoadActionsAction>(fromMatomoActions.MatomoActionTypes.LOAD_ACTIONS))
            .pipe(map(action => action.payload),
                switchMap((action: any) => {
                        if (action) {
                            return this.matomoRestService.getDataForRange(action).pipe(
                                map((data: Response) => {
                                        console.log(data.returnObject);
                                        return new fromMatomoActions.LoadActionsCompleteAction(data.returnObject);
                                    }
                                ),
                                catchError((error) => of(new fromMatomoActions.LoadActionsFailAction(error)))
                            );
                        }
                    }
                )
            );
    });

    loadImpressions$: Observable<Action> = createEffect(() => {
        return this.actions$
            .pipe(ofType<fromMatomoActions.LoadImpressionsAction>(fromMatomoActions.MatomoActionTypes.LOAD_IMPRESSIONS))
            .pipe(map(action => action.payload),
                switchMap((action: any) => {
                        if (action) {
                            return this.matomoRestService.getImpressionsForRange(action).pipe(
                                map((data: Response) => {
                                        console.log(data.returnObject);
                                        return new fromMatomoActions.LoadImpressionsCompleteAction(data.returnObject);
                                    }
                                ),
                                catchError((error) => of(new fromMatomoActions.LoadImpressionsFailAction(error)))
                            );
                        }
                    }
                )
            );
    });
}