import {Action} from "@ngrx/store";
import {HttpErrorResponse} from "@angular/common/http";

export enum MessageActionTypes {
    LOAD_MESSAGES = '[Message] Load Messages',
    LOAD_MESSAGES_COMPLETE = '[Message] Load Message Completes',
    LOAD_MESSAGE = '[Message] Load Message',
    LOAD_MESSAGE_COMPLETE = '[Message] Load Message Complete',
    MESSAGE_FAILED = '[Message]  Message operation failed',
    LOGOUT = '[Message] Logout'
}

export class LoadMessagesAction implements Action {
    readonly type = MessageActionTypes.LOAD_MESSAGES;

    constructor(public payload: any) {
    }
}

export class LoadMessagesCompleteAction implements Action {
    readonly type = MessageActionTypes.LOAD_MESSAGES_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadMessageAction implements Action {
    readonly type = MessageActionTypes.LOAD_MESSAGE;

    constructor(public payload: any) { // == Query
    }
}

export class ErrorAction implements Action {
    readonly type = MessageActionTypes.MESSAGE_FAILED;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class LoadMessageCompleteAction implements Action {
    readonly type = MessageActionTypes.LOAD_MESSAGE_COMPLETE;

    constructor(public payload: any) {
    }
}




export type MessageActions =
    LoadMessagesAction |
    LoadMessageAction |
    LoadMessagesCompleteAction |
    ErrorAction |
    LoadMessageCompleteAction;
