import {Injectable, OnDestroy} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {RestServiceAbstract} from '../../../abstracts/rest-service.abstract';
import {Response} from '../../../models/response.model';


@Injectable({
    providedIn: 'root'
})
export class MessageRestService extends RestServiceAbstract implements OnDestroy {

    protected ngUnsubscribe$: Subject<void> = new Subject<void>();

    public getMessages(rq) {
        return this.get(`/usermanagement/admin/messages/list`, {params: rq}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public getNumberOfRecipients(rq) {
        return this.get(`/usermanagement/admin/messages/numberOfRecipients`, {params: rq}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public fetchNumberOfRecipients(rq) {
        return this.post('/usermanagement/admin/messages/numberOfRecipients', rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public createMail(rq) {
        return this.post(`/usermanagement/admin/messages/save`, rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public loadMessage(rq) {
        return this.get(`/usermanagement/admin/messages/readMessage`, {params: {messageId: rq.payload}}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
