import {RouterModule, Routes} from '@angular/router';
import {ViewComponent} from './view.component';


const routes: Routes = [
    {
        path: '', component: ViewComponent, children: [
            {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
            {
                path: 'dashboard',
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'payback-produkte',
                loadChildren: () => import('../modules/payback/products/products.module').then(m => m.ProductsModule)
            },
            {
                path: 'nachricht-erstellen',
                loadChildren: () => import('../modules/messaging/create-message-form/create-message-form.module')
                    .then(m => m.CreateMessageFormModule)
            },
            {
                path: 'gesendete-nachrichten',
                loadChildren: () => import('../modules/messaging/message-list/message-list.module').then(m => m.MessageListModule)
            },
            {
                path: 'phoenix-mitarbeitern',
                loadChildren: () => import('../modules/user-management/user-management.module').then(m => m.UserManagementModule)
            },
            {
                path: 'apotheken-benutzer',
                loadChildren: () => import('../modules/pharmacy-user-management/pharmacy-user-management.module')
                    .then(m => m.PharmacyUserManagementModule)
            },
            {
                path: 'berater-benutzer',
                loadChildren: () => import('../modules/berater-user-management/berater-user-management.module').then(m => m.BeraterUserManagementModule)
            },
            {
                path: 'documents-config',
                loadChildren: () => import('../modules/documents/documents.module').then(m => m.DocumentsModule)
            },
            {
                path: 'rechnungs-beilagen',
                loadChildren: () => import('../modules/esare-supplements/esare-supplements.module').then(m => m.EsareSupplementsModule)
            },
            {
                path: 'synchronizer-overview',
                loadChildren: () => import('../modules/synchronizer/synchronizer.module').then(m => m.SynchronizerModule)
            },
            {
                path: 'promotions-overview',
                loadChildren: () => import('../modules/promotions/promotions.module').then(m => m.PromotionsModule)
            },
            {
                path: 'promotion-products',
                loadChildren: () => import('../modules/promotions/promotion-products/promotion-products.module').then(m => m.PromotionProductsModule)
            },
            {
                path: 'vendors-overview',
                loadChildren: () => import('../modules/vendors/vendors.module').then(m => m.VendorsModule)
            },
            {
                path: 'promotion-products-import',
                loadChildren: () => import('../modules/promotions/promotion-products/promotion-products-import/promotion-products-import.module').then(m => m.PromotionProductsImportModule)
            },
            {
                path: 'vaccine-management',
                loadChildren: () => import('../modules/vaccine-management/vaccine-management.module').then(m => m.VaccineManagementModule)
            },
            {
                path: 'vaccine-shop-config',
                loadChildren: () => import('../modules/vaccine-config/vaccine-config.module').then(m => m.VaccineConfigModule)
            },
            {
                path: 'vaccine-shipments-upload',
                loadChildren: () => import('../modules/vaccine-shipments-upload/vaccine-shipments-upload.module')
                    .then(m => m.VaccineShipmentsUploadModule)
            },
            {
                path: 'slider-verwalten',

                loadChildren: () =>
                    import('../modules/marketing-management/slider-management/slider-management.module').then(m => m.SliderManagementModule)
            },
            {
                path: 'ads-verwalten',
                loadChildren: () =>
                    import('../modules/marketing-management/ads-management/ads-management.module').then(m => m.AdsManagementModule)

      },
      {
        path: 'popup-verwalten',
        loadChildren: () =>
            import('../modules/marketing-management/popup-management/popup-management.module').then(m => m.PopupManagementModule)
      },
      {

                path: 'advertorial-verwalten',
                loadChildren: () =>
                    import('../modules/marketing-management/advertorial-management/advertorial-management.module').then(m => m.AdvertorialManagementModule)
            },
            {
                path: 'datev-verwalten',
                loadChildren: () =>
                    import('../modules/integrations-management/datev/datev.module').then(m => m.DatevModule)
            },
            {
                path: 'notifications',
                loadChildren: () =>
                    import('../modules/marketing-management/notices-management/notices-management.module').then(m => m.NoticesManagementModule)
      },
      {
        path: 'account-management',
        loadChildren: () => import('../modules/account-management/account-management.module')
              .then(m => m.AccountManagementModule)


            },
            {
                path: 'forms-management',
                loadChildren: () => import('../modules/cms-management/forms-management/forms-management.module').then(m => m.FormsManagementModule)
            },
            {
                path: 'files-management',
                loadChildren: () => import('../modules/cms-management/files-management/files-management.module').then(m => m.FilesManagementModule)
            },
            {
                path: 'pages-management',
                loadChildren: () => import('../modules/cms-management/pages-management/pages-management.module').then(m => m.PagesManagementModule)

            },
            {
                path: 'matomo-reports',
                loadChildren: () => import('../modules/matomo-reports/matomo-reports.module').then(m=>m.MatomoReportsModule)
            },
            {
                path: 'supplier-report',
                loadChildren: () => import('../modules/matomo-reports/supplier-report/supplier-report.module').then(m=>m.SupplierReportModule)
            },
            {
                path: 'delay-info',
                loadChildren: () => import('../modules/delay-info/delay-info.module').then(m => m.DelayInfoModule),
                data: {
                    heading: 'Lieferverzögerungen'
                }

            },
            {
                path: 'preorder-management',
                loadChildren: () => import('../modules/preorder-management/preorder-management.module').then(m => m.PreorderManagementModule)
            },
            {
                path: 'preorders-view',
                loadChildren: () => import('../modules/preorder-management/preorders-view/preorders-view.module').then(m => m.PreordersViewModule)
            },

        ]
    }
];
export const ROUTES = RouterModule.forChild(routes);
