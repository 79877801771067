import {PromotionsActions, PromotionsActionTypes} from '../actions/promotions.action';
import {HttpErrorResponse} from '@angular/common/http';

export interface State {
    promotions: any[];
    selectedPromotion: any;
    addPromotionLoading: boolean;
    addPromotionError: HttpErrorResponse;
    getAllPromotionsLoading: boolean;
    getAllPromotionsError: HttpErrorResponse;
    editPromotionLoading: boolean;
    editPromotionError: HttpErrorResponse;
    addPromotionLogoLoading: boolean;
    addPromotionLogoError: HttpErrorResponse;
    getPromotionLoading: boolean;
    getPromotionError: HttpErrorResponse;
    addIdfsToPromotionLoading: boolean;
    addIdfsToPromotionError: HttpErrorResponse;
    addProductsLoading: boolean;
    addProductsError: HttpErrorResponse;
    updateProductsLoading: boolean;
    updateProductsError: HttpErrorResponse;
    editableProduct: any;
    deletePromotionLoading: boolean;
    deletePromotionError: HttpErrorResponse;
    restorePromotionLoading: boolean;
    restorePromotionError: HttpErrorResponse;
    duplicatePromotionLoading: boolean;
    duplicatePromotionError: HttpErrorResponse;
}

export const initialState: State = {
    promotions: [],
    selectedPromotion: null,
    addPromotionLoading: false,
    addPromotionError: null,
    getAllPromotionsLoading: false,
    getAllPromotionsError: null,
    editPromotionLoading: false,
    editPromotionError: null,
    addPromotionLogoLoading: false,
    addPromotionLogoError: null,
    getPromotionLoading: false,
    getPromotionError: null,
    addIdfsToPromotionLoading: false,
    addIdfsToPromotionError: null,
    addProductsLoading: false,
    addProductsError: null,
    updateProductsLoading: false,
    updateProductsError: null,
    editableProduct: null,
    deletePromotionLoading: false,
    deletePromotionError: null,
    restorePromotionLoading: false,
    restorePromotionError: null,
    duplicatePromotionLoading: false,
    duplicatePromotionError: null
};

export function reducer(state = initialState, action: PromotionsActions): State {
    switch (action.type) {
        case PromotionsActionTypes.INIT_ADD_PROMOTION:
            return {
                ...state,
                addPromotionLoading: true,
                addPromotionError: null
            };
        case PromotionsActionTypes.ADD_PROMOTION_COMPLETE:
            return {
                ...state,
                addPromotionLoading: false,
                addPromotionError: null
            };
        case PromotionsActionTypes.ADD_PROMOTION_FAILED:
            return {
                ...state,
                addPromotionLoading: false,
                addPromotionError: action.payload
            };
        case PromotionsActionTypes.INIT_LOAD_ALL_PROMOTIONS:
            return {
                ...state,
                getAllPromotionsLoading: true,
                getAllPromotionsError: null
            };
        case PromotionsActionTypes.LOAD_ALL_PROMOTIONS_COMPLETE:
            return {
                ...state,
                getAllPromotionsLoading: false,
                getAllPromotionsError: null,
                promotions: action.payload
            };
        case PromotionsActionTypes.LOAD_ALL_PROMOTIONS_FAILED:
            return {
                ...state,
                getAllPromotionsLoading: false,
                getAllPromotionsError: action.payload,
            };
        case PromotionsActionTypes.INIT_EDIT_PROMOTION:
            return {
                ...state,
                editPromotionLoading: true,
                editPromotionError: null
            };
        case PromotionsActionTypes.EDIT_PROMOTION_COMPLETE:
            return {
                ...state,
                editPromotionLoading: false,
                editPromotionError: null
            };
        case PromotionsActionTypes.EDIT_PROMOTION_FAILED:
            return {
                ...state,
                editPromotionLoading: false,
                editPromotionError: action.payload
            };
        case PromotionsActionTypes.INIT_ADD_PROMOTION_LOGO:
            return {
                ...state,
                addPromotionLogoLoading: true,
                addPromotionLogoError: null
            };
        case PromotionsActionTypes.ADD_PROMOTION_LOGO_COMPLETE:
            return {
                ...state,
                addPromotionLogoLoading: false,
                addPromotionLogoError: null
            };
        case PromotionsActionTypes.ADD_PROMOTION_LOGO_FAILED:
            return {
                ...state,
                addPromotionLogoLoading: false,
                addPromotionLogoError: action.payload
            };
        case PromotionsActionTypes.INIT_GET_PROMOTION:
            return {
                ...state,
                getPromotionLoading: true,
                getPromotionError: null
            };
        case PromotionsActionTypes.GET_PROMOTION_COMPLETE:
            return {
                ...state,
                getPromotionLoading: false,
                getPromotionError: null,
                selectedPromotion: action.payload
            };
        case PromotionsActionTypes.GET_PROMOTION_FAILED:
            return {
                ...state,
                getPromotionLoading: false,
                getPromotionError: action.payload,
            };
        case PromotionsActionTypes.INIT_SET_IDFS_TO_PROMOTION:
            return {
                ...state,
                addIdfsToPromotionLoading: true,
                addIdfsToPromotionError: null
            };
        case PromotionsActionTypes.SET_IDFS_TO_PROMOTION_COMPLETE:
            return {
                ...state,
                addIdfsToPromotionLoading: false,
                addIdfsToPromotionError: null
            };
        case PromotionsActionTypes.SET_IDFS_TO_PROMOTION_FAILED:
            return {
                ...state,
                addIdfsToPromotionLoading: false,
                addIdfsToPromotionError: action.payload,
            };
        case PromotionsActionTypes.INIT_ADD_PRODUCTS:
            return {
                ...state,
                addProductsLoading: true,
                addProductsError: null
            };
        case PromotionsActionTypes.ADD_PRODUCTS_COMPLETE:
            return {
                ...state,
                addProductsLoading: false,
                addProductsError: null
            };
        case PromotionsActionTypes.ADD_PRODUCTS_FAILED:
            return {
                ...state,
                addProductsLoading: false,
                addProductsError: action.payload
            };
        case PromotionsActionTypes.INIT_UPDATE_PRODUCTS:
            return {
                ...state,
                editableProduct: action.payload,
                updateProductsLoading: true,
                updateProductsError: null
            };
        case PromotionsActionTypes.UPDATE_PRODUCTS_COMPLETE:
            return {
                ...state,
                editableProduct: null,
                updateProductsLoading: false,
                updateProductsError: null
            };
        case PromotionsActionTypes.UPDATE_PRODUCTS_FAILED:
            return {
                ...state,
                editableProduct: null,
                updateProductsLoading: false,
                updateProductsError: action.payload
            };
        case PromotionsActionTypes.RESET_EDITABLE_PRODUCT:
            return {
                ...state,
                editableProduct: null
            };
        case PromotionsActionTypes.INIT_DELETE_PROMOTION:
            return {
                ...state,
                deletePromotionLoading: true,
                deletePromotionError: null
            };
        case PromotionsActionTypes.DELETE_PROMOTION_COMPLETE:
            return {
                ...state,
                deletePromotionLoading: false,
                deletePromotionError: null
            };
        case PromotionsActionTypes.DELETE_PROMOTION_FAILED:
            return {
                ...state,
                deletePromotionLoading: false,
                deletePromotionError: action.payload
            };
        case PromotionsActionTypes.INIT_RESTORE_PROMOTION:
            return {
                ...state,
                restorePromotionLoading: true,
                restorePromotionError: null
            };
        case PromotionsActionTypes.RESTORE_PROMOTION_COMPLETE:
            return {
                ...state,
                restorePromotionLoading: false,
                restorePromotionError: null
            };
        case PromotionsActionTypes.RESTORE_PROMOTION_FAILED:
            return {
                ...state,
                restorePromotionLoading: false,
                restorePromotionError: action.payload
            };
        case PromotionsActionTypes.INIT_DUPLICATE_PROMOTION:
            return {
                ...state,
                duplicatePromotionLoading: true,
                duplicatePromotionError: null
            };
        case PromotionsActionTypes.DUPLICATE_PROMOTION_COMPLETE:
            return {
                ...state,
                duplicatePromotionLoading: false,
                duplicatePromotionError: null
            };
        case PromotionsActionTypes.DUPLICATE_PROMOTION_FAILED:
            return {
                ...state,
                duplicatePromotionLoading: false,
                duplicatePromotionError: action.payload
            };
        default:
            return state;
    }
}

export const getAddPromotionLoading = (state: State) => state.addPromotionLoading;
export const getAddPromotionError = (state: State) => state.addPromotionError;
export const getPromotions = (state: State) => state.promotions;
export const getPromotionsLoading = (state: State) => state.getAllPromotionsLoading;
export const getPromotionsError = (state: State) => state.getAllPromotionsError;
export const getEditPromotionLoading = (state: State) => state.editPromotionLoading;
export const getEditPromotionError = (state: State) => state.editPromotionError;
export const getAddPromotionLogoLoading = (state: State) => state.addPromotionLogoLoading;
export const getAddPromotionLogoError = (state: State) => state.addPromotionLogoError;
export const getPromotionByIdLoading = (state: State) => state.getPromotionLoading;
export const getPromotionByIdError = (state: State) => state.getPromotionError;
export const getPromotionById = (state: State) => state.selectedPromotion;
export const getAddIdfsToPromotionLoading = (state: State) => state.addIdfsToPromotionLoading;
export const getAddIdfsToPromotionError = (state: State) => state.addIdfsToPromotionError;
export const getAddProductsLoading = (state: State) => state.addProductsLoading;
export const getAddProductsError = (state: State) => state.addProductsError;
export const getUpdateProductsLoading = (state: State) => state.updateProductsLoading;
export const getUpdateProductsError = (state: State) => state.updateProductsError;
export const getEditableProduct = (state: State) => state.editableProduct;
export const getDeletePromotionLoading = (state: State) => state.deletePromotionLoading;
export const getDeletePromotionError = (state: State) => state.deletePromotionError;
export const getRestorePromotionLoading = (state: State) => state.restorePromotionLoading;
export const getRestorePromotionError = (state: State) => state.restorePromotionError;
export const getDuplicatePromotionLoading = (state: State) => state.duplicatePromotionLoading;
export const getDuplicatePromotionError = (state: State) => state.duplicatePromotionError;
