import * as fromReducers from '../store/reducers';
import { VendorsRestService } from './api/vendors.rest-service';
import {
    InitAddVendor,
    AddVendorComplete,
    AddVendorFailed,
    InitUpdateVendor,
    UpdateVendorComplete,
    UpdateVendorFailed,
    InitLoadAllVendors
} from '../store/actions/vendors.actions';
import {Directive, Injectable, OnDestroy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Directive()
@Injectable({
    providedIn: 'root',
})
export class VendorsService implements OnDestroy {
    private ngUnsubscribe$ = new Subject<any>();

    constructor(public store: Store<fromReducers.State>,
                public vendorsRestService: VendorsRestService) {
    }
    // ==============
    // Load vendors
    // ==============
    getAllVendorsObservable(): Observable<any[]> {
        return this.store.pipe(select(fromReducers.getVendors));
    }

    loadAllVendors(): Observable<any[]> {
        this.store.dispatch(new InitLoadAllVendors());
        return this.getAllVendorsObservable();
    }

    vendorsLoadingObservable(): Observable<any> {
        return this.store.pipe(select(fromReducers.getAllVendorsLoading));
    }

    loadAllVendorsError(): Observable<any> {
        return this.store.pipe(select(fromReducers.getAllVendorsLoadError));
    }
    // ==============
    // Add new vendor
    // ==============
    createVendor(request: any) {
        this.store.dispatch(new InitAddVendor());
        this.vendorsRestService
            .addVendor(request)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((response: any) => {
                if (response.returnObject === null && response.errorCode === null) {
                    this.store.dispatch(new AddVendorComplete());
                    this.loadAllVendors();
                } else {
                    this.store.dispatch(new AddVendorFailed(response.errorCode));
                }
            });
    }

    createVendorLoadingObservable(): Observable<any> {
        return this.store.pipe(select(fromReducers.getAddVendorLoading));
    }

    createVendorErrorObservable(): Observable<any> {
        return this.store.pipe(select(fromReducers.getAddVendorError));
    }
    // ==============
    // Edit vendor
    // ==============
    editVendor(id: any, request: any) {
        this.store.dispatch(new InitUpdateVendor());
        this.vendorsRestService
            .updateVendor(id, request)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((response: any) => {
                if (response.returnObject === null && response.errorCode === null) {
                    this.store.dispatch(new UpdateVendorComplete());
                    this.loadAllVendors();
                } else {
                    this.store.dispatch(new UpdateVendorFailed(response.errorCode));
                }
            });
    }

    editVendorLoadingObservable(): Observable<any> {
        return this.store.pipe(select(fromReducers.getUpdateVendorLoading));
    }

    editVendorErrorObservable(): Observable<any> {
        return this.store.pipe(select(fromReducers.getUpdateVendorError));
    }
    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
