import * as fromPreorderSupplierActions from '../../actions/preorder/supplier.actions';
import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs';
import {PreorderRestService} from '../../../services/api/preorder.rest-service';

@Injectable()
export class PreorderSupplierEffects {
    constructor(
        private preorderRestService: PreorderRestService,
        private actions$: Actions
    ) {
    }

    @Effect()
    loadSuppliers$: Observable<Action> = this.actions$.pipe(
        ofType<fromPreorderSupplierActions.InitLoadAllSuppliers>(fromPreorderSupplierActions.SupplierActionTypes.INIT_LOAD_ALL_SUPPLIERS))
        .pipe(switchMap(() =>
                this.preorderRestService.loadAllSuppliers().pipe(
                    map(data => new fromPreorderSupplierActions.LoadAllSuppliersComplete(data.returnObject)),
                    catchError((error) => of(new fromPreorderSupplierActions.LoadAllSuppliersFailed(error)))
                )
            )
        );
}
