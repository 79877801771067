/**
 * represent the basic navigation item model
 */
export class NavItem {
    heading: string;
    navLinkName: string;
    component: string;
    path: string;
    activatesPath: string[];
    isTile: boolean;
    tileTitle: string;
    tileImageFileName: string;
    tileImageFileNameHover: string;
    requiredAuthority: string[];
    tree: NavItem[];
    dataCy: string;
    fragment: string;

    constructor(obj: any = {}) {
        this.heading = obj && obj.heading || null;
        this.navLinkName = obj && obj.navLinkName || null;
        this.component = obj && obj.component || 'dashboard';
        this.path = obj && obj.path || null;
        this.activatesPath = obj && obj.activatesPath || ['dashboard'];
        this.tree = obj && obj.tree || null;
        this.isTile = obj && obj.isTile || false;
        this.tileTitle = obj && obj.tileTitle || null;
        this.tileImageFileName = obj && obj.tileImageFileName || null;
        this.tileImageFileNameHover = obj && obj.tileImageFileNameHover || null;
        this.requiredAuthority = obj && obj.requiredAuthority || null;
        this.dataCy = obj && obj.dataCy || null;
        this.fragment = obj && obj.fragment || null;
    }

    hasChildNodes() {
        return this.tree && this.tree.length > 0;
    }

    hasTiles() {
        let hasTiles = false;
        this.tree.forEach(childNode => {
            if (childNode.isTile) {
                hasTiles = true;
            }
        });
        return hasTiles;
    }

    hasActiveComponent(component: string) {
        let hasActiveComponent = false;
        this.tree.forEach(childNode => {
            if (childNode.component === component) {
                hasActiveComponent = true;
            }
        });
        return hasActiveComponent;
    }
}
