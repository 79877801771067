import {Action} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

export enum EsareActionTypes {
    INIT_ADD_SUPPLEMENT = '[Esare] Init Add supplement',
    ADD_SUPPLEMENT_COMPLETE = '[Esare] Add supplement complete',
    ADD_SUPPLEMENT_FAILED = '[Esare] Add supplement failed',
    INIT_LOAD_ALL_SUPPLEMENTS = '[Esare] Init Load all supplements',
    LOAD_ALL_SUPPLEMENTS_COMPLETE = '[Esare] Load all supplements complete',
    LOAD_ALL_SUPPLEMENTS_FAILED = '[Esare] Load all supplements failed',
    INIT_EDIT_SUPPLEMENT = '[Esare] edit supplement',
    EDIT_SUPPLEMENT_COMPLETE = '[Esare] edit supplement complete',
    EDIT_SUPPLEMENT_FAILED = '[Esare] edit supplement failed'
}


export class InitAddSupplement implements Action {
    readonly type = EsareActionTypes.INIT_ADD_SUPPLEMENT;
}

export class AddSupplementComplete implements Action {
    readonly type = EsareActionTypes.ADD_SUPPLEMENT_COMPLETE;

    constructor(public payload: any) {
    }
}

export class AddSupplementFailed implements Action {
    readonly type = EsareActionTypes.ADD_SUPPLEMENT_FAILED;

    constructor(public payload: any) {
    }
}

export class InitLoadAllSupplements implements Action {
    readonly type = EsareActionTypes.INIT_LOAD_ALL_SUPPLEMENTS;
}

export class LoadAllSupplementsComplete implements Action {
    readonly type = EsareActionTypes.LOAD_ALL_SUPPLEMENTS_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadAllSupplementsFailed implements Action {
    readonly type = EsareActionTypes.LOAD_ALL_SUPPLEMENTS_FAILED;

    constructor(public payload: any) {
    }
}

export class InitEditSupplement implements Action {
    readonly type = EsareActionTypes.INIT_EDIT_SUPPLEMENT;
}

export class EditSupplementComplete implements Action {
    readonly type = EsareActionTypes.EDIT_SUPPLEMENT_COMPLETE;

    constructor(public payload: any) {
    }
}

export class EditSupplementFailed implements Action {
    readonly type = EsareActionTypes.EDIT_SUPPLEMENT_FAILED;

    constructor(public payload: any) {
    }
}

export type EsareActions =
    InitAddSupplement |
    AddSupplementComplete |
    AddSupplementFailed |
    InitLoadAllSupplements |
    LoadAllSupplementsComplete |
    LoadAllSupplementsFailed |
    InitEditSupplement |
    EditSupplementComplete |
    EditSupplementFailed;
