import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as fromBeraterActions from '../actions/beraterUser.actions';
import {BeraterUserRestService} from '../../services/api/beraterUser.rest-service';

@Injectable()
export class BeraterUserEffects {

    constructor(private actions$: Actions,
                private beraterRestService: BeraterUserRestService) {

    }

    getBeraterUsers$: Observable<Action> = createEffect(() => {
        return this.actions$
            .pipe(ofType<fromBeraterActions.LoadBeraterUser>(
                fromBeraterActions.BeraterUserActionTypes.GET_ALL_BERATER_USERS))
            .pipe(switchMap(() =>
                    this.beraterRestService.loadAllBeraterUser()
                        .pipe(
                            map((data) => new fromBeraterActions.LoadBeraterUserComplete(data.returnObject)),
                            catchError((error) => of(new fromBeraterActions.LoadBeraterUserFailed(error)))
                        )
                )
            );
    });
}
