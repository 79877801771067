import {Injectable, OnDestroy} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {RestServiceAbstract} from '../../../abstracts/rest-service.abstract';
import {ResponseSnakeModel} from '../../../models/response-snake.model';

@Injectable({
    providedIn: 'root',
})
export class PagesRestService extends RestServiceAbstract implements OnDestroy{
    protected ngUnsubscribe$: Subject<void> = new Subject<void>();

    getPagesList(){
        return this.get('/cms/admin/pages').pipe(takeUntil(this.ngUnsubscribe$), map(resp=>{
            return new ResponseSnakeModel(resp);
        }))
    }

    getActivePagesList(){
        return this.get('/cms/admin/pages/active').pipe(takeUntil(this.ngUnsubscribe$), map(resp=>{
            return new ResponseSnakeModel(resp);
        }))
    }

    getActivePages(type){
        return this.get('/cms/admin/pages/active/'+type).pipe(takeUntil(this.ngUnsubscribe$), map(resp=>{
            return new ResponseSnakeModel(resp);
        }))
    }

    getPage(id) {
        return this.get('/cms/admin/pages/'+id).pipe(takeUntil(this.ngUnsubscribe$), map(resp=>{
            return new ResponseSnakeModel(resp);
        }))
    }

    ngOnDestroy(){
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}