import * as fromPharmacyUserActions from '../actions/pharmacyUser.actions';
import { Injectable} from '@angular/core';
import { Actions, Effect, ofType} from '@ngrx/effects';
import { Action} from '@ngrx/store';
import { catchError, map, switchMap} from 'rxjs/operators';
import { of} from 'rxjs/internal/observable/of';
import { Observable} from 'rxjs';
import { UserRestService} from '../../services/api/user.rest-service';

@Injectable()
export class PharmacyUserEffects {

    @Effect()
    loadUsers$: Observable<Action> = this.actions$.pipe(
        ofType<fromPharmacyUserActions.GetAllPharmacyUsers>(fromPharmacyUserActions.PharmacyUserActionTypes.GET_ALL_PHARMACY_USERS))
        .pipe(map(action => action.payload), switchMap((rq) =>
                this.userRestService.loadPharmacyUsers(rq).pipe(
                    map(data => new fromPharmacyUserActions.GetAllPharmacyUsersComplete(data.returnObject)),
                    catchError((error) => of(new fromPharmacyUserActions.GetAllPharmacyUsersFailed(error)))
                )
            )
        );

    constructor(
        private userRestService: UserRestService,
        private actions$: Actions
    ) {}
}
