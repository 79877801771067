import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {FormsRestService} from '../../services/api/cms/forms.rest-service';
import {of} from 'rxjs/internal/observable/of';
import * as fromCmsActions from '../actions/cms.actions';
import {catchError, map, switchMap} from 'rxjs/operators';


@Injectable()
export class CmsEffects {
    constructor(private formsRestService: FormsRestService,
                private actions$: Actions) {
    }

    @Effect()
    loadForms$: Observable<Action> = this.actions$.pipe(
        ofType<fromCmsActions.LoadFormsAction>(fromCmsActions.CmsActionsTypes.LOAD_FORMS))
        .pipe(map(action => action),
            switchMap(() =>
                this.formsRestService.getAllForms().pipe(
                    map(data => {
                        return new fromCmsActions.LoadFormsCompleteAction(data.return_object);
                    }),
                    catchError((error) => of(new fromCmsActions.LoadFormsFailAction(error)))
                )
            )
        );

    @Effect()
    loadForm$: Observable<Action> = this.actions$.pipe(
        ofType<fromCmsActions.LoadFormAction>(fromCmsActions.CmsActionsTypes.LOAD_FORM))
        .pipe(map(action => action),
            switchMap((id) => this.formsRestService.getForm(id).pipe(
                map(data => new fromCmsActions.LoadFormCompleteAction(data.return_object)),
                catchError((error) => of(new fromCmsActions.LoadFormFailAction(error)))
            ))
        );
}