import {Injectable} from '@angular/core';
import {PreorderRestService} from '../../../services/api/preorder.rest-service';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import * as fromPreorderProductActions from '../../actions/preorder/product.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';


@Injectable()
export class PreorderProductEffects {
    constructor(
        private preorderRestService: PreorderRestService,
        private actions$: Actions
    ) {
    }

    @Effect()
    loadProducts$: Observable<Action> = this.actions$.pipe(
        ofType<fromPreorderProductActions.InitLoadOrderedProducts>
        (fromPreorderProductActions.ProductActionTypes.INIT_LOAD_ORDERED_PRODUCTS))
        .pipe(map(action => action.payload), switchMap((preOrderId) =>
                this.preorderRestService.loadProductsByPreorderId(preOrderId).pipe(
                    map(data => new fromPreorderProductActions.LoadOrderedProductsComplete(data.returnObject)),
                    catchError((error) => of(new fromPreorderProductActions.LoadOrderedProductsFailed(error)))
                )
            )
        );
}
