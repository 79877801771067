import {Injectable, OnDestroy} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {PagesRestService} from '../api/cms/pages.rest-service';

@Injectable({
    providedIn: 'root',
})
export class PagesService {
    constructor(private pagesRestService: PagesRestService) {

    }

    getPagesList() {

    }

    getPagesByType(type) {
        return this.pagesRestService.getActivePages(type);
    }

    getPage(id) {
        return this.pagesRestService.getPage(id);
    }
}