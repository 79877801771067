<div class="row">
    <div class="col-md-12">
        <div *ngIf="newProducts.length > 0" class="div-result-table">
            <div class="row div-result-header">
                <div class="col-md-2 font-weight-bold">
                    Name
                </div>
                <div class="col-md-1 font-weight-bold">
                    PZN
                </div>
                <div class="col-md-1 font-weight-bold">
                    AEP
                </div>
                <div class="col-md-1 font-weight-bold">
                    Tier1
                </div>
                <div class="col-md-1 font-weight-bold">
                    Tier2
                </div>
                <div class="col-md-1 font-weight-bold">
                    Tier3
                </div>
                <div class="col-md-1 font-weight-bold">
                    Menge1
                </div>
                <div class="col-md-1 font-weight-bold">
                    Menge2
                </div>
                <div class="col-md-1 font-weight-bold">
                    Menge3
                </div>
                <div class="col-md-1 font-weight-bold">
                    Sorting
                </div>
                <div class="col-md-1">
                </div>
            </div>
            <ng-container *ngFor="let product of newProducts ; let i = index">
                <div class="row div-result-entry d-flex align-items-center py-l">
                    <div class="col-md-2">
                        <span class="d-inline-block wrap" [innerHTML]="product.name | safeHtml"></span>
                    </div>
                    <div class="col-md-1">
                            <span class="d-inline-block wrap"
                                  [ngClass]="product.pzn.length > 8 || product.pzn.length === 0 || isNaN(+product.pzn) ? 'warn font-weight-bold' : ''">
                                {{product.pzn !== '' ? product.pzn : 'Pzn missing!'}}
                            </span>
                    </div>
                    <div class="col-md-1">
                            <span class="d-inline-block wrap">
                                {{product.price_normalaep / 100 | currency:'EUR':'symbol':'1.2-2' }}
                            </span>
                    </div>
                    <div class="col-md-1">
                            <span class="d-inline-block wrap">
                                {{(product.tiers[0].cents / 100) | currency:'EUR':'symbol':'1.2-2' }}
                            </span>
                    </div>
                    <div class="col-md-1">
                            <span class="d-inline-block wrap"
                                  *ngIf="product.tiers[1]">{{(product.tiers[1]?.cents / 100) | currency:'EUR':'symbol':'1.2-2' }}
                            </span>
                    </div>
                    <div class="col-md-1">
                            <span class="d-inline-block wrap"
                                  *ngIf="product.tiers[2]">{{product.tiers[2]?.cents / 100 | currency:'EUR':'symbol':'1.2-2' }}
                            </span>
                    </div>
                    <div class="col-md-1">
                        <span class="d-inline-block wrap">{{product.tiers[0].minQuantity}}</span>
                    </div>
                    <div class="col-md-1">
                        <span class="d-inline-block wrap">{{product.tiers[1]?.minQuantity}}</span>
                    </div>
                    <div class="col-md-1">
                        <span class="d-inline-block wrap">{{product.tiers[2]?.minQuantity}}</span>
                    </div>
                    <div class="col-md-1">
                        <span class="d-inline-block wrap">{{product?.sorting}}</span>
                    </div>
                    <div class="col-md-1">
                        <button class="btn-icon" (click)="remove(i)"><i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
