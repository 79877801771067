import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../core/services/api/auth.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {PasswordForgottenComponent} from '../password-forgotten/password-forgotten.component';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {

    public loginForm: FormGroup;
    public username: FormControl = new FormControl('', [Validators.required]);
    public password: FormControl = new FormControl('', [Validators.required]);
    public submitted: boolean;
    public hide = true;
    public loading = false;
    public error: any;
    public action: string = null;
    public passwordResetSuccess: boolean = false;
    private ngUnsubscribe$ = new Subject<any>(); // emit unsubscription

    constructor(@Inject(AuthService) public authService: AuthService,
                private formBuilder: FormBuilder,
                private router: Router,
                public dialog: MatDialog,
                public route: ActivatedRoute) {
        this.loginForm = this.formBuilder.group({
            username: this.username,
            password: this.password,
        });
    }

    ngOnInit() {
        this.route.params.pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(params => {
                this.action = params['action'];
            });
    }

    openDialog():void{
        const dialogRef = this.dialog.open(PasswordForgottenComponent,{
            width:'700px',
        })
        dialogRef.afterClosed().subscribe(result => {
        this.passwordResetSuccess = result.success;
      });
    }

    submit() {
        this.submitted = true;
        this.passwordResetSuccess = false;
        if (this.loginForm.invalid) {
            return;
        }
        // stop here if form is invalid
        let url = 'dashboard';
        const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
        if (returnUrl) {
            url = returnUrl;
        }
        this.loading = true;
        this.authService.login(this.loginForm.value).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(
            data => {
                this.router.navigateByUrl(url);
            },
            error => {
                this.error = error;
                this.loading = false;
            });
    }

    /**
     * unsubcribe all subscriptions on destroy
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private redirectToDashboard() {
        const isAuthenticated = this.authService.isAuthenticated();

        if (isAuthenticated) {
            this.router.navigateByUrl('dashboard');
        }
    }
}
