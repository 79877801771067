import {HttpErrorResponse} from '@angular/common/http';
import {DelayInfoActions, DelayInfoActionTypes} from '../actions/delay-info.actions';

export interface State {
    delays: any;
    delaysLoading: boolean;
    delaysError: HttpErrorResponse;

}

export const initialState: State = {
    delays: {},
    delaysLoading: false,
    delaysError: null,
};


export function reducer(state = initialState, action: DelayInfoActions): State {
    switch (action.type) {
        case DelayInfoActionTypes.LOAD_DELAYS:
            return {
                ...state,
                delaysLoading: true,
                delaysError: null
            };
        case DelayInfoActionTypes.LOAD_DELAYS_COMPLETE:
            return {
                ...state,
                delaysLoading: false,
                delaysError: null,
                delays: action.payload
            };
        case DelayInfoActionTypes.LOAD_DELAYS_FAIL:
            return {
                ...state,
                delaysLoading: false,
                delaysError: action.payload
            };
        default:
            return state;
    }
}

export const getDelaysLoading = (state: State) => state.delaysLoading;
export const getDelaysError = (state: State) => state.delaysError;
export const getDelays = (state: State) => state.delays;