import {Action} from '@ngrx/store';

export enum CategoryActionTypes {
    INIT_LOAD_ALL_CATEGORIES = '[Preorder-category] Init Load all categories',
    LOAD_ALL_CATEGORIES_COMPLETE = '[Preorder-category] Load all categories complete',
    LOAD_ALL_CATEGORIES_FAILED = '[Preorder-category] Load all categories failed',
    INIT_ADD_CATEGORY = '[Preorder-category] Init Add Category',
    ADD_CATEGORY_COMPLETE = '[Preorder-category] Add Category complete',
    ADD_CATEGORY_FAILED = '[Preorder-category] Add Category failed',
    INIT_EDIT_CATEGORY = '[Preorder-category] Init Edit Category',
    EDIT_CATEGORY_COMPLETE = '[Preorder-category] Edit Category Complete',
    EDIT_CATEGORY_FAILED = '[Preorder-category] Edit Category Failed',
    INIT_LOAD_CATEGORY = '[Preorder-category] Init Load Category',
    LOAD_CATEGORY_COMPLETE = '[Preorder-category] Load Category Complete',
    LOAD_CATEGORY_FAILED = '[Preorder-category] Load Category Failed',
}

export class InitLoadAllCategories implements Action {
    readonly type = CategoryActionTypes.INIT_LOAD_ALL_CATEGORIES;
}

export class LoadAllCategoriesComplete implements Action {
    readonly type = CategoryActionTypes.LOAD_ALL_CATEGORIES_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadAllCategoriesFailed implements Action {
    readonly type = CategoryActionTypes.LOAD_ALL_CATEGORIES_FAILED;

    constructor(public payload: any) {
    }
}

export class InitAddCategory implements Action {
    readonly type = CategoryActionTypes.INIT_ADD_CATEGORY;
}

export class AddCategoryComplete implements Action {
    readonly type = CategoryActionTypes.ADD_CATEGORY_COMPLETE;

    constructor(public payload: any) {
    }
}

export class AddCategoryFailed implements Action {
    readonly type = CategoryActionTypes.ADD_CATEGORY_FAILED;

    constructor(public payload: any) {
    }
}

export class InitEditCategory implements Action {
    readonly type = CategoryActionTypes.INIT_EDIT_CATEGORY;

}

export class EditCategoryComplete implements Action {
    readonly type = CategoryActionTypes.EDIT_CATEGORY_COMPLETE;

}

export class EditCategoryFailed implements Action {
    readonly type = CategoryActionTypes.EDIT_CATEGORY_FAILED;

    constructor(public payload: any) {
    }
}

export class InitLoadCategory implements Action {
    readonly type = CategoryActionTypes.INIT_LOAD_CATEGORY;

    constructor(public payload: any) {
    }
}

export class LoadCategoryComplete implements Action {
    readonly type = CategoryActionTypes.LOAD_CATEGORY_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadCategoryFailed implements Action {
    readonly type = CategoryActionTypes.LOAD_CATEGORY_FAILED;

    constructor(public payload: any) {
    }
}

export type CategoryActions =
    InitLoadAllCategories |
    LoadAllCategoriesComplete |
    LoadAllCategoriesFailed |
    InitAddCategory |
    AddCategoryComplete |
    AddCategoryFailed |
    InitEditCategory |
    EditCategoryComplete |
    EditCategoryFailed |
    InitLoadCategory |
    LoadCategoryComplete |
    LoadCategoryFailed;
