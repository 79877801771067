import {AccountActions, AccountActionTypes} from '../actions/account.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {AccountModel} from '../../models/account.model';
import {AccountsPaginationRequestModel} from '../../models/accounts-pagination-request.model';

export interface State {
    accounts: AccountModel[];
    getAllAccountsRequest: AccountsPaginationRequestModel;
    getAllAccountsResponse: any;
    getAllAccountsLoading: boolean;
    getAllAccountsError: HttpErrorResponse;
}

export const initialState: State = {
    accounts: [],
    getAllAccountsRequest: new AccountsPaginationRequestModel(),
    getAllAccountsResponse: null,
    getAllAccountsLoading: false,
    getAllAccountsError: null
};

export function reducer(state = initialState, action: AccountActions): State {
    switch (action.type) {
        case AccountActionTypes.INIT_LOAD_ALL_ACCOUNTS:
            return {
                ...state,
                getAllAccountsRequest: action.payload,
                getAllAccountsLoading: true,
                getAllAccountsError: null
            };
        case AccountActionTypes.LOAD_ALL_ACCOUNTS_COMPLETE:
            return {
                ...state,
                getAllAccountsLoading: false,
                getAllAccountsError: null,
                accounts: action.payload.content,
                getAllAccountsResponse: action.payload
            };
        case AccountActionTypes.LOAD_ALL_ACCOUNTS_FAILED:
            return {
                ...state,
                getAllAccountsLoading: false,
                getAllAccountsError: action.payload,
            };
        default:
            return state;
    }
}

export const getAccountsRequest = (state: State) => state.getAllAccountsRequest;
export const getAccounts = (state: State) => state.accounts;
export const getAccountsResponse = (state: State) => state.getAllAccountsResponse;
export const getAccountsLoading = (state: State) => state.getAllAccountsLoading;
export const getAccountsError = (state: State) => state.getAllAccountsError;
