import {Action} from '@ngrx/store';

export enum VaccineActionTypes {
    INIT_ADD_VACCINE = '[Vaccine] Init Add vaccine',
    ADD_VACCINE_COMPLETE = '[Vaccine] Add vaccine complete',
    ADD_VACCINE_FAILED = '[Vaccine] Add vaccine failed',
    INIT_LOAD_ALL_VACCINES = '[Vaccine] Init Load all vaccines',
    LOAD_ALL_VACCINES_COMPLETE = '[Vaccine] Load all vaccines complete',
    LOAD_ALL_VACCINES_FAILED = '[Vaccine] Load all vaccines failed',
    INIT_EDIT_VACCINE = '[Vaccine] edit vaccine',
    EDIT_VACCINE_COMPLETE = '[Vaccine] edit vaccine complete',
    EDIT_VACCINE_FAILED = '[Vaccine] edit vaccine failed'
}


export class InitAddVaccine implements Action {
    readonly type = VaccineActionTypes.INIT_ADD_VACCINE;
}

export class AddVaccineComplete implements Action {
    readonly type = VaccineActionTypes.ADD_VACCINE_COMPLETE;

    constructor(public payload: any) {
    }
}

export class AddVaccineFailed implements Action {
    readonly type = VaccineActionTypes.ADD_VACCINE_FAILED;

    constructor(public payload: any) {
    }
}

export class InitLoadAllVaccines implements Action {
    readonly type = VaccineActionTypes.INIT_LOAD_ALL_VACCINES;
}

export class LoadAllVaccinesComplete implements Action {
    readonly type = VaccineActionTypes.LOAD_ALL_VACCINES_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadAllVaccinesFailed implements Action {
    readonly type = VaccineActionTypes.LOAD_ALL_VACCINES_FAILED;

    constructor(public payload: any) {
    }
}

export class InitEditVaccine implements Action {
    readonly type = VaccineActionTypes.INIT_EDIT_VACCINE;
}

export class EditVaccineComplete implements Action {
    readonly type = VaccineActionTypes.EDIT_VACCINE_COMPLETE;

    constructor(public payload: any) {
    }
}

export class EditVaccineFailed implements Action {
    readonly type = VaccineActionTypes.EDIT_VACCINE_FAILED;

    constructor(public payload: any) {
    }
}

export type VaccineActions =
    InitAddVaccine |
    AddVaccineComplete |
    AddVaccineFailed |
    InitLoadAllVaccines |
    LoadAllVaccinesComplete |
    LoadAllVaccinesFailed |
    InitEditVaccine |
    EditVaccineComplete |
    EditVaccineFailed;
