import {Injectable, OnDestroy} from '@angular/core';
import {RestServiceAbstract} from '../../../abstracts/rest-service.abstract';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ResponseSnakeModel} from '../../../models/response-snake.model';

@Injectable({
    providedIn: 'root',
})
export class FormsRestService extends RestServiceAbstract implements OnDestroy {
    protected ngUnsubscribe$: Subject<void> = new Subject<void>();

    getAllForms() {
        return this.get('/cms/admin/forms').pipe(takeUntil(this.ngUnsubscribe$),
            map(data => {
                console.log(data);
                return new ResponseSnakeModel(data);
            })
        );
    }


    getForm(id) {
        return this.get('/cms/admin/forms/' + id).pipe(takeUntil(this.ngUnsubscribe$),
            map(data => {
                console.log(data);
                return new ResponseSnakeModel(data);
            })
        );
    }

    createForm(formData) {
        console.log(formData);
        return this.post('/cms/admin/forms', formData).pipe(takeUntil(this.ngUnsubscribe$),
            map(data => {
                console.log(data);
                return new ResponseSnakeModel(data);
            })
        );
    }

    updateForm(formData) {
        return this.put('/cms/admin/forms', formData).pipe(takeUntil(this.ngUnsubscribe$),
            map(data => {
                console.log(data);
                return new ResponseSnakeModel(data);
            })
        );
    }

    deleteForm(formData) {
        console.log(formData);
        return this.delete('/cms/admin/forms/' + formData.id, {}).pipe(takeUntil(this.ngUnsubscribe$),
            map(data => {
                console.log(data);
                return new ResponseSnakeModel(data);
            })
        );
    }


    submitForm(request: any, isPublic: boolean) {
            return this.post('/cms/admin/forms/test', request).pipe(map(data => {
                return new ResponseSnakeModel(data);
            }));
    }

    deleteField(field) {
        return this.delete('/cms/admin/forms/field/' + field.id, {}).pipe(takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new ResponseSnakeModel(data);
            }));
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe$.next();
        // This completes the subject properly.
        this.ngUnsubscribe$.complete();
    }

}