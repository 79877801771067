import { Component, Input, OnInit } from '@angular/core';
import {MatomoService} from '../../../../core/services/matomo.service';

@Component({
  selector: 'phx-advertorial-report',
  templateUrl: './advertorial-report.component.html',
  styleUrls: ['./advertorial-report.component.scss',
    '../supplier-report.component.scss']
})
export class AdvertorialReportComponent implements OnInit {
  @Input() advertorial;
  advertorials = [];
  click: number = 0;
  compares = [];
  days = 0;

  constructor(private matomoService: MatomoService) { }

  ngOnInit(): void {
    this.days = this.matomoService.getDateRange(new Date(this.advertorial.start), new Date(this.advertorial.end));

    this.getData();
  }

  getData() {
    const startDate = new Date(this.advertorial.start);
    const startDateThree = new Date(startDate.setMonth(startDate.getMonth()-3));
    const endDate = new Date(this.advertorial.end);
    //
    // this.matomoService.searchForRange('Klick+auf+Startseite+Slider', startDateThree, endDate).subscribe(slides => {
    //   slides.subscribe(slidesDate => {
    //     this.advertorials = slidesDate;
    //     console.log(this.advertorials);
    //     this.calculateClickPerformance();
    //   });
    // });

  }

  calculateClickPerformance() {
    if (this.advertorials.length > 0 && this.compares) {
      const title = this.advertorial.title.replace('+', ' ').trim();
      const selected = this.advertorials.find(slide => slide.label.trim() === title);
      let compareReport = [];

      this.advertorials.forEach(slide => {
        if(slide.label !== this.advertorial.title && this.compares.find((compare: any) => compare.title.trim() === slide.label.trim()) !== undefined) {
          compareReport.push(slide);
        }
      });
      const selectedClicks = this.matomoService.calculateClicksPerDay(selected, this.days);

      let compareClicks = 0;
      let compareNr = 0;

      for(let compare of this.compares){
        let compareDays = 0;
        let title = compare.title.replace('+', ' ').trim()
        compareDays = this.matomoService.getDateRange(new Date(compare.start), new Date(compare.end));
        let comp = compareReport.find(comp => comp.label.trim() === title);

        if(comp) {
          compareClicks += this.matomoService.calculateClicksPerDay(comp, compareDays);
          compareNr++;
        }
      }

      this.click = +((selectedClicks / (compareClicks/compareNr)).toFixed(2));
    }
  }

}
