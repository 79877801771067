<message icon="fa-check-circle"
         message="{{message}}"></message>
<!--
<div class="subject"><h5><i class="fal fa-envelope-open"></i> Bestätigen Sie die Registrierung im PHOENIX
    Apothekenportal | PHOENIX online</h5></div>
<hr class="my-2">
<div>
    PHOENIX Apothekenportal | PHOENIX online
    <span class="float-right me"><i class="fal fa-paperclip"></i> 16:18 (vor 1 Stunde) <i class="fal fa-star"></i><i
            class="fal fa-reply pl-2"></i></span>
</div>
<div class="me pb-5">an mich</div>
<h3> Herzlich Willkommen Herr Apotheker Schmidt,</h3>
<div class="py-4">wir freuen uns sehr, dass Sie sich im PHOENIX Apothekenportal registriert haben. Um Missbrauch zu
    vermeiden, bitten wir Sie Ihre Registrierung mit folgendem Link zu bestätigen.
</div>
<div>Ihr Benutzername lautet: <b>{{response?.username}}</b></div>
<a [routerLink]="['/registrierung/'+response?.registrationToken]">
    <button class="btn-primary mt-0" type="button">
        Registrierung bestätigen
    </button>
</a>
<div class="pt-4">
    Herzliche Grüße<br/>
    Ihre PHOENIX Group
</div>-->
