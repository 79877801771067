import {Action} from '@ngrx/store';

export enum PromotionsActionTypes {
    INIT_ADD_PROMOTION = '[Promotions] Init Add Promotion',
    ADD_PROMOTION_COMPLETE = '[Promotions] Add Promotion complete',
    ADD_PROMOTION_FAILED = '[Promotions] Add Promotion failed',
    INIT_LOAD_ALL_PROMOTIONS = '[Promotions] Init Load all Promotions',
    LOAD_ALL_PROMOTIONS_COMPLETE = '[Promotions] Load all Promotions complete',
    LOAD_ALL_PROMOTIONS_FAILED = '[Promotions] Load all Promotions failed',
    INIT_EDIT_PROMOTION = '[Promotions] edit Promotion',
    EDIT_PROMOTION_COMPLETE = '[Promotions] edit Promotion complete',
    EDIT_PROMOTION_FAILED = '[Promotions] edit Promotion failed',
    INIT_ADD_PROMOTION_LOGO = '[Promotions] Init Add Promotion Logo',
    ADD_PROMOTION_LOGO_COMPLETE = '[Promotions] Add Promotion Logo Complete',
    ADD_PROMOTION_LOGO_FAILED = '[Promotions] Add Promotion Logo Failed',
    INIT_GET_PROMOTION = '[Promotions] Get Promotion by Id',
    GET_PROMOTION_COMPLETE = '[Promotions] Get Promotion by Id Complete',
    GET_PROMOTION_FAILED = '[Promotions] Get Promotion by Id Failed',
    INIT_SET_IDFS_TO_PROMOTION = '[Promotions] Set IDFs to Promotion Init',
    SET_IDFS_TO_PROMOTION_COMPLETE = '[Promotions] Set IDFs to Promotion Complete',
    SET_IDFS_TO_PROMOTION_FAILED = '[Promotions] Set IDFs to Promotion Failed',
    INIT_ADD_PRODUCTS = '[Promotions] Add Products to Promotion',
    ADD_PRODUCTS_COMPLETE = '[Promotions] Add Products to Promotion Complete',
    ADD_PRODUCTS_FAILED = '[Promotions] Add Products to Promotion Failed',
    INIT_UPDATE_PRODUCTS = '[Promotions] Init Update Products to Promotion',
    UPDATE_PRODUCTS_COMPLETE = '[Promotions] Update Products Complete',
    UPDATE_PRODUCTS_FAILED = '[Promotions] Update Products Failed',
    RESET_EDITABLE_PRODUCT = '[Promotions] Reset Editable Product',
    INIT_DELETE_PROMOTION = '[Promotions] Init Delete Promotion',
    DELETE_PROMOTION_COMPLETE = '[Promotions] Delete Promotion Complete',
    DELETE_PROMOTION_FAILED = '[Promotions] Delete Promotion Failed',
    INIT_RESTORE_PROMOTION = '[Promotions] Init Restore Promotion',
    RESTORE_PROMOTION_COMPLETE = '[Promotions] Restore Promotion Complete',
    RESTORE_PROMOTION_FAILED = '[Promotions] Restore Promotion Failed',
    INIT_DUPLICATE_PROMOTION = '[Promotions] Init Duplicate Promotion',
    DUPLICATE_PROMOTION_COMPLETE = '[Promotions] Duplicate Promotion Complete',
    DUPLICATE_PROMOTION_FAILED = '[Promotions] Duplicate Promotion Failed'
}

export class InitAddPromotion implements Action {
    readonly type = PromotionsActionTypes.INIT_ADD_PROMOTION;
}

export class AddPromotionComplete implements Action {
    readonly type = PromotionsActionTypes.ADD_PROMOTION_COMPLETE;

    constructor(public payload: any) {
    }
}

export class AddPromotionFailed implements Action {
    readonly type = PromotionsActionTypes.ADD_PROMOTION_FAILED;

    constructor(public payload: any) {
    }
}

export class InitLoadAllPromotions implements Action {
    readonly type = PromotionsActionTypes.INIT_LOAD_ALL_PROMOTIONS;

    constructor(public payload: any) {
    }
}

export class LoadAllPromotionsComplete implements Action {
    readonly type = PromotionsActionTypes.LOAD_ALL_PROMOTIONS_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadAllPromotionsFailed implements Action {
    readonly type = PromotionsActionTypes.LOAD_ALL_PROMOTIONS_FAILED;

    constructor(public payload: any) {
    }
}

export class InitEditPromotion implements Action {
    readonly type = PromotionsActionTypes.INIT_EDIT_PROMOTION;
}

export class EditPromotionComplete implements Action {
    readonly type = PromotionsActionTypes.EDIT_PROMOTION_COMPLETE;

    constructor(public payload: any) {
    }
}

export class EditPromotionFailed implements Action {
    readonly type = PromotionsActionTypes.EDIT_PROMOTION_FAILED;

    constructor(public payload: any) {
    }
}

export class InitAddPromotionLogo implements Action {
    readonly type = PromotionsActionTypes.INIT_ADD_PROMOTION_LOGO;
}

export class AddPromotionLogoComplete implements Action {
    readonly type = PromotionsActionTypes.ADD_PROMOTION_LOGO_COMPLETE;
}

export class AddPromotionLogoFailed implements Action {
    readonly type = PromotionsActionTypes.ADD_PROMOTION_LOGO_FAILED;

    constructor(public payload: any) {
    }
}

export class InitGetPromotion implements Action {
    readonly type = PromotionsActionTypes.INIT_GET_PROMOTION;
}

export class GetPromotionComplete implements Action {
    readonly type = PromotionsActionTypes.GET_PROMOTION_COMPLETE;

    constructor(public payload: any) {
    }
}

export class GetPromotionFailed implements Action {
    readonly type = PromotionsActionTypes.GET_PROMOTION_FAILED;

    constructor(public payload: any) {
    }
}

export class InitSetIdfsToPromotion implements Action {
    readonly type = PromotionsActionTypes.INIT_SET_IDFS_TO_PROMOTION;
}

export class SetIdfsToPromotionComplete implements Action {
    readonly type = PromotionsActionTypes.SET_IDFS_TO_PROMOTION_COMPLETE;
}

export class SetIdfsToPromotionFailed implements Action {
    readonly type = PromotionsActionTypes.SET_IDFS_TO_PROMOTION_FAILED;

    constructor(public payload: any) {
    }
}

export class InitAddProducts implements Action {
    readonly type = PromotionsActionTypes.INIT_ADD_PRODUCTS;
}

export class AddProductsComplete implements Action {
    readonly type = PromotionsActionTypes.ADD_PRODUCTS_COMPLETE;
}

export class AddProductsFailed implements Action {
    readonly type = PromotionsActionTypes.ADD_PRODUCTS_FAILED;

    constructor(public payload: any) {
    }
}

export class InitUpdateProducts implements Action {
    readonly type = PromotionsActionTypes.INIT_UPDATE_PRODUCTS;

    constructor(public payload: any) {
    }
}

export class UpdateProductsComplete implements Action {
    readonly type = PromotionsActionTypes.UPDATE_PRODUCTS_COMPLETE;
}

export class UpdateProductsFailed implements Action {
    readonly type = PromotionsActionTypes.UPDATE_PRODUCTS_FAILED;

    constructor(public payload: any) {
    }
}

export class InitDeletePromotion implements Action {
    readonly type = PromotionsActionTypes.INIT_DELETE_PROMOTION;
}

export class DeletePromotionComplete implements Action {
    readonly type = PromotionsActionTypes.DELETE_PROMOTION_COMPLETE;
}

export class DeletePromotionFailed implements Action {
    readonly type = PromotionsActionTypes.DELETE_PROMOTION_FAILED;

    constructor(public payload: any) {
    }
}

export class ResetEditableProduct implements Action {
    readonly type = PromotionsActionTypes.RESET_EDITABLE_PRODUCT;
}

export class InitRestorePromotion implements Action {
    readonly type = PromotionsActionTypes.INIT_RESTORE_PROMOTION;
}

export class RestorePromotionComplete implements Action {
    readonly type = PromotionsActionTypes.RESTORE_PROMOTION_COMPLETE;
}

export class RestorePromotionFailed implements Action {
    readonly type = PromotionsActionTypes.RESTORE_PROMOTION_FAILED;

    constructor(public payload: any) {
    }
}

export class InitDuplicatePromotion implements Action {
    readonly type = PromotionsActionTypes.INIT_DUPLICATE_PROMOTION;
}

export class DuplicatePromotionComplete implements Action {
    readonly type = PromotionsActionTypes.DUPLICATE_PROMOTION_COMPLETE;
}

export class DuplicatePromotionFailed implements Action {
    readonly type = PromotionsActionTypes.DUPLICATE_PROMOTION_FAILED;

    constructor(public payload: any) {
    }
}


export type PromotionsActions =
    InitAddPromotion |
    AddPromotionComplete |
    AddPromotionFailed |
    InitLoadAllPromotions |
    LoadAllPromotionsComplete |
    LoadAllPromotionsFailed |
    InitEditPromotion |
    EditPromotionComplete |
    EditPromotionFailed |
    InitAddPromotionLogo |
    AddPromotionLogoComplete |
    AddPromotionLogoFailed |
    InitGetPromotion |
    GetPromotionComplete |
    GetPromotionFailed |
    InitSetIdfsToPromotion |
    SetIdfsToPromotionComplete |
    SetIdfsToPromotionFailed |
    InitAddProducts |
    AddProductsComplete |
    AddProductsFailed |
    InitUpdateProducts |
    UpdateProductsComplete |
    UpdateProductsFailed |
    ResetEditableProduct |
    InitDeletePromotion |
    DeletePromotionFailed |
    DeletePromotionComplete |
    InitRestorePromotion |
    RestorePromotionComplete |
    RestorePromotionFailed |
    InitDuplicatePromotion |
    DuplicatePromotionComplete |
    DuplicatePromotionFailed;
