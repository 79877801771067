import {Injectable} from '@angular/core';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {Response} from '../../models/response.model';
import {Observable, Subject, throwError} from 'rxjs';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {PromotionRequest} from '../../models/promotion-request.model';
import {PromotionProductModel} from '../../models/promotion-product.model';

@Injectable({
    providedIn: 'root',
})
export class PromotionsRestService extends RestServiceAbstract {

    protected ngUnsubscribe$: Subject<void> = new Subject<void>();

    public addPromotion(promotionRequest: PromotionRequest): Observable<Response> {
        return this.post(`/resource/admin/promo`, promotionRequest).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public loadAllPromotions(includeDeleted: boolean): Observable<Response> {
        return this.get(`/resource/admin/promo`, {params: {includeDeleted: includeDeleted}}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public loadOnlyDeletedPromotions(): Observable<Response> {
        return this.get(`/resource/admin/promo/deleted`)
            .pipe(takeUntil(this.ngUnsubscribe$),
                map(data => {
                    return new Response(data);
                }),
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    public getPromotionById(id: any): Observable<Response> {
        return this.get(`/resource/admin/promo/${id}`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public restorePromotionById(id: any): Observable<Response> {
        return this.post(`/resource/admin/promo/${id}`, null).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public updatePromotion(id: any, rq: PromotionRequest): Observable<Response> {
        return this.put(`/resource/admin/promo/${id}`, rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public removePromotion(rq: any): Observable<Response> {
        return this.delete(`/resource/admin/promo/${rq.id}`, rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public setPromotionAccounts(id: any, idfs: string[]): Observable<Response> {
        return this.post(`/resource/admin/promo/${id}/accounts`, idfs).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public setPromotionLogo(id: any, file: string) {
        return this.post(`/resource/admin/promo/${id}/logo`, file).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }


    public setPromotionProducts(id: any, products: PromotionProductModel[]): Observable<Response> {
        return this.post(`/resource/admin/promo/${id}/products`, products).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public updatePromotionProducts(id: any, products: PromotionProductModel[]): Observable<Response> {
        return this.put(`/resource/admin/promo/${id}/products`, products).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }
}
