import { Inject, Injectable, OnDestroy } from '@angular/core';
import { UserService } from '../user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { LoginRequest } from '../../models/login-request.model';
import { Response } from '../../models/response.model';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthService implements OnDestroy {

    private ngUnsubscribe$ = new Subject<any>();
    private jwtHelper = new JwtHelperService();

    constructor(private  http: HttpClient,
                @Inject(UserService) private userService: UserService,
                @Inject('AUTH_URL') private AUTH_URL: string) {
    }

    /**
     * Logs the user in with the given credentials. Gets a JWT from remote
     * authentication provider.
     *
     * @returns {Observable<any>}
     */
    getUserResponseFromGateway(): Observable<Response> {
        return this.http.get(`${this.AUTH_URL}/usermanagement/user`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError(error => {
                return throwError(error);
            })
        );
    }

    /**
     * Returns true if the user is authenticated.
     *
     * @returns {boolean}
     */
    public isAuthenticated(): boolean {
        const token = this.getToken();
        if (token == null) {
            return false;
        }
        if (this.jwtHelper.isTokenExpired(token)) {
            this.logout();
            return false;
        }
        return true;
    }

    /**
     * Get available groups from gateway
     *
     * @returns {Subscription}
     */
    public getGroups(): Subscription {
        return this.http.get(`${this.AUTH_URL}/groups`).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(data => {
            return new Response(data);
        });
    }

    /**
     * Logs the user in with the given credentials. Gets a JWT from remote
     * authentication provider.
     *
     * @returns {any}
     */
    login(lr: LoginRequest) {
        if (this.jwtHelper.isTokenExpired(this.getToken())) {
            this.logout();
        }
        const url = `${this.AUTH_URL}/usermanagement/auth/login`;
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('X-App-Key', 'admin');
        return this.http.post(url, lr, {observe: 'response', headers: headers}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                this.setToken(data.headers.get('authorization'));
                return data;
            })
        );
    }

    logout() {
        localStorage.removeItem('token');
    }

    private setToken(token: string) {
        localStorage.setItem('token', token);
    }

    public getToken(): string {
        return localStorage.getItem('token');
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
