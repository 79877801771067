import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LogService} from "../../../core/services/utility/log.service";
import {RegistrationRestService} from "../../../core/services/api/registration.rest-service";
import {RegistrationConfirmResponse} from "../../../core/models/registration/registration-confirm-response.model";
import {HttpErrorResponse} from "@angular/common/http";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'membership-request',
  templateUrl: './membership-request.component.html',
  styleUrls: ['./membership-request.component.scss']
})
export class MembershipRequestComponent implements OnInit {
    public error: HttpErrorResponse = null;
    public success: boolean = false;
    public loading: boolean = false;

    private ngUnsubscribe$ = new Subject<any>(); // emit unsubscription

    public userForm: FormGroup;
    public salutation: FormControl = new FormControl('', Validators.required);
    public title: FormControl = new FormControl('');
    public firstName: FormControl = new FormControl('', Validators.required);
    public lastName: FormControl = new FormControl('', Validators.required);
    public name: FormControl = new FormControl('', Validators.required);
    public idf: FormControl = new FormControl('',
        [Validators.required, Validators.minLength(2), Validators.maxLength(7)]);
    public street: FormControl = new FormControl('', Validators.required);
    public zipCode: FormControl = new FormControl('',
        [Validators.required, Validators.minLength(5), Validators.maxLength(5)]);
    public city: FormControl = new FormControl('', Validators.required);
    public email: FormControl = new FormControl('',
        [Validators.required, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}$')]);
    public phone: FormControl = new FormControl('',
        [Validators.required, Validators.pattern('^(\\+[1-9]{2})?[\\d -]+$')]);
    public fax: FormControl = new FormControl('',
        [Validators.pattern('^(\\+[1-9]{2})?[\\d -]+$')]);

    constructor(@Inject(LogService) public log: LogService,
                @Inject(RegistrationRestService) public registrationRestService: RegistrationRestService) {
        this.userForm = new FormGroup({
            salutation: this.salutation,
            title: this.title,
            firstName: this.firstName,
            lastName: this.lastName,
            name: this.name,
            idf: this.idf,
            street: this.street,
            zipCode: this.zipCode,
            city: this.city,
            email: this.email,
            phone: this.phone,
            fax: this.fax,
        });
    }

    ngOnInit() {
    }

    submit() {
        if (!this.userForm.valid) {
            return;
        }
        let data = this.userForm.value;
        this.loading = true;
        this.registrationRestService.signUp(data).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
            this.log.info(result.returnObject);
            this.loading = false;
            this.success = true;
        }, error => {
            this.error = error;
            this.loading = false;
        });
    }

    /**
     * unsubcribe all subscriptions on destroy
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
